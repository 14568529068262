import React, { createContext, useContext, ReactNode } from 'react';
import useSWR, {Fetcher, SWRConfig, SWRResponse} from 'swr';
import { useLocation } from 'react-router-dom';
import { isPublicRoute } from 'routes';
import Cookies from 'universal-cookie';
import { useRedirectToLogin } from './AuthProvider';

const cookies = new Cookies();

export interface ICleverStudent {
  klass_id: number,
  klass_name: string,
  clever_id: string,
  last_sync: string,
  student_id: number
  student_name: string,
  school_name: string,
  teachers: {id: number, name: string}[],
  klasses: {id: number, name: string}[],
  teacher_name: string
}

interface StudentsResponse {
  students: ICleverStudent[];
  total_count: number;
  page: number;
  per_page: number;
  total_pages: number;
}

export interface ICleverSchool {
  id: number;
  school_name: string;
  student_count: number;
  teacher_count: number;
  klass_count: number;
}

interface SchoolsResponse {
  schools: ICleverSchool[];
  total_count: number;
  page: number;
  per_page: number;
  total_pages: number;
}

export interface ICleverTeacher {
  teacher_id: number;
  teacher_name: string;
  school_name: string;
  last_sync: string;
  clever_id: string;
  student_count: number;
  klass_count: number;
}

interface TeachersResponse {
  teachers: ICleverTeacher[];
  total_count: number;
  page: number;
  per_page: number;
  total_pages: number;
}

export interface ICleverKlass {
  klass_id: number,
  klass_name: string,
  teacher_name: string,
  school_name: string,
  grade: string,
  clever_id: string,
  last_sync: string,
  student_count: number,
  hidden: boolean,
  teachers_shown: boolean,
  teachers: {
    id: number,
    name: string,
  }[]
}

interface KlassesResponse {
  klasses: ICleverKlass[];
  total_count: number;
  page: number;
  per_page: number;
  total_pages: number;
}

interface CleverProviderProps {
  children?: ReactNode;
}

interface CleverContextType {
  useStudents: (params?: {
    page?: number;
    per_page?: number;
    include_hidden_klasses?: boolean;
    query?: string;
  }) => Omit<SWRResponse<StudentsResponse, Error>, 'mutate'> & {
    mutate: (data?: StudentsResponse, shouldRevalidate?: boolean) => Promise<StudentsResponse | undefined>;
    isLoading: boolean;
  };
  useSchools: (params?: {
    page?: number;
    per_page?: number;
    query?: string;
  }) => Omit<SWRResponse<SchoolsResponse, Error>, 'mutate'> & {
    mutate: (data?: SchoolsResponse, shouldRevalidate?: boolean) => Promise<SchoolsResponse | undefined>;
    isLoading: boolean;
  };
  useTeachers: (params?: {
    page?: number;
    per_page?: number;
    include_hidden_klasses?: boolean;
    query?: string;
  }) => Omit<SWRResponse<TeachersResponse, Error>, 'mutate'> & {
    mutate: (data?: TeachersResponse, shouldRevalidate?: boolean) => Promise<TeachersResponse | undefined>;
    isLoading: boolean;
  };
  useKlasses: (params?: {
    page?: number;
    per_page?: number;
    query?: string;
  }) => Omit<SWRResponse<KlassesResponse, Error>, 'mutate'> & {
    mutate: (data?: KlassesResponse, shouldRevalidate?: boolean) => Promise<KlassesResponse | undefined>;
    isLoading: boolean;
  };
}

interface CleverProviderProps {
  children?: ReactNode;
}

const fetcher: Fetcher<StudentsResponse | SchoolsResponse | TeachersResponse | KlassesResponse, string> = (url: string) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
    headers: { 'KODABLE-API-TOKEN': cookies.get('kodable_kode') }
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    });
};

export const CleverProvider: React.FC<CleverProviderProps> = ({ children }) => {
  const { redirectToLogin } = useRedirectToLogin();
  const location = useLocation();

  const swrConfig = {
    revalidateOnFocus: false,
    fetcher,
    onError: (error: any, key: string) => {
      if (error.status === 401 && !isPublicRoute(location)) {
        redirectToLogin();
      }
    },
    dedupingInterval: 900000, // 15 minutes in milliseconds
  };

  const useStudents = (params?: {
    page?: number;
    per_page?: number;
    include_hidden_klasses?: boolean;
    query?: string;
  }) => {
    const page = params?.page ?? 1;
    const per_page = params?.per_page ?? 50;
    const include_hidden_klasses = params?.include_hidden_klasses ?? false;
    const query = params?.query ?? '';

    const queryParams = new URLSearchParams({
      page: page.toString(),
      per_page: per_page.toString(),
      include_hidden_klasses: include_hidden_klasses.toString(),
      query,
    });

    const url = `v3/clever/students?${queryParams.toString()}`;

    const { data, error, isValidating, mutate: originalMutate } = useSWR<StudentsResponse, Error>(url);

    const mutate = (newData?: StudentsResponse, shouldRevalidate = true) => {
      return originalMutate(newData, shouldRevalidate);
    };

    const isLoading = !error && !data;

    return { data, error, isValidating, mutate, isLoading };
  };

  const useSchools = (params?: {
    page?: number;
    per_page?: number;
    query?: string;
  }) => {
    const page = params?.page ?? 1;
    const per_page = params?.per_page ?? 50;
    const query = params?.query ?? '';

    const queryParams = new URLSearchParams({
      page: page.toString(),
      per_page: per_page.toString(),
      query,
    });

    const url = `v3/clever/schools?${queryParams.toString()}`;
    const { data, error, isValidating, mutate: originalMutate } = useSWR<SchoolsResponse, Error>(url);

    const mutate = (newData?: SchoolsResponse, shouldRevalidate = true) => {
      return originalMutate(newData, shouldRevalidate);
    };

    const isLoading = !error && !data;

    return { data, error, isValidating, mutate, isLoading };
  };

  const useTeachers = (params?: {
    page?: number;
    per_page?: number;
    include_hidden_klasses?: boolean;
    query?: string;
  }) => {
    const page = params?.page ?? 1;
    const per_page = params?.per_page ?? 50;
    const include_hidden_klasses = params?.include_hidden_klasses ?? false;
    const query = params?.query ?? '';

    const queryParams = new URLSearchParams({
      page: page.toString(),
      per_page: per_page.toString(),
      include_hidden_klasses: include_hidden_klasses.toString(),
      query,
    });

    const url = `v3/clever/teachers?${queryParams.toString()}`;

    const { data, error, isValidating, mutate: originalMutate } = useSWR<TeachersResponse, Error>(url);

    const mutate = (newData?: TeachersResponse, shouldRevalidate = true) => {
      return originalMutate(newData, shouldRevalidate);
    };

    const isLoading = !error && !data;

    return { data, error, isValidating, mutate, isLoading };
  };

  const useKlasses = (params?: {
    page?: number;
    per_page?: number;
    query?: string;
  }) => {
    const page = params?.page ?? 1;
    const per_page = params?.per_page ?? 50;
    const query = params?.query ?? '';

    const queryParams = new URLSearchParams({
      page: page.toString(),
      per_page: per_page.toString(),
      query,
    });

    const url = `v3/clever/classes?${queryParams.toString()}`;
    const { data, error, isValidating, mutate: originalMutate } = useSWR<KlassesResponse, Error>(url);

    const mutate = (newData?: KlassesResponse, shouldRevalidate = true) => {
      return originalMutate(newData, shouldRevalidate);
    };

    const isLoading = !error && !data;

    return { data, error, isValidating, mutate, isLoading };
  };

  const contextValue: CleverContextType = {
    useStudents,
    useSchools,
    useTeachers,
    useKlasses,
  };

  return (
    <SWRConfig value={swrConfig}>
      <CleverContext.Provider value={contextValue}>
        {children}
      </CleverContext.Provider>
    </SWRConfig>
  );
};

// Create the context
const CleverContext = createContext<CleverContextType | undefined>(undefined);

// Custom hook to use the Clever data
export const useClever = (): CleverContextType => {
  const context = useContext(CleverContext);
  if (context === undefined) {
    throw new Error('useClever must be used within a CleverProvider');
  }
  return context;
};