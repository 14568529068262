import endpoints from "endpoints";
import React, { useEffect } from "react";
import { useMemo } from "react";
import useSWR from "swr";
import { IUserProfile } from "types/IUserProfile";
import ISession from "types/ISession";
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes";
import { differenceInMinutes } from "date-fns";

const cookies = new Cookies();
interface IAuthContext {
  loading: boolean;
  error: boolean;
  session: ISession;
  currentUser: IUserProfile;
}

const AuthContext = React.createContext<IAuthContext>({} as any);

const AuthProvider: React.FC = ({ children }) => {
  const { data: sessionData, error: sessionError, isValidating } = useSWR<ISession>(endpoints.session);
  const sessionLoading = !sessionData && !sessionError && isValidating;
  const hasOrg = sessionData?.user?.user_role == 'super_admin' || sessionData?.user?.user_role == 'admin' || sessionData?.user?.user_role == 'member';
  const needsOnboarding = !sessionData?.user?.is_dashboard_onboarded && !hasOrg;

  const authValue = useMemo(() => ({
    loading: sessionLoading,
    error: sessionError,
    session: sessionData! && sessionData,
    currentUser: sessionData?.user! && {
      ...sessionData?.user!,
      needsTeacherOnboarding: sessionData?.user.is_parent === false && !sessionData.user.school_id && !sessionData.user.is_non_usa && needsOnboarding
    }
  }), [sessionLoading, sessionData, sessionError]);

  useEffect(() => {
    if (sessionData?.user) {
      const diff = differenceInMinutes(new Date(), new Date(sessionData?.user.created_at));

      if (diff < 5) {
        cookies.set('hide_fuzzbuzz_welcome_back', 'true', { path: '/' });
      }
    }
  }, [sessionData])

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const useAuth = () => {
  return React.useContext(AuthContext);
}

export const useRedirectToLogin = () => {
  const location = useLocation();
  const history = useHistory();

  return {
    redirectToLogin: (logout: boolean = false) => {
      const domain = process.env.REACT_APP_LOCAL
        ? 'localhost'
        : '.kodable.com';

      cookies.remove('kodable_kode', { domain, path: '/' });
      cookies.remove('admin_kodable_kode', { domain, path: '/' });
      cookies.remove('kodable_oauth_access_token', {domain, path: '/'})

      if (logout) {
        window.location.href = `${routes.login}/${!logout ? `?next=${encodeURIComponent(location.pathname === '//' ? '/' : location.pathname)}` : ''}`;
      } else if (!location.pathname.startsWith(routes.login)) {
        history.push(`${routes.login}/${!logout ? `?next=${encodeURIComponent(location.pathname === '//' ? '/' : location.pathname)}` : ''}`);
      }
    }
  }
}