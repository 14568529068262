import { Box} from "@material-ui/core";
import React from "react";
import { ICourseData } from "../ICourseData";
import CourseCardSelector from "./CourseCardSelector";

export const CoursesList: React.VFC<{courses: ICourseData[]}> = ({courses}) => {

    return (
        <Box 
            display="flex" 
            flexWrap="wrap"
            style={{gap: "25px"}}
        >
        {courses!.map(course => {
            return <CourseCardSelector
                key={course.id}
                course={course}
                />
        })}
        </Box>
    )
}

export default CoursesList;