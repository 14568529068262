import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

//Auth Route
import BaseAuthRoute from 'components/auth/BaseAuthRoute';

//Routes
import LearningIndexPage from 'pages/learning/LearningIndexPage';
import VideosIndexPage from 'pages/videos/VideosIndexPage';
import StudentPage from 'pages/students/StudentPage';
import SettingsPage from 'pages/settings/SettingsPageIndex';
import UpdateBillingPage from 'pages/settings/UpdateBillingPage';
import CancelSubscriptionPage from 'pages/settings/CancelSubscriptionPage';
import ResetTokenPage from 'pages/password/ResetTokenPage';
import UpgradeInfoPage from 'pages/upgrade/UpgradeInfoPage';
import UpgradePlanPage from 'pages/upgrade/UpgradePlanPage';
import UpgradeSuccessPage from 'pages/upgrade/UpgradeSuccessPage';
import KidsIndexPage from 'pages/kids/KidsIndexPage';
import OnboardingIndexPage from 'pages/onboarding/OnboardingIndexPage';
import UpdateSchoolSearchPage from 'pages/settings/teacher/UpdateSchoolSearchPage';
import UpdateSchoolResultsPage from 'pages/settings/teacher/UpdateSchoolResultsPage';
import UpdateSchoolSuccessPage from 'pages/settings/teacher/UpdateSchoolSuccessPage';
import ViewClassesList from 'pages/classes/ClassList/ViewClassesList';
import ViewClassIndex from 'pages/classes/ViewClassIndex';
import StudentsIndexPage from 'pages/students/StudentsIndexPage';
import useCurrentUser from 'loaders/useCurrentUser';
import AdminIndex from 'pages/admin/AdminIndex';
import DemoInstructionsIndex from 'pages/demo/DemoInstructions';
import ParentConnect from 'pages/classes/ParentConnect'
import ViewCourse from 'pages/courses/ViewCourse';
import CleverSync from 'pages/classes/CleverSync';
import queryString from 'query-string';
import BulkAddClasses from 'pages/classes/BulkAddClasses';
import ImportGoogleClasses from 'pages/classes/ImportGoogleClasses';
import ReportsIndex from 'pages/reports/ReportsIndex';
import { routes } from 'routes';
import { SalesIndex } from 'pages/sales/SalesIndex';
import { GodmodeIndex } from 'pages/godmode/GodmodeIndex';
import useSWR from 'swr';
import { ITeacherData } from 'types/ITeacherData';
import endpoints from 'endpoints';
import { TeacherOnboardingIndex } from 'pages/teacher-onboarding/TeacherOnboardingIndex';
import { BackToSchoolLandingPage } from 'pages/promo/BackToSchoolLandingPage';
import { HocLandingPage } from 'pages/hoc/HocLandingPage';
import { HocCertificatePrintout } from 'pages/hoc/HocCertificatePrintout';
import { ParentLettersPrintout } from 'pages/printouts/ParentLettersPrintout';
import { ValentinesIndex } from 'pages/valentines/ValentinesIndex';
import { ViewValentinesByClass } from 'pages/valentines/ViewValentinesByClass';
import { ValentinesPrintout } from 'pages/printouts/ValentinesPrintout';
import { StudentHandoutsPrintout } from 'pages/printouts/StudentHandoutsPrintout';
import ReferralPage from 'pages/events/ReferralPage';
import PasswordResetPage from "./pages/password/PasswordResetPage";
import CurriculumPage from "./pages/curriculum/CurriculumPage";
import CoursesIndex from 'pages/courses/CoursesIndex';
import React from "react";
import CleverPage from "./pages/clever/CleverPage";
import Error from "./pages/ErrorPage";
import QuoteLookup from "./pages/sales/QuoteLookup";
import { SuperAdminOnboardingAssignLicenses } from 'pages/teacher-onboarding/SuperAdminOnboardingAssignLicenses';

export interface IRouteParams {
  plan: 'monthly' | 'yearly' | 'lifetime';
  studentCode: string;
  id: string;
  studentId: string;
  classId: string;
  courseId: string;
  lessonId: string;
  unitId: string;
  cleverToken: string;
  secret_code: string;
  teacherId: string;
  schoolId: string;
  orderId: string;
  subscriptionId: string;
  organizationProfileId: string;
}

export const parentHome = routes.learningTools;
export const teacherHome = routes.classes.index;

const AppRoutes: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: teacherData } = useSWR<ITeacherData>(currentUser?.is_parent === false ? endpoints.teacherInit : null);
  const next = queryString.parse(window.location.search).next as string;

  const needsLoadData = !currentUser || !teacherData;
  const hasUserRole = currentUser?.user_role == 'member' || currentUser?.user_role == 'admin' || currentUser?.user_role == 'super_admin'; // if user has a role, they are part of an org, so they don't need to add school
  const needsAddSchool = currentUser && !currentUser?.school_id && !currentUser?.is_non_usa && !hasUserRole;
  const needsAddFirstClass = teacherData?.klasses.length === 0;
  const isAdmin = currentUser?.is_administrator;

  const noUITeacher = needsLoadData || needsAddSchool || (needsAddFirstClass && !isAdmin);

  return (
    <Switch>
      <Redirect from="/classes/list/connect" to="/classes/list?connectWithFamilies=1" />
      <BaseAuthRoute
        component={PasswordResetPage}
        path={routes.password.reset}
        noUI
      />
      <BaseAuthRoute
        component={SuperAdminOnboardingAssignLicenses}
        path={routes.teacherOnboarding.assignLicenses}
        noUI
      />
      <BaseAuthRoute
        component={ResetTokenPage}
        path={routes.password.resetToken}
        noUI
      />
      <BaseAuthRoute
        component={LearningIndexPage}
        trackingPageTitle="Learning Tools"
        path={routes.learningTools}
        allowedUsers="parent"
      />
      <BaseAuthRoute
        trackingPageTitle="Videos"
        component={VideosIndexPage}
        path={routes.parentVideos}
      />
      <BaseAuthRoute
        trackingPageTitle="Videos"
        component={VideosIndexPage}
        path={routes.videos}
      />
      <BaseAuthRoute
        trackingPageTitle="Curriculum"
        component={CurriculumPage}
        path={routes.curriculum.lesson()}
        hideAppBar
      />
      <BaseAuthRoute
        component={StudentsIndexPage}
        path={routes.students.index}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={CleverPage}
        path={routes.clever.index}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={StudentPage}
        path={routes.students.viewStudent()}
        allowedUsers="parent"
      />
      <BaseAuthRoute
        component={SettingsPage}
        trackingPageTitle="Account Info"
        exact
        path={routes.settings.index}
      />
      <BaseAuthRoute
        component={SettingsPage}
        exact
        path={`${routes.settings.index}/billing`}
      />
      <BaseAuthRoute
        component={UpdateBillingPage}
        trackingPageTitle="Billing Details"
        exact
        path={routes.settings.updateBilling}
        allowedUsers="parent"
      />
      <BaseAuthRoute
        component={CancelSubscriptionPage}
        trackingPageTitle="Cancel Subscription"
        exact
        path="/settings/cancel-subscription"
        allowedUsers="parent"
      />
      <BaseAuthRoute
        component={UpdateSchoolSearchPage}
        exact
        path={routes.settings.updateSchoolSearch}
        noUI={noUITeacher}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={UpdateSchoolResultsPage}
        exact
        noUI={noUITeacher}
        path={routes.settings.updateSchoolResults}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={UpdateSchoolSuccessPage}
        exact
        noUI={noUITeacher}
        path={routes.settings.updateSchoolSuccess}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={UpgradeInfoPage}
        exact
        path="/parent/upgrade/info"
        allowedUsers="parent"
      />
      <BaseAuthRoute
        noUI
        component={UpgradePlanPage}
        path="/parent/upgrade/plan/:plan?"
        allowedUsers="parent"
      />
      <BaseAuthRoute
        noUI
        component={UpgradeSuccessPage}
        path="/parent/upgrade/success"
        allowedUsers="parent"
      />
      <BaseAuthRoute
        component={OnboardingIndexPage}
        noUI
        path={routes.onboarding.index}
        allowedUsers="parent"
      />
      <BaseAuthRoute
        component={ViewClassesList}
        path={routes.classes.index}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={undefined}
        redirect={routes.teacherOnboarding.addClassClever.index}
        path={routes.classes.import.clever}
        exact
        allowedUsers="teacher"
        noUI={noUITeacher}
      />
      <BaseAuthRoute
        component={ImportGoogleClasses}
        path={routes.classes.import.google}
        exact
        allowedUsers="teacher"
        noUI={noUITeacher}
      />
      <BaseAuthRoute
        component={undefined}
        redirect={routes.teacherOnboarding.addClassManual.index}
        path={routes.classes.addNew.index}
        allowedUsers="teacher"
        noUI={noUITeacher}
      />
      <BaseAuthRoute
        component={BulkAddClasses}
        path={routes.classes.bulkAdd}
        allowedUsers="teacher"
        noUI={noUITeacher}
      />
      <BaseAuthRoute
        component={ViewClassIndex}
        path={routes.class.index()}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={DemoInstructionsIndex}
        path={routes.demo.index}
        exact
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={CoursesIndex}
        path={routes.courses.index}
        exact
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={ParentConnect}
        path={routes.parentConnect}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={ViewCourse}
        path={routes.courses.viewCourse.index()}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={AdminIndex}
        path={routes.admin.index}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={CleverSync}
        path={routes.cleverSync}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={ReportsIndex}
        path={routes.reports.index}
        allowedUsers="teacher"
      />
      <BaseAuthRoute
        component={SalesIndex}
        path={routes.sales.index}
      />
      <BaseAuthRoute
        component={GodmodeIndex}
        path={routes.godmode.index}
      />

      <BaseAuthRoute
        component={TeacherOnboardingIndex}
        path={routes.teacherOnboarding.index}
        noUI={noUITeacher}
      />

      <BaseAuthRoute
        component={BackToSchoolLandingPage}
        path={routes.promo.backToSchool}
      />

      <BaseAuthRoute
        component={HocLandingPage}
        path={routes.hoc.index}
      />

      <BaseAuthRoute
        component={ReferralPage}
        path={routes.referral.landing}
      />

      <BaseAuthRoute
        component={ViewValentinesByClass}
        path={routes.valentines.viewClass()}
      />

      <BaseAuthRoute
        component={ValentinesIndex}
        path={routes.valentines.index}
      />

      <BaseAuthRoute
        component={QuoteLookup}
        path={routes.sales.quotes.lookup}
        noUI
      />

      <Route component={HocCertificatePrintout} path={routes.handouts.hocCertificate()} />
      <Route component={ParentLettersPrintout} path={routes.handouts.parentLetters()} />
      <Route component={ValentinesPrintout} path={routes.handouts.valentinesHandouts()} />
      <Route component={StudentHandoutsPrintout} path={routes.handouts.studentHandouts()} />
      <Route component={Error} path={routes.error} />

      <Route path="/kids/:studentCode" component={KidsIndexPage} />
      <Redirect from="*" to={next ? next : (currentUser?.is_parent ? parentHome : teacherHome)} />
    </Switch>
  );
}

export default AppRoutes;