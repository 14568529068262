import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { DialogStateProps } from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import { ISalesProfile } from "pages/settings/teacher/ISalesProfile";
import React, { useEffect } from "react";
import { useState } from "react";
import useSWR, { mutate } from "swr";

const UnlinkAccountDialog: React.VFC<DialogStateProps & { userId?: number }> = ({ open = false, onClose = () => { }, userId }) => {
  const { currentUser } = useCurrentUser();
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [unlinkError, setUnlinkError] = useState(false);
  const sharedClasses = useSharedStyles();
  const alert = useAlert();
  const { data: salesProfile } = useSWR<ISalesProfile>(endpoints.salesProfile(currentUser.id));

  const handleUnlink = () => {
    setIsUnlinking(true);
    justFetch(endpoints.unlinkTeacher(userId || currentUser.id), 'POST')
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }
        mutate(endpoints.salesProfile(userId || currentUser.id));
        mutate(endpoints.session);
        onClose();
        alert.success('Admin Account Unlinked');
      })
      .catch(() => setUnlinkError(true));
  }

  useEffect(() => {
    setIsUnlinking(false);
    setUnlinkError(false);
  }, [open]);

  const isAdmin = () => currentUser?.user_role == 'admin';
  const hasSuperAdmin = () => isAdmin() && salesProfile?.administrator?.id != currentUser.id

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: isUnlinking ? 'visible' : 'hidden'}} />
    <DialogTitle>Unlink Account?</DialogTitle>
    <DialogContent className={sharedClasses.vspacing2}>
      <Typography>You won't be able to access your school's Kodable subscription after unlinking your account.</Typography>
      {hasSuperAdmin() && isAdmin()  && <Alert severity="error">As an administrator who is part of an organization, you need to contact your organization administrator {salesProfile?.administrator?.name} at {salesProfile?.administrator?.username} to perform this action!</Alert>}
      {!hasSuperAdmin() && isAdmin()  && <Alert severity="error">You can't leave the organization you are the administrator of!</Alert>  /* org admins shouldnt have the Leave Org button, but just in case. todo: delete once qa confirms */}
      {unlinkError && <Alert severity="error" action={<Button size="small" color="inherit" onClick={handleUnlink}>Try again</Button>}>There was an error unlinking your account.</Alert>}
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        disabled={isUnlinking}
        onClick={onClose}
      >Cancel</Button>
      <Button
        color="red"
        variant="contained"
        onClick={handleUnlink}
        disabled={isUnlinking || isAdmin()}
      >Unlink</Button>
    </DialogActions>
  </Dialog>
}

export default UnlinkAccountDialog;