import {useLayoutEffect, useState} from "react";
import {Box, Paper} from "@material-ui/core";
import {GridColDef} from "@material-ui/data-grid";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";
import {ICleverSchool, useClever} from "../../context/CleverProvider";

const CleverSchools: React.VFC = () => {
  const [schools, setSchools] = useState<ICleverSchool[]>([])
  const [query, setQuery] = useState<string>('')
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const {useSchools} = useClever();
  const {data: schoolData, isValidating} = useSchools({
    page: page+1,
    per_page: pageSize,
    query
  })

  useLayoutEffect(() => {
    setTotalRowCount(schoolData?.total_count || 0)
    if (schoolData?.schools) {
      setSchools(schoolData.schools)
    }
  }, [schoolData])

  const columns: GridColDef[] = [
    { field: 'school_name', headerName: 'School Name', width: 300 },
    { field: 'klass_count', headerName: '# Classes', width: 120, type: 'number' },
    { field: 'teacher_count', headerName: '# Teachers', width: 135, type: 'number' },
    { field: 'student_count', headerName: '# Students', width: 135, type: 'number' },
  ]

  return (
    <Box ml={2} mr={2}>
      <CleverButtonToolbox
        searchPlaceholder={"Search Schools"}
        searchDisabled={schools.length === 0 && query.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        toolTip={'These are the Clever schools currently syncing with Kodable.'}
      />
      <Box component={Paper}>
        <CleverTable
          page={page}
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          columns={columns}
          rows={schools}
          loading={isValidating}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverSchools