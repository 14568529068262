import {useSalesInitialSelectionStyles} from "../../SalesSiteTypeSelections";
import PageContainer from "../../../../components/ui/PageContainer";
import {useOrganizationSelection} from "../../../../context/OrganizationSelectionProvider";
import useSharedStyles from "../../../../components/useSharedStyles";
import useCurrentUser from "../../../../loaders/useCurrentUser";
import {useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Divider, Typography} from "@material-ui/core";
import {routes} from "../../../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFlag, faFlagUsa} from "@fortawesome/free-solid-svg-icons";
import { getConfigPlanURL } from "./SubmitOrderIndex";

const OrderSelectLocationTypePage: React.VFC = () => {
  const {setIsOutsideUS, setSiteType, setSchoolId, setDistrictId, setIsLocationSet} = useOrganizationSelection()
  const configureStyles = useSalesInitialSelectionStyles()
  const sharedClasses = useSharedStyles()
  const {currentUser, loading} = useCurrentUser()
  const history = useHistory();

  useEffect(() => {
    if (!currentUser) {
      return
    }
    if (!currentUser?.organization_profile) {
      return
    }

    if (currentUser?.organization_profile?.district_id) {
      setSiteType('district')
      setDistrictId(currentUser?.organization_profile?.district_id)
      return
    }

    if (currentUser?.organization_profile?.school_id) {
      setSiteType('school')
      setSchoolId(currentUser?.organization_profile?.school_id)
    }
  }, [currentUser, setSiteType, setSchoolId, setDistrictId])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress/>
      </Box>
    )
  }

  return (

      <Box className={sharedClasses?.vspacing4} display="flex" flexDirection="column" p={2} pb={0}>
        <Typography
          variant="h1"
        >
          Are you located in the United States, or somewhere else?
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            className={configureStyles?.optionBox}
            onClick={() => {
              setIsOutsideUS(false);
              history.push(routes.sales.orders.submit.selectOrgType)
            }}>
            <FontAwesomeIcon className={configureStyles?.optionBoxIcon} icon={faFlagUsa}/>
            <Typography className={configureStyles?.optionBoxText}>United States</Typography>
          </Box>
          <Box
            className={configureStyles?.optionBox}
            onClick={() => {
              setIsOutsideUS(true)
              setSiteType('other')
              setIsLocationSet(true)
              history.push(getConfigPlanURL())
            }}
          >
            <FontAwesomeIcon className={configureStyles?.optionBoxIcon} icon={faFlag}/>
            <Typography className={configureStyles?.optionBoxText}>Somewhere else</Typography>
          </Box>
        </Box>
      </Box>

  )
}

export default OrderSelectLocationTypePage