import {Box, Paper} from "@material-ui/core";
import {justFetch} from "../../mutations/mutate";
import endpoints from "../../endpoints";
import {useLayoutEffect, useState} from "react";
import {GridColDef} from "@material-ui/data-grid";
import {ConfirmationDialog} from "../../components/dialogs/base/ConfirmationDialog";
import useDialogState from "../../hooks/useDialogState";
import HiddenKlassDialog from "./components/HiddenKlassDialog";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";
import {ICleverKlass, useClever} from "../../context/CleverProvider";


const CleverClasses: React.VFC = () => {
  const [klasses, setKlasses] = useState<ICleverKlass[]>([])
  const [query, setQuery] = useState<string>('');
  const [selectedKlasses, setSelectedKlasses] = useState<any[]>([])
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [hiddenKlasses, setHiddenKlasses] = useState<ICleverKlass[]>([]);
  const hideClassDialogState = useDialogState()
  const showHiddenClassDialogState = useDialogState()
  const [isSubmittingHidden, setIsSubmittingHidden] = useState(false)
  const {useKlasses} = useClever();
  const {data: klassData, isValidating, mutate} = useKlasses({
    page: page+1,
    per_page: pageSize,
    query
  })

  const getHiddenKlasses = async () => {
    const resp = await justFetch(endpoints.clever.allHiddenKlasses, 'GET')
    let data = await resp.json()

    setHiddenKlasses(data.klasses.map((klass: ICleverKlass) => {
      return {...klass, id: `${klass.klass_id}`, teachers_shown: false}
    }))
  }

  useLayoutEffect(() => {
    setTotalRowCount(klassData?.total_count || 0)
    if (klassData?.klasses) {
      setKlasses(klassData.klasses.map((klass: ICleverKlass) => {
        return {...klass, id: `${klass.klass_id}`, teachers_shown: false}
      }))
    }
  }, [klassData]);


  useLayoutEffect(() => {
    getHiddenKlasses()
  }, [])

  const columns: GridColDef[] = [
    { field: 'klass_name', headerName: 'Class Name', width: 300 },
    { field: 'teachers', headerName: 'Teacher Name', width: 300 },
    { field: 'clever_id', headerName: 'Clever ID', width: 250 },
    { field: 'school_name', headerName: 'School Name', width: 250 },
    {
      field: 'student_count',
      headerName: '# Students',
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'last_sync', headerName: 'Last Sync', type: 'date' },
  ]

  const onHideClasses = async () => {
    setIsSubmittingHidden(true)
    const klassIds = selectedKlasses.map(klassId => Number(klassId))
    const resp = await justFetch(endpoints.bulkHideKlasses, 'POST', {klass_id: klassIds})
    if (resp.ok) {
      setPage(0)
      await getHiddenKlasses()
      setIsSubmittingHidden(false)
    }
    hideClassDialogState.onClose()
  }

  const onEnabledKlass = async () => {
    showHiddenClassDialogState.onClose()
    await mutate()
    setPage(0)
    await getHiddenKlasses()
  }

  return (
    <Box ml={2} mr={2}>
      <ConfirmationDialog
        open={hideClassDialogState.open}
        onClose={() => {
          hideClassDialogState.onClose()
          setIsSubmittingHidden(false)
        }}
        submitting={isSubmittingHidden}
        title={`Are you sure you want to hide ${selectedKlasses.length === 1 ? 'this class?' : 'these classes?'}`}
        subtitle={`Once ${selectedKlasses.length ? 'this class is' : 'these classes are'} hidden, teachers and students will no longer have access to ${selectedKlasses.length === 1 ? 'it' : 'them'}. Hidden classes do not count against your student limit.`}
        onConfirm={onHideClasses}
      />
      <HiddenKlassDialog open={showHiddenClassDialogState.open} onClose={showHiddenClassDialogState.onClose} onEnabledKlass={onEnabledKlass} klasses={hiddenKlasses} />
      <CleverButtonToolbox
        hideClass={true}
        showHiddenClass={true}
        searchPlaceholder={"Search Classes"}
        searchDisabled={klasses.length === 0 && query.length === 0}
        showHiddenClassDisabled={hiddenKlasses.length === 0}
        hideClassDisabled={selectedKlasses.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        onHideClassClick={hideClassDialogState.handleOpen}
        onShowHiddenClassClick={showHiddenClassDialogState.handleOpen}
        toolTip={'These are the classes currently shared with Kodable through Clever. Ensure only the necessary classes are being shared, if any are missing please contact your Clever administrator.'}
      />
      <Box component={Paper}>
        <CleverTable
          columns={columns}
          rows={klasses}
          page={page}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          loading={isValidating}
          checkboxSelection={true}
          onRowSelect={setSelectedKlasses}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverClasses