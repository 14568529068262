import {Box, makeStyles, Paper} from "@material-ui/core";
import React from "react";


export const columnWidth = 220;
export const headerHeight = 175;

export const useStudentsProgressStyles = makeStyles(theme => ({
    stickyCourses: {
        position: 'sticky',
        top: '0rem',
        zIndex: 4
    },
    clickableStudent: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
        }
    },
    staticStudent: {
        position: 'sticky',
        left: '0rem',
        zIndex: 3
    },
}));

const useClassProgressStudentRowStyles = makeStyles(theme => ({
    cell: {
        border: `1px solid rgba(0, 0, 0, 0.2)`,
        borderRight: 'none',
        '&:not(:last-child)': {
            borderBottom: 'none'
        },
        '&:is(:last-child)': {
            borderRight: '1px'
        }
    },
    insideCell: {
        borderTop: `1px solid rgba(0, 0, 0, 0.2)`,
        borderLeft: 'none',
        borderRight: 'none'
    },
}));

export const ClassProgressStudentRowCell: React.FC<{ hideBorder?: boolean, width?: number }> = ({ children, width = columnWidth, hideBorder = false }) => {
    const classProgressStudentRowClasses = useClassProgressStudentRowStyles();

    return <Box
      bgcolor="white"
      width={width}
      height={66}
      p={1}
      className={hideBorder ? classProgressStudentRowClasses.insideCell : classProgressStudentRowClasses.cell}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
    >
        {children}
    </Box>
}


export const ProgressTable: React.VFC<{topLeft: React.ReactNode, header: React.ReactNode, firstColumn: React.ReactNode, content: React.ReactNode, width: number}> = ({ topLeft, header, firstColumn, content, width}) => {
    const studentsProgressStyles = useStudentsProgressStyles();
    return <Box style={{
        maxHeight: "63vh",
        height: "auto",
        maxWidth: "88vw",
        width: "auto",
        overflowY: "auto",
    }}>
        <Box display="flex" key="header" component={Paper} {...{ elevation: 1 }} flexDirection="row" className={studentsProgressStyles.stickyCourses} width={width + columnWidth}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={`${headerHeight}px`} zIndex="4" style={{
                textAlign: 'center',
                minWidth: columnWidth,
                maxWidth: columnWidth,
                borderTop: `1px solid rgba(0, 0, 0, 0.2)`,
                borderLeft: `1px solid rgba(0, 0, 0, 0.2)`,
                borderTopLeftRadius: '25px'
            }}>
                {topLeft}
            </Box>
            {header}
        </Box>
        <Box display="flex" key="content" flexDirection="column" width="auto" height="auto" style={{
            minWidth: "100%",
            maxWidth: "100%"
        }}>
            <Box display="flex" key="content" flexDirection="row" width={width + columnWidth} height="auto">
                <Box display="flex" flexDirection="column" component={Paper} {...{ elevation: 0 }} className={studentsProgressStyles.staticStudent}>
                    {firstColumn}
                </Box>
                <Box display="flex" flex="0" key="content" flexDirection="row">
                    {content}
                </Box>
            </Box>
            <Box height="25px" key="aesthetic-spacer" width="auto" bgcolor="white" style={{
                border: `1px solid rgba(0, 0, 0, 0.2)`,
                borderTop: 'none',
                borderBottomLeftRadius: '25px',
                borderBottomRightRadius: '25px',
                minWidth: width + columnWidth,
                maxWidth: width + columnWidth
            }}></Box>
        </Box>

    </Box>
}

export const SingleProgressTable: React.VFC<{topLeft: React.ReactNode, header: React.ReactNode, content: React.ReactNode}> = ({ topLeft, header, content}) => {
    return (
        <Box display="flex" flexDirection="row" width="auto" height="auto" component={Paper} {...{elevation: 0}} >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                 width={`${columnWidth}px`} height={`auto`} style={{
                textAlign: 'center',
                border: `1px solid rgba(0, 0, 0, 0.2)`,
                borderRight: `none`,
                borderTopLeftRadius: '25px',
                borderBottomLeftRadius: '25px'
            }}>
                {topLeft}
            </Box>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    {header}
                </Box>
                <Box display="flex" flexDirection="row" width="auto" height="auto">
                    {content}
                </Box>
            </Box>
        </Box>
    )
}

export default ProgressTable;