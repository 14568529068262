import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setSessionTokenCookie = (session_token: string, admin = false, access_token: string | null = null) => {

  const cookieName = admin ? 'admin_kodable_kode' : 'kodable_kode';
  const domainURL = process.env.REACT_APP_LOCAL ? 'localhost' : '.kodable.com';

  cookies.set( cookieName, session_token, {
    domain: domainURL,
    path: '/',
    sameSite: 'lax'
  });

  const is_dev = window.location.href.includes('dev') || window.location.href.includes('webflow');
  if (is_dev)
  {
    const domainAltURL = process.env.REACT_APP_LOCAL ? 'localhost' : '.webflow.io';
    cookies.set( cookieName, session_token, {
      domain: domainAltURL,
      path: '/',
      sameSite: 'lax',
    });
  }

  if (access_token === null) 
  {
    return
  }

  cookies.set('kodable_oauth_access_token', access_token, {
    domain: domainURL,
    path: '/'
  })
}

export const googleClassroomToken = {
  set: (token: string | null) => {
    cookies.set('kodable_oauth_gclassroom_token', token, {
      domain: process.env.REACT_APP_LOCAL
      ? 'localhost'
      : '.kodable.com',
      path: '/'
    })
  },
  get: () => { return cookies.get('kodable_oauth_gclassroom_token') }
}

export const getAccessTokenCookieVal = () => {
  return cookies.get('kodable_oauth_access_token')
}