import {DialogStateProps} from "../../../../../hooks/useDialogState";
import {SchoolInfoType} from "./SiteToSchoolBox";

import { faSearch, faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText
} from "@material-ui/core";
import MenuItem from 'components/ui/MenuItem';
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import states from "states-us";
import * as Yup from "yup";
import Select from "components/ui/Select";
import endpoints from "endpoints";
import useSWR from "swr";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import RequestAddSchoolDialog from "pages/settings/teacher/RequestAddSchoolDialog";


const useUpdateSchoolResultsPageStyles = makeStyles(theme => ({
  listContainer: {
    maxHeight: '50vh',
    overflowY: 'auto'
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    border: `4px solid ${theme.palette.primary.main}`  
  },
}));


const SearchSchoolResults: React.FC<{ onSelectSchool: (school: SchoolInfoType) => void, location: { zipcode?: string | number; city?: string; state?: string } }> = ({ onSelectSchool, location }) => {
  const { data: schools, error } = useSWR<SchoolInfoType[]>('zipcode' in location ? endpoints.schoolSearchByZip(location.zipcode?.toString() as string) : endpoints.schoolSearchByCity(location.city as string, location.state as string));
  const loading = !schools && !error;
  const classes = useUpdateSchoolResultsPageStyles();
  const sharedClasses = useSharedStyles();
  const [searchString, setSearchString] = useState('');
  const [submissionError, setSubmissionError] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<SchoolInfoType | null>(null);
  const schoolsFound = schools && schools.length > 0;
  const [showRequestSchoolDialog, setShowRequestSchoolDialog] = useState(false);

  const filteredSchools = useMemo(() => {
    const filteredSchools = schools?.filter(({ school_name }) => school_name.toLowerCase().includes(searchString.toLowerCase()));

    if (!filteredSchools?.find(({ id }) => id === selectedSchool?.id)) {
      setSelectedSchool(null);
    }

    return filteredSchools;
    // eslint-disable-next-line
  }, [schools, searchString]);

  const searchParam = (() => {
    if ('zipcode' in location) {
      return "zip code " + location.zipcode;
    }
    return `${location.city}, ${location.state}`;
  } )();

  if (loading) {
    return <Box minHeight={500} maxHeight="80vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  }

  if (error) {
    return <Box className={sharedClasses.vspacing2}>
        <Alert severity="error"
        >Error searching in: {searchParam}</Alert>
      </Box>
  }

  return (
    <>
      <RequestAddSchoolDialog
        open={showRequestSchoolDialog}
        onClose={() => setShowRequestSchoolDialog(false)}
        school_searched_zip={(location as any).zipcode}
        school_mailing_city={(location as any).city}
        school_mailing_state={(location as any).state}
      />
        <Box className={sharedClasses.vspacing2}>
          {schools && schools.length > 0 && <Typography>
            {schools.length} schools found in {searchParam}{schoolsFound ? ':' : '.'} <Link href="#" onClick={e => {
              e.preventDefault();
              setShowRequestSchoolDialog(true);
            }}>
              Can't find your school?
            </Link>
          </Typography>}
          {schools && schools.length === 0 && <Typography>
            We couldn't find any schools in {searchParam}. <Link href="#" onClick={e => {
              e.preventDefault();
              setShowRequestSchoolDialog(true);
            }}>
              Click here to add your school.
            </Link>
          </Typography>}
          {schoolsFound &&
            <TextField
              autoFocus
              value={searchString}
            onChange={e => setSearchString(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder="Start typing to filter results"
            margin="dense"
          ></TextField>
        }
        <Box sx={{ minHeight: '50vh', paddingBottom: '0rem'}}>
        {schoolsFound &&
          <Paper classes={{
            root: classes.listContainer
          }}
          >
            <List style={{ overflowY: 'auto'}}>
              {filteredSchools?.map((school) => {
                return <ListItem 
                  key={school.id} 
                  button 
                  selected={school.id === selectedSchool?.id} 
                  onClick={() => {
                    setSelectedSchool(school);
                    onSelectSchool(school);
                  }}
                  classes={{ selected: classes.selected }}
                  >
                  <ListItemIcon>
                    <FontAwesomeIcon size="2x" icon={faSchool} />
                  </ListItemIcon>
                  <ListItemText primary={school.school_name} secondary={`${school.school_location_city}, ${school.school_location_state} ${`${school.school_location_zip}`.padStart(5, '0')}`}></ListItemText>
                </ListItem>
              })}
            </List>
          </Paper>
        }
        </Box>
        {submissionError && <Alert severity="error">There was an error submitting this form.</Alert>}
      </Box>
    </>
  )
}

const SearchSchoolDialog: React.VFC<
    DialogStateProps & {
      onSuccess: (schoolInfo: SchoolInfoType, nonTraditionalSchoolName?: string) => void, 
      searchOptions: { 
        zipcode?: string | number; 
        city?: string; 
        state?: string; 
        nonTraditionalSchoolName?: string
      },
      enableSetName?: boolean
    }
  > = ({open, onClose, onSuccess, searchOptions, enableSetName = false}) => {
  const sharedClasses = useSharedStyles();
  const [selectedSchool, setSelectedSchool] = useState<SchoolInfoType | null>(null);

  enum SearchPage {
    SearchBy = 1,
    Results,
  }
  const [page, setPage] = useState<SearchPage>(SearchPage.SearchBy);
  
  const form = useFormik({
    initialValues: {
      zipcode: searchOptions?.zipcode || '',
      searchOption: "citystate",
      city: searchOptions?.city || '',
      state: states.find(state=>state.abbreviation.toLowerCase() == searchOptions?.state?.toLowerCase())?.name || '',
      nonTraditionalSchoolName: searchOptions?.nonTraditionalSchoolName || ''
    },
    validationSchema: Yup.object({
      searchOption: Yup.string().oneOf(["zipcode", "citystate"]),
      zipcode: Yup.string().when("searchOption", {
        is: "zipcode",
        then: Yup.string()
          .required("Please enter a zip code.")
          .matches(/^\d{3,5}(-\d{4})?$/, "Please enter a valid zip code."),
      }),
      city: Yup.string().when("searchOption", {
        is: "citystate",
        then: Yup.string().required("Please enter a value."),
      }),
      state: Yup.string().when("searchOption", {
        is: "citystate",
        then: Yup.string().required("Please select a state."),
      }),
    }),
    onSubmit: async ({ searchOption, zipcode, city, state }) => {
      onSearch();
    },
  });

  const onPrevious = () =>
  {
    setSelectedSchool(null);
    setPage(SearchPage.SearchBy);
  }
  const onSearch = () =>
  {
    setPage(SearchPage.Results);
  }
  const onSelectSchool = (school: SchoolInfoType) =>
  {
    setSelectedSchool(school);
  }

  // Set default Options when dialog opens
  useEffect(() => {
    if (open){
      setPage(SearchPage.SearchBy);
      form.setFieldValue("searchOption", "zipcode");
      form.setFieldValue("zipcode", searchOptions?.zipcode || "");
      form.setFieldValue("city", searchOptions?.city || "");
      form.setFieldValue("state", states.find(state=>state.abbreviation.toLowerCase() == searchOptions?.state?.toLowerCase())?.name || "");
      setSelectedSchool(null);
      form.setFieldValue("nonTraditionalSchoolName", searchOptions?.nonTraditionalSchoolName || '');
    }
  }, [open, searchOptions]);

  const firstPartOfZipCode = (zipcode: string | number) =>
  {
    const stringZipCode = String(zipcode);
    let firstPartPadded = stringZipCode.includes('-') ? stringZipCode.split('-')[0] : stringZipCode.substring(0, 5);
    firstPartPadded = firstPartPadded.padStart(5, '0');
    return firstPartPadded;
  }

  const formSearchOptions = ()=>
  {
    return form.values.searchOption=='zipcode'? 
      { zipcode: firstPartOfZipCode(form.values.zipcode)}:
      { city: form.values.city, state: form.values.state};
  }  

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select A School</DialogTitle>
      <DialogContent>

      <form onSubmit={form.handleSubmit}>
        <Box className={sharedClasses.vspacing2}>

          <Typography variant="body1">
            Find the school to link to this license:
          </Typography>

          {page === SearchPage.SearchBy && (
          <Box>
          <RadioGroup
            id="searchOption"
            onChange={(e) =>
              form.setFieldValue("searchOption", e.target.value)
            }
            value={form.values.searchOption}
          >
            <FormControlLabel
              value="zipcode"
              control={<Radio />}
              label="Search by Zip Code"
            />
            {form.values.searchOption === "zipcode" && (
              <Box mb={1} display="flex" alignItems="center">
                <TextField
                  autoFocus
                  onChange={form.handleChange}
                  value={form.values.zipcode}
                  id="zipcode"
                  variant="outlined"
                  placeholder="Enter a zip code"
                  helperText={form.submitCount > 0 && form.errors.zipcode}
                  error={form.submitCount > 0 && !!form.errors.zipcode}
                  margin="dense"
                />
              </Box>
            )}
            <FormControlLabel
              value="citystate"
              control={<Radio />}
              label="Search by City and State"
            />
            {form.values.searchOption === "citystate" && (
              <Box display="flex">
                <Box mr={2} flex={1}>
                  <TextField
                    autoFocus
                    onChange={form.handleChange}
                    value={form.values.city}
                    id="city"
                    variant="outlined"
                    placeholder="Enter a town or city"
                    fullWidth
                    helperText={form.submitCount > 0 && form.errors.city}
                    error={form.submitCount > 0 && !!form.errors.city}
                    margin="dense"
                  />
                </Box>
                <Box>
                  <FormControl
                    error={form.submitCount > 0 && !!form.errors.state}
                  >
                    <Select
                      variant="outlined"
                      fullWidth
                      displayEmpty
                      onChange={(e) =>
                        form.setFieldValue("state", e.target.value)
                      }
                      value={form.values.state}
                      id="state"
                      margin="dense"
                    >
                      <MenuItem disabled value="">
                        Select your state
                      </MenuItem>
                      {states
                        .filter(({ territory }) => !territory)
                        .map(({ name }) => (
                          <MenuItem key={name} value={name}>{name}</MenuItem>
                        ))}
                    </Select>
                    {form.submitCount > 0 && (
                      <FormHelperText>{form.errors.state}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
            )}
            <FormControlLabel
              value="nontraditional"
              control={<Radio />}
              label="Non-traditional / International school"
            />
            {enableSetName && form.values.searchOption === "nontraditional" && (<>
              <TextField
                id="nonTraditionalSchoolName"
                label = "Set school name:"
                variant="outlined"
                value={form.values.nonTraditionalSchoolName}
                margin="normal"
                fullWidth
                onChange={form.handleChange}
              />
            </>)}
          </RadioGroup>
        </Box>
          )}
          
          {page === SearchPage.Results && (
            <SearchSchoolResults 
              onSelectSchool={onSelectSchool} 
              location = {formSearchOptions()}
            />
          )}

          <Box display="flex" justifyContent="center" mt={4}>
            
              <Button 
                color="primary"
                variant="contained"
                onClick={ ()=>{onClose()}}
                style={{ marginRight: '1rem' }}
                >
                  Close
              </Button>

              { page === SearchPage.Results && 
              <Button
                color="primary"
                variant="contained"
                onClick={ ()=>{onPrevious()}}
                style={{ marginRight: '1rem' }}
              >
                Previous
              </Button>
              }

              {(page === SearchPage.SearchBy && form.values.searchOption != "nontraditional") &&
              <Button
                color="primary"
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faSearch} />}
                disableElevation
                type="submit"
                style={{ marginRight: '1rem' }}
              >
                Search
              </Button>
              }

              {(page === SearchPage.Results || form.values.searchOption === "nontraditional") &&
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={ ()=>{ 
                  onSuccess(selectedSchool as SchoolInfoType, form.values.nonTraditionalSchoolName);
                  onClose();
                }}
                style={{ marginRight: '1rem' }}
                disabled={!selectedSchool?.id && form.values.searchOption != "nontraditional" || form.values?.nonTraditionalSchoolName?.length < 1 && form.values.searchOption == "nontraditional" && enableSetName}
              >
                {form.values.searchOption === "nontraditional" && enableSetName? "Set non-traditional school name" : ''}
                {form.values.searchOption === "nontraditional" && !enableSetName? "Select non-traditional school" : ''}
                {form.values.searchOption !== "nontraditional" ? "Select School" : ''}
              </Button>
              }

          </Box>
        </Box>
      </form>
      </DialogContent>

      <Box p={2} display={'flex'} justifyContent={'center'}>
        </Box>

    </Dialog>
  )
}

export default SearchSchoolDialog