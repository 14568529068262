import { Box } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import useTeacherInit from "loaders/useTeacherInit";
import AddStudentsDialog from "components/dialogs/class/AddStudentsDialog";
import StudentsTable from "pages/students/StudentsTable";
import { useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";

const ViewClassStudents = () => {
  const { teacherData } = useTeacherInit();
  const { params } = useRouteMatch<IRouteParams>();
  const currentClass = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id.toString() === params.classId);
  }, [teacherData, params.classId]);

  const [showAddStudentsDialog, setShowAddStudentsDialog] = useState(false);

  return <Box py={4} px={6}>
    <AddStudentsDialog open={showAddStudentsDialog} onClose={() => setShowAddStudentsDialog(false)} targetClassId={params.classId} />
    <StudentsTable embed showPasswordPictures={currentClass?.password_enabled} klassId={parseInt(params.classId)} onAddStudents={() => setShowAddStudentsDialog(true)}  />
  </Box>
};

export default ViewClassStudents;