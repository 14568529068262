import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {Alert} from "@material-ui/lab";

const CleverSecureSyncDialog: React.VFC<{open: boolean, onClose: () => void}> = ({open, onClose}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>Clever Sync</DialogTitle>
      <DialogContent>
        <Alert severity="info">Your school data is currently syncing via Clever Secure Sync. Data shared with us will automatically update in Kodable within the next two hours</Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color='primary'
          onClick={onClose}
        >Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CleverSecureSyncDialog