import { IOrganizationProfileValidInclude } from "types/IOrganizationProfile";

const endpoints = {
  login: `v1/login`,
  session: 'v1/session',
  parent_init: 'v1/users/parent_init',
  recaptcha: 'v1/recaptcha',
  user: (id: string | number) => `v1/users/${id}`,
  password: `/v1/password`,
  schoolSearchByZip: (zipcode: string) => `v1/schools?zip=${zipcode}`,
  schoolSearchByCity: (city: string, state: string) => `v1/schools?city=${city}&state=${state}`,
  searchOrganizationProfiles: (schoolId: string | number | null, districtId: string | number | null, displayName: string | null): string => {
    let queryString = 'v2/organization_profile/search?';

    const params = [];
    if (schoolId !== null) {
      params.push(`school_id=${encodeURIComponent(schoolId)}`);
    }
    if (districtId !== null) {
      params.push(`district_id=${encodeURIComponent(districtId)}`);
    }
    if (displayName !== null) {
      params.push(`display_name=${encodeURIComponent(displayName)}`);
    }

    queryString += params.join('&');

    return queryString;
  },
  allOrganizationProfileNames: 'v2/organization_profile/display_names',
  teachersBySchool: (schoolId: string) => `v1/schools/${schoolId}/teachers`,
  schoolInfo: (schoolId: string) => `v1/schools/${schoolId}`,
  teacherInit: `v1/users/init`,
  checkClassCode: `v1/validate/klass`,
  classes: `v2/klasses`,
  classById: (id: string | number, includes?: string[]) => `v2/klasses/${id}${includes ? `?${includes.map(include => `include_${include}=true`)}` : ''}`,
  addStudent: `v2/students`,
  addStudents: `v2/students?multiple=true`,
  deleteStudents: `v1/multiple_students`,
  moveStudentsToClass: `v1/multiple_students/transfer`,
  studentById: (id: string | number) => `v2/students/${id}`,
  fuzzesByClass: (id: string | number) => `v2/student_fuzzes/class/${id}`,
  fuzzRegen: (student_id: string | number, fuzz_id: string | number) => `v2/student_fuzzes/regen/${student_id}/${fuzz_id}`,
  mazesByClass: (id: string | number) => `v2/mazes?group_by_student=true&klass_id=${id}`,
  mazesByStudentCode: (studentCode: string) => `v2/mazes?student_code=${studentCode.replace('_pi', '')}`,
  contentImageById: (id: string | number) => `https://kodable-user-created-content--${process.env.REACT_APP_HANDOUT_ENV === 'production' ? 'prod' : 'dev'}.s3.amazonaws.com/${id}`,
  getUserCreatedContent: `v2/content/get-content`,
  studentWork: (type: 'fuzz' | 'maze', resolution: 'fullsize' | 'thumbs', id: string | number, extension = type === 'fuzz' ? 'png' : 'jpg') =>
    `https://s3.amazonaws.com/kodable${type}${process.env.REACT_APP_HANDOUT_ENV === 'staging' ? 'test' : ''}/${resolution}/${type}_${id}.${extension}`,
  resetPassword: {
    send: `v1/reset_password/send`,
    complete: `v1/reset_password/complete`
  },
  socialLogin: `v1/social_login`,
  teacher: (teacherId: number | string) => `v2/teachers/${teacherId}`,
  teacherReferral: (teacherId: number | string) => `v2/teachers/${teacherId}/referral`,

  assignLicense: (purchase_process_id: string | number) => `v1/purchases/${purchase_process_id}/activate`,
  unassignLicense: (purchase_process_id: string | number) => `v1/purchases/${purchase_process_id}/unactivate`,
  assignSchool: (purchase_process_id: string | number) => `v1/purchases/${purchase_process_id}/assign-school`,
  linkTeachers: `v2/teachers/link`,
  unlinkTeacher: (userId: string | number) => `v2/teachers/${userId}/unlink`,
  salesQuote: {
    lookup: (quoteId: string) => `v2/quote/lookup/${quoteId}`
  },
  allBasicsCourses: `v2/courses`,
  allBasicsCoursesLegacy: `v2/courses/legacy`,
  allCreatorCourses: `v2/creator_courses`,
  course: (courseId: number | string) => `v2/courses/${courseId}`,
  courseDetails: (courseId: number | string) => `v2/courses/${courseId}/details`,
  coursesForClass: (classId: string | number) => `v2/klasses/${classId}/course`,
  creatorCoursesForClass: (classId: string | number) => `v2/klasses/${classId}/creator-course`, 
  missionsForClass: (classId: string | number) => `v2/klasses/${classId}/missions`,
  district: (ansi_state_code: string | undefined) => `v2/district${ansi_state_code && ansi_state_code?.length > 0 ? `?ansi_state_code=${ansi_state_code}` : ''}`,
  allCreativeBuildings: `v2/creative_buildings_course`,
  allBasicsLevels: `v2/game/basics_levels?exclude_config=true`, // note: exclude_config is currently ignored on API. this returns about 1mb json
  basicsLevelById: (id: string | number) => `v2/game/basics_levels/${id}`, // note: not implemented on the api side
  resources: `v1/resources`,
  resourcesVideos: `v1/resources?file_type=video`,
  resourcesUpload: `v1/resources/upload`,
  resourceById: (id: string | number) => `v1/resources/${id}`,
  resourceArchive: `v1/resources/archive`,
  resourcesByCourseId: (course_id: string | number) => `v1/resources?course_id=${course_id}`,
  cleverKlasses: `v2/klasses/clever`,
  deleteCleverKlass: (cleverId: string) => `v3/clever/remove-klass/${cleverId}`,
  syncClever: `v2/klasses/clever/sync-klasses`,
  cleverDistricts: `v1/clever/admin/districts`,
  cleverDistrictDelete: (clever_id: string | number) => `v1/clever/admin/district/${clever_id}`,
  importStudents: `/v2/students/import`,
  classLevelCompletions: (classId: string | number) => `v2/klasses/${classId}/level-completion`,
  klassesByCode: (classCode: string) => `v2/klasses?klass_code=${classCode}`,
  studentLevelCompletions: (studentId: string | number) => `v2/students/${studentId}/level_completion`,
  subscription: (userId: string | number) => `v1/users/${userId}/subscription`,
  purchaseParentSubscription: (userId: string | number) => `/v1/users/${userId}/purchase-parent-subscription`,
  allSubscriptions: `v1/subscriptions/all`,
  parentSubscriptionPricing: `v1/subscriptions/parent-pricing`,
  requestAddSchool: `v1/schools/request_add`,
  classLessonUnassignments: (classId: string | number) => `v2/klasses/${classId}/lesson`,
  updateClassLessonUnassignments: (classId: string | number, courseId: string | number) => `v2/klasses/${classId}/course/${courseId}/lesson`,
  validateGoogleClassroom: `v1/validate/google_classroom`,
  addGoogleClasses: `v2/klasses/bulk`,
  bulkV2Reports: `v2/reports`,
  klassV2Report: (klass_id: string | number) => `v2/reports/${klass_id}`,
  cleverLogin: `v1/clever/login`,
  clever: {
    allKlasses: (page: number = 1, perPage: number = 50, query: string = '') => `v3/clever/classes?page=${page}&per_page=${perPage}&query=${query}`,
    allHiddenKlasses: `v3/clever/hidden-classes`,
    allStudents: (page: number = 1, perPage: number = 50, includeHiddenKlasses: boolean = false, query: string = '') => `v3/clever/students?page=${page}&per_page=${perPage}&include_hidden_klasses=${includeHiddenKlasses}&query=${query}`,
    allTeachers: (page: number = 1, perPage: number = 50, includeHiddenKlasses: boolean = false, query: string = '') => `v3/clever/teachers?page=${page}&per_page=${perPage}&include_hidden_klasses=${includeHiddenKlasses}&query=${query}`,
    allSchools: (page: number = 1, perPage: number = 50, query: string = '') => `v3/clever/schools?page=${page}&per_page=${perPage}&query=${query}`,
    external: {
      klasses: `v3/clever/external/klasses`
    }
  },
  resetStudentsProgress: `v2/students/reset_progress`,
  sales: {
    quote: {
      exists: 'v2/sales/quote/exists'
    },
    order: {
      exists: 'v2/sales/order/exists'
    },
    purchaseOrder: `/v2/sales/purchase-order`,
    purchaseOrderUrl:(file_number: string) => `/v2/sales/purchase-order/url/${file_number}`,
  },
  quotes: `v2/sales/quote`,
  createQuote: `v2/sales/quote/create`,
  deleteQuote: (secret_code: string) => `v2/sales/quote/${secret_code}`,
  quote: (secret_code: string) => `v2/sales/quote/${secret_code}`,
  shareQuote: (secret_code: string) => `v2/sales/quote/${secret_code}/share`,
  renewQuoteBySubId: (subscription_id: string | number) => `v2/sales/quote/renewal/${subscription_id}`,
  deleteFuzz: (client_id: string | number) => `v2/student_fuzzes/${client_id}`,
  deleteMaze: (studentId: string | number, contentId: string | number) => `v2/content/${studentId}/${contentId}`,
  orders: `v2/sales/order`,
  studentsByClassCode: (klass_code: string, include_teacher: boolean = false, include_klass: boolean = false, include_parent = false) => (`v2/students?klass_code=${klass_code}` + (include_klass? "&include_klass=true":"") + (include_teacher? "&include_teacher=true":"") + (include_parent? "&include_parent=true":"")),
  register: `v1/register`,
  socialRegister: `v1/social_register`,
  klassTrackParentInvite: (klass_id: string | number) => `v2/klasses/${klass_id}/track-parent-invite`,
  klass_secret_code: (klass_id: string | number) => `v2/klasses/${klass_id}/secret-code`,
  klassBySecretCode: (secret_code: string) => `v2/klasses?secret_code=${secret_code}`,
  valentinesMazesByKlass: (klass_id: string | number, event?: string) => `v2/mazes?klass_id=${klass_id}${event !== undefined ? `&event=${event}` : ``}`,
  salesProfile: (teacherId?: number | string) => `v2/sales/profile${teacherId ? `?teacher_id=${teacherId}` : ``}`,
  klassCreativeLock: (classId: string | number) => `v2/klasses/${classId}/creative-lock`,
  queuedOrders: `v2/sales/order`,
  processOrder: (orderId: string | number) => `v2/sales/order/${orderId}/process`,
  bulkDeleteKlasses: `v2/klasses/bulk-delete`,
  bulkHideKlasses: `v2/klasses/bulk-hide`,
  bulkShowKlasses: `v2/klasses/bulk-show`,
  parentLeads: `v1/parent_leads`,
  parent: {
    onboarding: {
      finalize: (parentId: string | number) => `/v1/users/${parentId}/finalize_onboarding`
    }
  },
  godmode: {
    ambassadors: `v1/godmode/ambassadors`,
    addTeacher: `v1/godmode/teachers`,
    teachers: (query: string, searchColumn: string) => `v1/godmode/teachers?q=${encodeURIComponent(query)}&search_column=${searchColumn}`,
    teacher: (teacherId: string | number) => `v1/godmode/teachers/${teacherId}`,
    impersonate: (teacherId: string | number) => `v1/godmode/impersonate/${teacherId}`,
    createTrial: (teacherId: string | number) => `v1/godmode/teachers/${teacherId}/create-parent-trial`,
    cancelSubscription: (teacherId: string | number) => `v1/godmode/teachers/${teacherId}/cancel-subscription`,
    upgrade: (teacherId: string | number) => `v1/godmode/teachers/${teacherId}/upgrade`,
    downgrade: (teacherId: string | number) => `v1/godmode/teachers/${teacherId}/downgrade`,
    purchase: (purchaseProcessId: string | number) => `v1/godmode/purchases/${purchaseProcessId}`,
    transfer: (teacherId: string | number) => `v1/godmode/teachers/${teacherId}/transfer`,
    events: 'v2/event',
    schools: (page: number, search: string | undefined | null) => `v1/schools?${search ? `search=${search}` : `page=${page}`}`,
    schoolsPost: `v1/schools`,
    schoolDelete: (schoolId: string | number) => `v1/schools/${schoolId}`,
    schoolById: (schoolId: string | number) => `v1/schools/${schoolId}`,
    schoolsTeachers: (schoolId: string | number) => `v1/schools/${schoolId}/teachers`,
    schoolRequests: `v1/school_requests`,
    schoolRequestDelete: (schoolRequestId: string | number) => `v1/school_requests/${schoolRequestId}`,
    schoolRequestUpdate: (schoolRequestId: string | number) => `v1/school_requests/${schoolRequestId}`,
    schoolRequestLink: (schoolRequestId: string | number) => `v1/school_requests/${schoolRequestId}/link`,
    studentByStudentCode: (studentCode?: string) => `v1/godmode/students?student_code=${studentCode}`,
    studentByStudentId: (studentId?: string | number) => `v1/godmode/students/${studentId}`,
    subscriptions: {
      all: `v1/subscriptions`,
      details: (subscriptionId: string) => `v1/subscriptions/${subscriptionId}`,
      paginated: (page: number, pageSize: number, query: string) => `v1/subscriptions/all?page=${page}&page_size=${pageSize}&query=${query}`,
      schoolPricing: `v1/subscriptions/school-pricing`,
      upgradeToPremiumPlus: (subscriptionId?: number) => `v1/godmode/subscriptions/${subscriptionId}/upgrade`,
      edit: (subscription_id: string | number) => `v1/subscriptions/${subscription_id}`,
    },
    renewals: {
      purchaseProcessState: (purchaseProcessId: number) => `v1/godmode/renewals/${purchaseProcessId}/state`,
    },
    churnedSubDetails: `v1/godmode/revenue/churn_sub_details/`,
    timeOff: 'v1/godmode/time_off',
    revenueStats: `v1/godmode/revenue/revenue_stats`,
    salesCleverDistrictsRenewedNeedsDisconnect: `v2/sales/clever-districts/renewed-needs-disconnect`,
    salesCleverDistrictsExpirationOutdated: `v2/sales/clever-districts/clever-expiration-outdated`,
    recentQuotes: `v2/sales/quote/recent`,
    quotes: {
      all: (pageNumber: number, pageSize: number, query?: string) => {
        let allEndpointString = `v1/godmode/quotes?page_number=${pageNumber}&page_size=${pageSize}`;
        if (query) allEndpointString += `&query=${query}`;
        return allEndpointString
      }
    },
    orderRequest: {
      byId: (orderId: string) => `v2/godmode/orders/${orderId}`
    },
    giftCodes: `v1/godmode/gift_code`,
    assignLicense: (subscription_id: string | number) => `v1/subscriptions/${subscription_id}/assign-license`,
    futureSubscriptions: (organizationProfileId: string | number) => `v1/subscriptions/future_subscriptions/${organizationProfileId}`,
    deleteSubscription: (subscription_id: string | number) => `v1/subscriptions/${subscription_id}`,
    organizationProfile: {
      all: (
        pageNumber: number,
        pageSize: number,
        order_by?: string,
        order_direction?: 'ASC' | 'DESC',
        query?: string,
        include?: IOrganizationProfileValidInclude[] | null,
        paid_only?: boolean
        ) => {
          let allEndpointString = `v2/organization_profile/all?page_number=${pageNumber}&page_size=${pageSize}`;
          if (order_by) allEndpointString += `&order_by=${order_by}`;
          if (order_direction) allEndpointString += `&order_direction=${order_direction}`;
          if (query) allEndpointString += `&query=${query}`;
          if (include) allEndpointString += `&include=${include.join(',')}`;
          if (paid_only) allEndpointString += `&paid_only=true`;
          
          return allEndpointString
        },
      byId: (organizationProfileId: string | number, include: string[] | null) => `v2/organization_profile?organization_profile_id=${organizationProfileId}${include ? `&include=${include.join(',')}` : ''}`,
      byAdministratorId: (administratorId: string | number, include: string[] | null) => `v2/organization_profile?administrator_id=${administratorId}${include ? `&include=${include.join(',')}` : ''}`,
      bySubscriptionId: (subscriptionId: string | number, include: string[] | null) => `v2/organization_profile?subscription_id=${subscriptionId}${include ? `&include=${include.join(',')}` : ''}`,
      notes: {
        organizationNotes: (organiationProfileId: string | number) => `v1/godmode/organization_profiles/${organiationProfileId}/notes`,
        editNote: (noteId: string | number) => `v1/godmode/organization_profiles/note/${noteId}`
      },
      update: (organizationProfileId: string | number) => `v1/godmode/organization_profiles/${organizationProfileId}`,
    }
  },
  event: {
    allEvents: (activeOnly?: boolean) => `v2/event${activeOnly ? '?active_only=true' : ''}`,
  },
  users: {
    subscription: {
      billing: (userId: string | number) => `v1/users/${userId}/subscription-billing`,
      cancelReason: (userId: string | number) => `/v1/users/${userId}/cancel-reason`,
      cancel: (userId: string | number) => `/v1/users/${userId}/cancel-subscription`
    }
  },
  subscriptionById: (subscriptionId: number) => `v1/subscriptions/${subscriptionId}`,
  stripeLastCharge: (teacherId: number | string) => `v1/godmode/teachers/${teacherId}/stripe-last-charge`,
  refundLastStripeCharge: (teacherId: number | string) => `v1/godmode/teachers/${teacherId}/stripe-refund-charge`,
  segmentTrackEvent: `v2/segment/track`,
  stripeSetupIntent: `v1/stripe/setup-intent`,
  stripePaymentIntent: `v1/stripe/payment-intent`,
  stripeAttachPaymentMethod: `v1/stripe/attach-payment-method`,
  stripeUpcomingInvoice: (teacherId: string | number) => `v1/stripe/${teacherId}/upcoming_invoice`,
  video: {
    assignments: {
      byStudentCode: (studentCode: string) => `v1/video_assignments?student_code=${studentCode}`
    }
  }
};

export default endpoints;