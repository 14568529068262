import React, {Dispatch, SetStateAction, useLayoutEffect, useMemo} from 'react';
import { Box, Typography } from '@material-ui/core';
import { ITeacherData } from 'types/ITeacherData';
import useSWR from 'swr';
import useCurrentUser from 'loaders/useCurrentUser';
import endpoints from 'endpoints';
import { useAddClassFormContext } from 'context/AddClassFormProvider';
import { gradesArray } from "types/Grades";
import useSharedStyles from 'components/useSharedStyles';
import Button from 'components/ui/buttons/Button';
import { routes } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import TextField from 'components/ui/TextField';
import Select from 'components/ui/Select';
import MenuItem from 'components/ui/MenuItem';
import { AddNewClassManualStep } from './AddClassManualForm';

const exampleNames = [
  "Brave Bears",
  "Amazing Rainbows",
  "Special Stars",
  "Happy Kangaroos",
  "Terrific Tigers",
  "Magical Lions",
  "Fantastic Frogs",
  "Gritty Lizards",
  "Inspiring Inchworms"
]

export const AddClassManualFormNameGrade: React.VFC<{setStep: Dispatch<SetStateAction<AddNewClassManualStep>>}> = ({setStep}) => {
  const { data: teacherData } = useSWR<ITeacherData>(endpoints.teacherInit)
  const { currentUser } = useCurrentUser()
  const { addNewClassForm, canUseCleverImport }  = useAddClassFormContext()
  const sharedClasses = useSharedStyles()

  useLayoutEffect(() => {
    addNewClassForm.setFieldValue('grade', 'default')
  }, [])

  const exampleName = useMemo(() => {
    return `${currentUser.name ? `${currentUser.name}'s ` : ''} ${exampleNames[Math.floor((exampleNames.length - 1) * Math.random())]}`
  }, [currentUser.name])

  return (
    <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
      <Box>
        {teacherData?.klasses.length === 0 &&
          <Typography
            style={{ fontStyle: 'italic', marginBottom: 4 }}
          >
            Suggested: {exampleName}
          </Typography>
        }
        <TextField
          label={teacherData?.klasses.length !== 0 ? "Class Name" : undefined}
          placeholder="Type class name here"
          id="klass_name"
          value={addNewClassForm.values.klass_name}
          onChange={addNewClassForm.handleChange}
          helperText={addNewClassForm.errors.klass_name}
          error={!!addNewClassForm.errors.klass_name && addNewClassForm.submitCount > 0}
          onBlur={addNewClassForm.handleBlur}
          InputProps={{
            style: {
              background: 'white'
            }
          }}
        />
      </Box>
      <Select
        label="Class Grade"
        id="grade"
        onChange={e => addNewClassForm.setFieldValue('grade', e.target.value)}
        value={addNewClassForm.values.grade}
        helperText={addNewClassForm.errors.grade}
        error={!!addNewClassForm.errors.grade && addNewClassForm.submitCount > 0}
        onBlur={addNewClassForm.handleBlur}
        fullWidth
        style={{
          background: 'white'
        }}
      >
        <MenuItem disabled value="default">
          <em>Select Grade</em>
        </MenuItem>
        {gradesArray.map((grade) => {
          return (
            <MenuItem
              key={grade.key}
              value={grade.key}
            >
              {grade.name}
            </MenuItem>
          )
        })
        }
      </Select>
      {(teacherData?.klasses && teacherData?.klasses.length > 0) &&
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={sharedClasses.vspacing2}
            mt={2}
          >
            <Button
              color="purple"
              variant="outlined"
              {...{
                component: RouterLink,
                to: routes.classes.bulkAdd
              }}
            >
              Add Multiple Classes
            </Button>
            <Button
              startIcon={<FontAwesomeIcon icon={faGoogle} />}
              color="red"
              variant="outlined"
              {...{
                component: RouterLink,
                to: routes.classes.import.google
              }}
            >
              Add From Google Classroom
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="blue"
              size="large"
              disableElevation={false}
              onClick={() => setStep('add-students')}
              disabled={!addNewClassForm.isValid}
            >
              Continue
            </Button>
          </Box>
        </Box>
      }
    </Box>
  )
}