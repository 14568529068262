import { Box, CircularProgress, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useMemo, useState } from "react";
import { ELeadState } from "types/IStudentSchoolProfile";
import { ResourceCard, ResourceCardProps } from "./ViewClassDashboard";
import {useRecentActivity} from "../../../hooks/useRecentActivity";

export const RecommendedForYou: React.VFC<{ klass_id?: number | string }> = ({ klass_id }) => {
  const sharedClasses = useSharedStyles();
  const [showGettingStartedDialog, setShowGettingStartedDialog] = useState(false);
  
  return <>
    <Typography variant="h2" style={{ opacity: 0.75 }} paragraph>Recommended For You</Typography>
    {klass_id && <RecForYouClass klass_id={klass_id} />}
    {!klass_id && <Box display="flex" className={sharedClasses.hspacing2} style={{ overflowX: 'scroll' }}>
      <ResourceCard {...resources['field_guide'] } />
      <ResourceCard {...resources['scope'] } />
      <ResourceCard {...resources['code_card'] } dialogState={{open: showGettingStartedDialog, setOpen: setShowGettingStartedDialog}} />
    </Box>}
  </>;
}

const RecForYouClass: React.VFC<{ klass_id: number | string }> = ({ klass_id }) => {
  const { teacherData } = useTeacherInit();
  const { recentActivityCount, loading } = useRecentActivity(klass_id);
  const currentClass = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id.toString() === klass_id.toString())!;
  }, [teacherData, klass_id]);
  const students = useMemo(() => {
    return teacherData!.students.filter((student) => student.klasses.includes(currentClass.id));
  }, [teacherData, currentClass]);

  const hasLinkedStudents = useMemo(() => {
    return students.some(({ lead }) => lead?.state === ELeadState.linked);
  }, [students]);
  const hasThreeRecentActivities = recentActivityCount >= 3;

  if (loading) {
    return <Box display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  
  if (!hasThreeRecentActivities && !hasLinkedStudents) {
    return <ResourceCard {...resources['get_started'] } />
  }

  if (hasThreeRecentActivities && !hasLinkedStudents) {
    return <ResourceCard {...resources['settings'] } />
  }

  return <ResourceCard {...resources['subjects'] } />
}

const resources: { [key: string]: ResourceCardProps } = {
  get_started: {
    thumbnail: "/images/resources/get_started_thumbnail.png",
    href: "https://docs.google.com/presentation/d/1-wq195_F5YOwEw_xVlRYn-S4vgJvjX3UY4UkhLherbE/edit?usp=sharing",
    type: "Presentation",
    title: "Getting Started With Kodable"
  },
  settings: {
    thumbnail: "/images/resources/settings_thumbnail.png",
    title: "How to Include Coding in Your Schedule",
    type: "Video",
    ytVideoId: "cWCG7OyAC1w"
  },
  subjects: {
    thumbnail: "/images/resources/subjects_thumbnail.png",
    title: "How To: Coding With Other Subjects",
    type: "Video",
    ytVideoId: "YMpvpbsKyCQ"
  },
  field_guide: {
    thumbnail: "/images/resources/field_guide.png",
    href: "https://s3.us-west-2.amazonaws.com/resources.kodable.com/KodableFieldGuide.pdf",
    type: "Teacher Guide",
    title: "Kodable Field Guide",
  },
  scope: {
    thumbnail: "/images/resources/getting-started-creator.png",
    href: "https://s3.us-west-2.amazonaws.com/resources.kodable.com/planning/GettingStartedwithKodableCreator.pdf",
    title: "Getting started with Kodable Creator",
    type: "Teacher Guide",
  },
  code_card: {
    thumbnail: "/images/resources/code_card.png",
    title: "Student Login Instructions",
    type: "Printable & Digital"
  }
}
