export default function Error() {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <img
        style={{marginBottom: 50}}
        src="/images/logos/kodable-edu-color-vertical.svg"
        alt="Kodable"
        className="md:max-w-sm max-w-xs"></img>
      <div className="font-semibold text-2xl text-gray-500">
        Something went wrong...
      </div>
      <div className="flex">
        <button
          onClick={() => window.location.reload()}
          className="bg-kodable-green px-4 py-2 rounded text-white mt-8">
          Reload Page
        </button>
        <a
          href="http://support.kodable.com/en/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-kodable-orange px-4 py-2 rounded text-white mt-8 ml-4">
          Contact Support
        </a>
      </div>
    </div>
  );
}
