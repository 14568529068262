import {Alert, Color} from "@material-ui/lab";

const InfoBox: React.FC<{style?: any, severity?: Color | undefined}> = ({children, style, severity='info'}) => {
  const alertStyle = {
    ...style,
    fontWeight: '600',
  }

  return (
    <Alert severity={severity} style={alertStyle}>
      {children}
    </Alert>
  )
}

export default InfoBox