import React, { FormEvent, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory, match } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import { useAlertDispatch } from 'context/AlertProvider';
import usePurchaseParentSubscription from 'mutations/usePurchaseParentSubscription';
import Amplitude from 'utils/Amplitude';
import stripePromise from 'utils/Stripe';
import queryString from 'query-string';
import useSubscription from 'loaders/useSubscription';
import useCurrentUser, { mutate } from 'loaders/useCurrentUser';
import { IUserProfile } from "types/IUserProfile";
import { Box, Typography, makeStyles } from '@material-ui/core';
import { justFetch } from 'mutations/mutate';
import endpoints from 'endpoints';
import useSharedStyles from 'components/useSharedStyles';
import { IParentSubscriptionPricing } from 'types/ISubscription';
import useSWR from 'swr';
import { routes } from 'routes';


interface IPaymentWrapperPaymentIntent {
  client_secret: string;
}

const PaymentWrapper: React.FC<{price: number, children: ReactNode}> = ({ price, children }) => {
  const options = price > 100 ? {
    mode: 'payment' as const,
    amount: Math.round((price) * 100),
    currency: 'usd'
  } : {
    mode: 'setup' as const,
    currency: 'usd'
  }
  return <Elements
      stripe={stripePromise}
      options={options}
    >
      {children}
    </Elements>
}

const usePaymentStyles = makeStyles(theme => ({
  paymentPageWrapper: {
    flexDirection: 'column',
    '@media (min-width:960px)': {
      flexDirection: 'row'
    },
  },
  paymentElementWrapper: {
    flexBasis: '100%',
    '@media (min-width:960px)': {
      flexBasis: '40%'
    },
  },
}));

function Payment({ pricingPlan, goToCart, currentUser }: { pricingPlan: any, goToCart: any, currentUser: IUserProfile }) {
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()
  const [disabled, setDisabled] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [agree, setAgree] = useState(false)
  const [displayPriceProrated, setDisplayPriceProrated] = useState<number>(pricingPlan?.price)
  const dispatch = useAlertDispatch()
  const purchaseParentSubscription = usePurchaseParentSubscription(
    currentUser.id
  );
  const { mutate: mutateSubscription, subscription } = useSubscription()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [recaptchaValue, setRecaptchaValue] = useState<null|string>(null)
  const sharedClasses = useSharedStyles()
  const classes = usePaymentStyles()

  type LocationState = {
    showUI: string;
  }

  const location = useLocation<LocationState>()


  useEffect(() => {
    if (pricingPlan?.type !== 'lifetime') {
      setDisplayPriceProrated(pricingPlan?.price)
      return
    }
    if (subscription?.prorated_discounted_price > 0) {
      setDisplayPriceProrated(subscription?.prorated_discounted_price)
      return
    }
  }, [subscription, pricingPlan])

  useEffect(() => {
    mutate();
  }, [])

  const handleSubmitFailure = (message: string) => {
    dispatch({
      id: 'subscription-not-purchased',
      type: 'error',
      title: 'Subscription not Purchased!',
      message,
    });
    setRecaptchaValue(null)
    setSubmitting(false);
  }

  async function handleStartLifetimeSubscription(clientSecret: string) {
    if (!stripe || !elements) {
      handleSubmitFailure('Could not submit. Please try again later.')
      return
    }

    const paymentElement = elements.getElement(PaymentElement);

    if (paymentElement) {
      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.REACT_APP_KODABLE_WEBSITE}${routes.parent.upgrade.success}`
        },
        redirect: 'if_required'
      })

      if (result.error) {
        throw new Error(result.error.message)
      }

      if (!result.paymentIntent || result.paymentIntent.status !== 'succeeded') {
        throw new Error('Payment failed. Please try again later.')
      }

      const paymentIntentId = result.paymentIntent.id

      if (!paymentIntentId) {
        throw new Error('Payment failed. Please try again later.')
      }

      // Handle the recaptcha async, so we can maintain the chain of user actions and not use side effects
      // to handle the actual submission of the PaymentIntent or SetupIntent
      const captchaResponse = await recaptchaRef.current?.executeAsync()
      
      if (!captchaResponse) {
        throw new Error('Could not submit. Please try again later.')
      }

      purchaseParentSubscription({
        payment_intent_id: paymentIntentId,
        captcha_token: captchaResponse,
        user: currentUser,
        amount: displayPriceProrated,
        plan: pricingPlan.type,
        coupon: pricingPlan.coupon,
        platform: 'Web'
      })
      .then((result) => {
        if (result.status >= 400) {
          handleSubmitFailure(result.message)
        } else {
          const parsedSearchParams = queryString.parse(location.search);
          Amplitude.track('Subscription Purchase Completed');
          Amplitude.track('Purchased Kodable', {
            source: 'STRIPE',
            type: 'PARENT_SUBSCRIPTION'
          });

          mutateSubscription();

          if (parsedSearchParams.event === 'valentines2023') {
            window.location.href = `${process.env.REACT_APP_KODABLE_WEBSITE}/valentines-thank-you`
          } else {
            history.push({
              pathname: routes.parent.upgrade.success,
              search: location.search,
              state: {
                showUI: location.state?.showUI,
                purchasedPlan: pricingPlan
              }
            })
          }
        }
      })
      .catch(() => {
        handleSubmitFailure('An unknown error has occurred, please try again and contact support@kodable.com if this continues.')
      })
    }
  }

  async function handleStartSubscription(clientSecret: string) {
    if (!stripe || !elements) {
      handleSubmitFailure('Could not submit. Please try again later.')
      return
    }

    const paymentElement = elements.getElement(PaymentElement);

    if (paymentElement) {
      const result = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.REACT_APP_KODABLE_WEBSITE}/parent/upgrade/success`
        },
        redirect: 'if_required'
      })
      
      if (result.error) {
        throw new Error(result.error.message)
      }

      if (!result.setupIntent || result.setupIntent.status !== 'succeeded') {
        throw new Error('Payment failed. Please try again later.')
      }

      const paymentMethodId = result.setupIntent.payment_method

      if (!paymentMethodId) {
        throw new Error('Payment failed. Please try again later.')
      }

      // Handle the recaptcha async, so we can maintain the chain of user actions and not use side effects
      // to handle the actual submission of the PaymentIntent or SetupIntent
      const captchaResponse = await recaptchaRef.current?.executeAsync()
      
      if (!captchaResponse) {
        throw new Error('Could not submit. Please try again later.')
      }

      purchaseParentSubscription({
        payment_method_id: paymentMethodId,
        captcha_token: captchaResponse,
        user: currentUser,
        amount: displayPriceProrated,
        plan: pricingPlan.type,
        coupon: pricingPlan.coupon,
        platform: 'Web'
      })
      .then((result) => {
        if (result.status >= 400) {
          handleSubmitFailure(result.message)
        } else {
          const parsedSearchParams = queryString.parse(location.search);
          Amplitude.track('Subscription Purchase Completed');
          Amplitude.track('Purchased Kodable', {
            source: 'STRIPE',
            type: 'PARENT_SUBSCRIPTION'
          });

          mutateSubscription();

          if (parsedSearchParams.event === 'valentines2023') {
            window.location.href = `${process.env.REACT_APP_KODABLE_WEBSITE}/valentines-thank-you`
          } else {
            history.push({
              pathname: '/parent/upgrade/success',
              search: location.search,
              state: {
                showUI: location.state?.showUI,
                purchasedPlan: pricingPlan
              }
            })
          }
        }
      })
      .catch(() => {
        handleSubmitFailure('An unknown error has occurred, please try again and contact support@kodable.com if this continues.')
      })
    }
  }

  async function getPaymentIntentSecret() : Promise<string|null> {
    const response = await justFetch(endpoints.stripePaymentIntent, 'POST', {
      amount: displayPriceProrated,
    })

    if (response.ok) {
      const clientSecretResponse: IPaymentWrapperPaymentIntent = await response.json()
      if (clientSecretResponse?.client_secret) {
        return clientSecretResponse?.client_secret
      }
    }

    return null
  }

  async function getSetupIntentSecret() : Promise<string|null> {
    const response = await justFetch(endpoints.stripeSetupIntent, 'POST', {
      amount: 0.00
    })

    if (response.ok) {
      const clientSecretResponse: IPaymentWrapperPaymentIntent = await response.json()
        if (clientSecretResponse?.client_secret) {
          return clientSecretResponse.client_secret
        }
    }

    return null
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (submitting) {
      return
    }

    setSubmitting(true)

    if (!elements || !stripe) {
      handleSubmitFailure('Could not submit. Please try again later.')
      return
    }
      
    // Use a PaymentIntent if this is a lifetime purchase
    const clientSecret = pricingPlan?.type === 'lifetime' ? await getPaymentIntentSecret() : await getSetupIntentSecret()

    if (!clientSecret) {
      handleSubmitFailure('Could not verify payment intent details')
      return
    }

    const {error: submitError} = await elements.submit()

    if (submitError) {
      handleSubmitFailure('Could not submit. Please try again later.')
      return
    }

    if (pricingPlan?.type === 'lifetime') {
      handleStartLifetimeSubscription(clientSecret)
      .catch((e) => {
        handleSubmitFailure(e.message)
      })
      return
    }

    handleStartSubscription(clientSecret)
    .catch((e) => {
      handleSubmitFailure(e.message)
    })
  }

  return (
    <Fragment>
      <Box p={4} className={classes?.paymentPageWrapper} display="flex" justifyContent="space-between">
        <Box className={sharedClasses?.vspacing1}>
          <Typography variant="body1">Kodable Pro Membership</Typography>
          <Typography variant="h1" style={{display: 'flex', alignItems: 'center'}}>
            <strong style={pricingPlan?.price !== displayPriceProrated ? {color: '#757575', textDecoration: 'line-through', fontSize: '1.25rem', marginRight: '.75rem'} : {}}>
              ${pricingPlan.price}
            </strong>
            {pricingPlan.price !== displayPriceProrated &&
              <strong>
                ${displayPriceProrated}
              </strong>
            }
          </Typography>
          <Typography variant="body1">{pricingPlan?.type === 'lifetime' ? 'One purchase for a lifetime of learning. All prices in USD.' : 'Billed automatically after 7-day free trial. All prices in USD.'}</Typography>
          <img className="hidden md:block" width={450} style={{marginTop: 20}} src="/images/onboarding/intro.png" alt="Kodable Pro Membership" />
        </Box>
        <Box className={classes?.paymentElementWrapper}>
          <form onSubmit={handleSubmit}>
            <Box className={sharedClasses?.vspacing2} display="flex" flexDirection="column">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                size="invisible"
              />
              <PaymentElement
                options={{
                  wallets: {
                    applePay: 'auto',
                    googlePay: 'auto'
                  }
                }}
                onChange={(value) => {
                  if (value.complete) {
                    setDisabled(false)
                  } else {
                    setDisabled(true)
                  }
                }}
              />
              <label className="font-bold flex justify-end items-center mb-8">
                <input
                  onChange={(e) => {
                    setAgree(e.target.checked);
                  }}
                  className="mr-2"
                  type="checkbox"
                />
                <span className="text-gray-800 text-sm">
                  I agree to the{' '}
                  <a
                    className="text-kodable-blue underline"
                    href="https://www.kodable.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer">
                    Terms of Service
                  </a>
                </span>
              </label>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <button
                  onClick={goToCart}
                  className="flex items-center justify-center text-gray-600 border border-gray-200 bg-white hover:bg-gray-100 active:bg-gray-200 px-4 py-2 rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 mr-2"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={4}
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                  Back
                </button>
                <button
                  disabled={!stripe || disabled || submitting || !agree}
                  type="submit"
                  className="flex items-center justify-center bg-kodable-orange border border-kodable-orange hover:border-kodable-orange  hover:bg-kodable-orange-dark active:bg-kodable-orange-darker active:border-kodable-orange px-8 py-2 rounded text-white ml-6 disabled:opacity-50 disabled:cursor-not-allowed">
                  {submitting ? 'Processing...' : 'Subscribe'}
                </button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Fragment>
  );
}

export type ParentSubscriptionPricingPlan = {
  type: 'monthly' | 'yearly' | 'lifetime';
  price: number;
  subtext?: string;
  name: string;
  subtextTrialing?: string;
  nameTrialing: string;
}

export const getPricingFromPlan = (plan: 'monthly' | 'yearly' | 'lifetime', pricing: IParentSubscriptionPricing): ParentSubscriptionPricingPlan => {
  if (plan === 'lifetime') {
    return {
      type: 'lifetime',
      price: pricing?.lifetime,
      name: '+ tax lifetime subscription',
      nameTrialing: '+ tax lifetime subscription',
    }
  } else if (plan === 'yearly') {
    return {
      type: 'yearly',
      subtext: 'Billed automatically',
      name: '+ tax yearly subscription',
      subtextTrialing: 'Billed automatically after 7-day free trial',
      nameTrialing: '+ tax yearly subscription with 7-day trial',
      price: pricing?.yearly,
    }
  }

  return {
    type: 'monthly',
    subtext: 'Billed automatically',
    name: '+ tax monthly subscription',
    subtextTrialing: 'Billed automatically after 7-day free trial',
    nameTrialing: '+ tax monthly subscription with 7-day trial',
    price: pricing?.monthly,
  }
}


const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  }
}))

export default function UpgradePlanPage({
  computedMatch: {
    params: { plan = 'monthly' },
  },
}: {
  computedMatch: match<{ plan: 'monthly' | 'yearly' | 'lifetime' }>,
}) {
  const { currentUser } = useCurrentUser();
  const [pricingPlan, setPricingPlan] = useState<ParentSubscriptionPricingPlan>()
  const { data: pricing } = useSWR<IParentSubscriptionPricing>(endpoints?.parentSubscriptionPricing)
  const history = useHistory();
  const classes = useStyles()

  useEffect(() => {
    if (!pricing) {
      return
    }
    setPricingPlan(getPricingFromPlan(plan, pricing))
  }, [plan, pricing])

  if (!currentUser || !pricingPlan) {
    return null
  }

  return (
    <div className="max-w-screen-lg my-8 px-2 mx-auto" style={{ width: '100%' }}>
      <Box className={classes.logo}>
        <img
          src="/images/logos/kodable-edu-color.svg"
          width={350}
          alt="Kodable"/>
      </Box>
      <div className="flex flex-col">
        <PaymentWrapper price={pricingPlan?.price}>
          <Payment
            pricingPlan={pricingPlan}
            currentUser={currentUser}
            goToCart={() => history.push(routes.parentOnboarding.plans)}
          />{' '}
        </PaymentWrapper>
      </div>
    </div>
  );
}
