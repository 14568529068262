import { Box} from "@material-ui/core";
import React from "react";
import { ICourseData } from "../ICourseData";
import CourseCardAssignment from "./CourseCardAssignment";
import {IKlass} from "../../../types/IKlass";

export const AssignmentsCourseList: React.VFC<{courses: ICourseData[], klassData: IKlass}> = ({courses, klassData}) => {

    return (
        <Box
            display="flex"
            flexDirection="column"
            style={{gap: "50px"}}
            height={"calc(100vh - 325px)"}
            overflow={"auto"}
        >
            {courses!.map(course => {
                return <CourseCardAssignment
                    key={course.id}
                    course={course}
                    klassData={klassData}
                />
            })}
        </Box>
    )
}

export default AssignmentsCourseList;