import { Box, Typography } from "@material-ui/core";
import { AppBarContent } from "components/nav/AppBarContent";
import useSharedStyles from "components/useSharedStyles";
import React, { ReactNode } from "react";

interface PageHeaderProps {
  title: string;
  actions?: ReactNode;
  inAppBar?: boolean;
  showCleverIcon?: boolean;
  startContent?: React.ReactNode;
}

const PageHeader: React.VFC<PageHeaderProps> = ({ title, actions, inAppBar = false, showCleverIcon, startContent }) => {
  const sharedClasses = useSharedStyles();

  const content = <Box display="flex" alignItems="center" justifyContent={!inAppBar ? 'center' : ''}>
    {showCleverIcon &&
      <img src={'/images/CleverLogoInv.png'} alt={'Clever'} style={{width:25, marginRight: 10}}/>
    }
    {startContent}
    <Typography align={!inAppBar ? 'center' : undefined} variant={inAppBar ? 'h2' : 'h1'}>{title}</Typography>
    <Box display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
      {actions}
    </Box>
  </Box>

  return inAppBar ? <AppBarContent>{content}</AppBarContent> : content;
}

export default PageHeader;