import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useTracking } from "context/TrackingProvider";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { mutate } from "swr";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const TryItDialog: React.VFC<{ klass_id?: number }> = ({ klass_id: _klass_id }) => {
  const sharedClasses = useSharedStyles();
  const textRef = useRef<HTMLInputElement>(null);
  const [didCopyToClipboard, setDidCopyToClipboard] = useState(false);
  const { track } = useTracking();
  const { currentUser } = useCurrentUser();
  const { teacherData } = useTeacherInit();

  const klass_id = useMemo(() => {
    if (_klass_id) {
      return _klass_id;
    }

    if (teacherData?.has_clever_account) {
      return teacherData?.klasses.sort(({ id: A }, { id: B }) => A - B)[0]?.id;
    }
  }, [_klass_id, teacherData]);

  const klass_code = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id === klass_id)?.klass_code.toLocaleUpperCase();
  }, [klass_id, teacherData]);

  const [didCloseTryItDialog, setDidCloseTryItDialog] = useState(!!cookies.get('showed_try_it_dialog'));
  useEffect(() => {
    if (!currentUser.used_klass_code && !didCloseTryItDialog) {
      cookies.set('showed_try_it_dialog', 'true', {
        domain: process.env.REACT_APP_LOCAL
          ? 'localhost'
          : '.kodable.com',
        path: '/'
      });

      cookies.set('class_roster_highlight_teacher_profile', klass_code, {
        domain: process.env.REACT_APP_LOCAL
          ? 'localhost'
          : '.kodable.com',
        path: '/'
      });
    }
  }, [currentUser, didCloseTryItDialog, klass_code]);
  const needsTeacherProfile = useMemo(() => {
    return !!teacherData && !teacherData?.students.some(({ klasses, name }) => klasses.includes(klass_id as number) && name === 'Teacher Profile')
  }, [klass_id, teacherData])

  const copyToClipboard = () => {
    track('Used Class Code Reveal Modal', {
      'Action': 'Copy to Clipboard'
    });
    setDidCopyToClipboard(true);
    textRef?.current?.select();
    document.execCommand('copy');
  }

  useEffect(() => {
    if (didCopyToClipboard) {
      setTimeout(() => {
        setDidCopyToClipboard(false);
      }, 2500);
    }

  }, [didCopyToClipboard]);

  useEffect(() => {
    if (!currentUser.used_klass_code && !didCloseTryItDialog) {
      const interval = setInterval(() => {
        mutate(endpoints.session);
      }, 5000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [currentUser.used_klass_code, didCloseTryItDialog])


  const handleClickTryIt = () => {
    track('Used Class Code Reveal Modal', {
      'Action': 'Try It'
    });

    if (!needsTeacherProfile) {
      return;
    }

    return justFetch(endpoints.addStudent, 'POST', {
      klass_id: klass_id,
      teacher_id: currentUser?.id,
      name: 'Demo Account',
      is_teacher: true
    })
      .then(res => {
        mutate(endpoints.teacherInit);
      })
  }

  if (!klass_code) {
    return null;
  }

  return <Dialog open={!currentUser.used_klass_code && !didCloseTryItDialog}>
    <DialogTitle>Woo-hoo! Your {!!_klass_id ? 'class is' : 'classes are'} ready to code!</DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box my={2} display="flex" className={sharedClasses.hspacing2}>
          <TextField
            value={`${klass_code.toLocaleUpperCase()}`}
            onClick={e => { (e.target as any).select(); copyToClipboard(); }}
            inputProps={{
              ref: textRef,
              style: {
                fontSize: '3rem',
                color: 'rgb(39, 169, 225)',
                textAlign: 'center'
              },
            }}
            style={{
              width: '320px'
            }}
          />
          <Button variant="outlined" startIcon={<FontAwesomeIcon icon={faCopy} />} onClick={copyToClipboard}>{didCopyToClipboard ? 'Copied!' : 'Copy'}</Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <img src="/images/fuzzbuzz/fuzzbuzz_2.jpg" style={{ width: 275, marginBottom: 16 }} />
        <Typography style={{ fontWeight: 'bold' }}>Your students will use this code to log in. Let's try it now</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb={2}>
        <Button
          color="primary"
          style={{ visibility: 'hidden' }}
          onClick={() => {
            track('Used Class Code Reveal Modal', {
              'Action': 'See My Class'
            });
            setDidCloseTryItDialog(true);
          }}
        >See My {!!_klass_id ? 'Class' : 'Classes'}</Button>
        <Button
          startIcon={<FontAwesomeIcon icon={faGamepad} />}
          color="green"
          variant="contained"
          href={`${process.env.REACT_APP_KODABLE_WEBSITE}/login?tryClassCode=${klass_code.toLocaleUpperCase()}`}
          {...{ target: '_blank' }}
          onClick={handleClickTryIt}
          style={{ fontSize: '1.5rem' }}
        >Try It!</Button>
        <Button
          color="primary"
          onClick={() => {
            track('Used Class Code Reveal Modal', {
              'Action': 'See My Class'
            });
            setDidCloseTryItDialog(true);
          }}
        >Skip For Now</Button>
      </Box>
    </DialogContent>
  </Dialog>;
}