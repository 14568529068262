import React, {useEffect, useMemo, useState} from "react";
import {ICourseData, IGalaxyCourse} from "../ICourseData";
import {Box, Button, Checkbox, CircularProgress, Paper, Typography} from "@material-ui/core";
import {useCourseCardStyles} from "./CourseCard";
import useSWR, {mutate} from "swr";
import endpoints from "../../../endpoints";
import {GameTitleChip} from "./GameTitleChip";
import {GameTitle} from "../../../enums/GameTitle";
import {justFetch} from "../../../mutations/mutate";
import Amplitude from "../../../utils/Amplitude";

import {IKlass} from "../../../types/IKlass";
import {useAlert} from "../../../context/AlertProvider";
import useCurrentUser from "../../../loaders/useCurrentUser";
import {missionColorMap, missionNameMap} from "./defaultMissionConfigurations";

const buttonStyle = {
    backgroundColor: '#01AA3B',
    color: 'white',
    padding: '8px 16px',
    borderRadius: 10,
    marginRight: '12px',
    minWidth: '150px'
};

export const CourseCardAssignment: React.VFC<{ course: ICourseData, klassData: IKlass}> = ({ course, klassData }) => {
    const { data: courseData } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const courseCardClasses = useCourseCardStyles();
    const [assigned, setAssigned] = useState(true);
    const { currentUser } = useCurrentUser();
    
    const [isAssigning, setIsAssigning] = useState(false);
    const [isAssigningMission, setIsAssigningMission] = useState<{ [key: number]: boolean }> ({});
    const alert = useAlert();
    
    const [creativeLockData, setCreativeLockData] = useState(klassData?.assignments?.creative_locks!);

    function convertMissionOmissions(mission_omissions: string[] | undefined): { [key: string]: string[] } {
        const missionOmission: { [key: string]: string[] } = {};
        if (!mission_omissions)
            return missionOmission;
        mission_omissions.forEach(mission => {
            const [courseId, missionId] = mission.split('-');
            if (!missionOmission[courseId]) {
                missionOmission[courseId] = [];
            }
            missionOmission[courseId].push(missionId);
        });
        return missionOmission;
    }

    const missionOmissions = useMemo(() => {
        return convertMissionOmissions(klassData?.assignments?.mission_omissions);
    }, [klassData?.assignments?.mission_omissions]);

    const isMissionOmitted = (courseID: number, missionID: number) =>
    {
        return missionOmissions[courseID]?.includes(missionID.toString());
    }

    useEffect(() => {
        setCreativeLockData(klassData?.assignments?.creative_locks!);
    }, [klassData?.assignments?.creative_locks])
    
    useEffect(() => {

        if (course.meta.isCreativeArea){
            setAssigned(!creativeLockData[course.meta.dashboardTitle!.replace(/\s/g,"")]);
            return;
        }
        switch (course.meta.gameTitle){
            case GameTitle.BASICS:
                setAssigned(klassData?.assignments?.courses.includes(course.id) ?? true);
                break;
            case GameTitle.CREATOR:
                setAssigned(klassData?.assignments?.creator_courses.includes(course.id) ?? true);
        }
    }, [klassData, course]);
    
    const handleChangeAssignment = async (e: React.ChangeEvent, checked: boolean) => {
        setIsAssigning(true);
        let res: Response | undefined;
        
        if (course.meta.isCreativeArea) {
            await handleCreativeChangeAssignment(e, checked);
            return;
        }
        switch (course.meta.gameTitle){
            case GameTitle.BASICS:
                res = await justFetch(endpoints.coursesForClass(klassData?.id!), checked ? 'POST' : 'DELETE', { course_id: [course.id] })
                break;
            case GameTitle.CREATOR:
                res = await justFetch(endpoints.creatorCoursesForClass(klassData?.id!), checked ? 'POST' : 'DELETE', { course_id: [course.id - 1000] })
        }
        
        if (!res || !res.ok) {
            setIsAssigning(false);
            alert.error(`Unable to  ${checked ? 'Assign' : 'Unassign'}`);
        } else {
            // only track on unassignment
            if (!checked) {
                Amplitude.track('Course Unassigned', {
                    klassId: klassData?.id,
                    courseId: course?.id,
                    gameTitle: course.meta.gameTitle,
                });
            }

            const assignmentsKey = course.meta.gameTitle == GameTitle.BASICS? "courses" : "creator_courses";
            const oldAssignments = klassData!.assignments![assignmentsKey];
            let newAssignments = {...klassData!.assignments}
            newAssignments[assignmentsKey] = checked ? [...oldAssignments, course.id] : oldAssignments.filter(id => id !== course.id)

            await mutate(endpoints.classById(klassData?.id!, ['assignments']), {
                ...klassData,
                assignments: newAssignments
            });
            alert.success(`Course ${checked ? 'Assigned' : 'Unassigned'}`);
            setAssigned(checked);
            setIsAssigning(false);
        }
    }
    
    const handleChangeMissionAssignment = async (missionID: number, checked: boolean) => {

        setIsAssigningMission(prev => ({ ...prev, [missionID]: true}));

        let res: Response | undefined;
        
        switch (course.meta.gameTitle){
            case GameTitle.BASICS:
                res = await justFetch(endpoints.missionsForClass(klassData?.id!), 'POST', checked? { course_id: course.id, assign_missions: [missionID] } : { course_id: course.id, unassign_missions: [missionID] })
                break;
        }
        
        if (!res || !res.ok) {
            setIsAssigningMission(prev => ({ ...prev, [missionID]: false}));
            alert.error(`Unable to  ${checked ? 'Assign' : 'Unassign'}`);
        } else {
            // only track on unassignment
            if (!checked) {
                Amplitude.track('Mission Unassigned', {
                    klassId: klassData?.id,
                    courseId: course?.id,
                    gameTitle: course.meta.gameTitle,
                    missionID: missionID
                });
            }

            const changedMission = `${course.id}-${missionID}`;
            const newMissionOmissions = checked ? [...klassData!.assignments!.mission_omissions, changedMission] : klassData!.assignments!.mission_omissions.filter(omission => omission !== changedMission);

            await mutate(endpoints.classById(klassData?.id!, ['assignments']), {
                ...klassData,
                assignments: {
                    ...klassData!.assignments,
                    mission_omissions: newMissionOmissions
                }
            });
            alert.success(`Mission ${checked ? 'Assigned' : 'Unassigned'}`);
            setIsAssigningMission(prev => ({ ...prev, [missionID]: false}));
        }
    }

    const handleCreativeChangeAssignment= async (e: React.ChangeEvent, checked: boolean) => {
        const body = creativeLockData;
        body[course.meta.dashboardTitle!.replace(/\s/g,"")] = !checked;
        const res = await justFetch(endpoints.klassCreativeLock(klassData?.id!), 'PUT', body)

        if (!res.ok) {
            setIsAssigning(false);
            alert.error(`Unable to  ${checked ? 'Assign' : 'Unassign'}`);
        } else {
            await mutate(endpoints.classById(klassData?.id!, ['assignments']), undefined, true);
            alert.success(`Course ${checked ? 'Assigned' : 'Unassigned'}`);
            setAssigned(checked);
            setIsAssigning(false);
        }
    }
    
    const icon = useMemo(() => {
        if ( course.meta.gameTitle == GameTitle.BASICS)
        {
            const galaxyImage = (courseData?.find(({ id }) => id === 1) as IGalaxyCourse)?.configuration.planetNodes.find(planetNode => planetNode.courseID === course.id)?.image;
            if (galaxyImage) return `images/courses/planets/${galaxyImage}.png`;
        }

        return course.meta.iconUrl;
    }, [course]);


    return <>

        <Box
            display="flex" 
            flexDirection="row" 
            alignItems="center"
            width="900px"
            height="250px"
           
        >
            <Box
                width="50px"
                height="50px"
            >
            {!isAssigning && <Checkbox
                checked={assigned}
                onChange={handleChangeAssignment}
                style={{ backgroundColor: 'white', transform: 'scale(1.2)' }}
                className={assigned ? courseCardClasses.checkbox : ''}
            />}
            {isAssigning && <CircularProgress size="2rem" style={{ color: '#91BC55' }} />}
            </Box>
            <Box
                component={Paper}
                {...{
                    variant: 'outlined',
                }}
                style={{
                    borderWidth:  2,
                    transition: 'transform 0.5s, border-color 0.5s',
                    borderRadius: '25px',
                    borderColor: assigned? '#00cc00ff' : '#0000001e'
                }}
                p={2}
                display="flex" 
                flexDirection="column" 
                alignItems="flex-start"
                height="100%"
                width="900px"
                position="relative"
            >

                <Box alignSelf="flex-end">
                    <GameTitleChip  game={course.meta.gameTitle!} />
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} style={{ transition: 'opacity 0.5s', opacity: 1 }}>
                    {icon && <Box id="floaty-planet" mr={2} position="relative">
                        <img
                            alt={course.meta.dashboardTitle}
                            src={icon}
                            style={{
                                minWidth: 150, 
                                maxWidth: 150, 
                                maxHeight: 200,
                                height: 'auto',
                            }}
                        />
                    </Box>}

                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h1" style={{ fontWeight: 600 }}>{course.meta.dashboardTitle}</Typography>
                        <Typography style={{ opacity: 0.65 }} paragraph>{course.meta.dashboardSubtitle}</Typography>
                        <Typography>{course.meta.dashboardDescription ? (course.meta.dashboardDescription) : (<>
                            [Missing description]
                        </>)}</Typography>

                        {!course.meta.isCreativeArea && course.meta.gameTitle == GameTitle.BASICS && course.configuration.missionNodes?.length && <>
                            <Typography style={{ marginTop: '20px', marginBottom: '6px', opacity: 0.65 }}>
                                Assigned Lessons
                            </Typography>
                            <Box >
                                {course.configuration.missionNodes && course.configuration.missionNodes.map((mission, missionIndex)=> {
                                    return (
                                        <Button
                                            startIcon={mission.isPaywalled && currentUser.plan != "School"  ? <img height={"25px"} width={"25px"} src={'/images/badges/premium-badge-100.png'}/> : null}
                                            variant="contained"
                                            style={{ ...buttonStyle, backgroundColor: missionColorMap[missionIndex], opacity: isMissionOmitted(course.id, missionIndex) || !assigned || (mission.isPaywalled && currentUser.plan != "School")  ? 0.4 : 1, position: 'relative' }}
                                            onClick={(_e: any) => { 
                                                if (!mission.isPaywalled || currentUser.plan == "School") {
                                                    handleChangeMissionAssignment(missionIndex, isMissionOmitted(course.id, missionIndex));
                                                }
                                            
                                            }}
                                            disabled = {isAssigningMission[missionIndex] || !assigned}
                                        >
                                            {isAssigningMission[missionIndex] && <CircularProgress size="2rem" style={{ color: '#71AC45', position: 'absolute', top: '10%', left: '40%', }} />}
                                            {missionNameMap[missionIndex]}
                                        </Button>
                                    )
                                })}
                            </Box>
                        </>}
                    </Box>

                </Box>


            </Box>
        </Box>
    </>
}

export default CourseCardAssignment