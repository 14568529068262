import {Box, CircularProgress, InputAdornment, TextField, Typography} from "@material-ui/core";
import PageContainer from "components/ui/PageContainer"
import React, {useMemo, useState} from "react";
import useSharedStyles from "components/useSharedStyles";
import {GameTitle} from "../../enums/GameTitle";
import CoursesList from "./components/CoursesList";
import {Autocomplete} from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import MultiSelector from "../../components/ui/MultiSelector";
import { gradesArray } from "../../types/Grades";
import useSWR from "swr";
import {ICourseData} from "./ICourseData";
import endpoints from "../../endpoints";
import {
    convertCreativeBuildingsToSectionedCourses,
    getOrderedCoursesBySection,
    orderedSections
} from "./courseSections";
import {creativeBuildingsData} from "./CreativeBuildingsData";

const CoursesIndex: React.FC = () => {
    const sharedClasses = useSharedStyles()
    const [search, setSearch] = useState<string>("")
    const games = [GameTitle.BASICS, GameTitle.CREATOR]  
    const [gameFilter, setGameFilter] = useState<string[]>([]);
    const gradesList = gradesArray.map(grade => grade.name).filter(grade => grade !== "Other")
    const [gradeFilter, setGradeFilter] = useState<string[]>([]);

    const { data: courseData, error: courseDataError } = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
    const { data: creatorCourseData, error: creatorCourseDataError } = useSWR<ICourseData[]>(endpoints.allCreatorCourses);


    const orderedCourses = useMemo(() => {
        if (!courseData || !creatorCourseData) {
            return undefined
        }
        const orderedBySection = getOrderedCoursesBySection([
            ...courseData!,
            ...convertCreativeBuildingsToSectionedCourses(creativeBuildingsData) as ICourseData[],
            ...creatorCourseData!
        ]);
        return orderedSections
            .reduce((acc: ICourseData[], section) => {
                return acc.concat(orderedBySection[section] || []);
            }, [])
    },[courseData, creatorCourseData, creativeBuildingsData]);

    const courseDataLoading = (!courseData && !courseDataError || (!creatorCourseData && !creatorCourseDataError)) || !orderedCourses;

    function filteredCourses(): ICourseData[] {
        return orderedCourses!.filter(course => {
            if (gameFilter.length > 0) {
                if (!gameFilter.includes(GameTitle.BASICS) && course.id < 1000) {
                    return false;
                }
                if (!gameFilter.includes(GameTitle.CREATOR) && course.id > 1000) {
                    return false;
                }
            }
            if (gradeFilter.length > 0) {
                const grades = gradesArray.filter(grade => gradeFilter.includes(grade.name));
                if (!grades.map(grade => course.meta.grades?.includes(grade.key)).includes(true)) return false;
            }
            if (search.length > 0) {
                const lowerCaseSearch = search.toLowerCase();
                return course.meta.dashboardTitle?.toLowerCase().includes(lowerCaseSearch) ||
                    course.meta.dashboardSubtitle?.toLowerCase().includes(lowerCaseSearch) ||
                    course.meta.dashboardDescription?.toLowerCase().includes(lowerCaseSearch);
            }
            return true;
        });
    }
    
    function autoCompleteOptions(): string[] {
        let autoOptions: string[] = []
        if (!orderedCourses) {
            return autoOptions;
        }
        return [...autoOptions, ...orderedCourses.filter(course => course && course.meta.dashboardTitle).map(course => course!.meta.dashboardTitle!)];
    }

    return (
      <>
      <Box zIndex={2} 
           display="flex"
           flexDirection="row"
           justifyContent="flex-start"
           alignItems="center"
           paddingLeft="39px"
           style={{marginTop:"76px", 
               height:"100px", 
               width:"100%", 
               borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
               position:"fixed", 
               backgroundColor:"#fff",
               gap: "50px"}}>
          <Box width="372px" height="60px" style={{
              borderRadius: "10px",
          }}>
          <Autocomplete 
              freeSolo
              autoComplete={true}
              options={autoCompleteOptions()}
              onChange={(e, value) => setSearch(value || "")}
              renderInput={(params) => (
            <TextField 
                {...params} 
                placeholder="Search Courses" 
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                       ...params.InputProps,
                       type: 'search',
                       startAdornment: (
                           <InputAdornment position="start">
                               <SearchIcon />
                           </InputAdornment>
                       ),
                   }}
                   />
          )} />
          </Box>
          <Box display="flex" flexDirection="row">
              <img src='images/mdi_filter.svg'/>
              <Typography>Filter By</Typography>
          </Box>
          <MultiSelector title="Product" 
                         options={games} 
                         currentSelection={gameFilter} 
                         setSelection={setGameFilter} 
                         variant="outlined"
                         style={{ height:"60px", width: "250px"}}/>

          <MultiSelector title="Grade Level"
                         options={gradesList}
                         currentSelection={gradeFilter}
                         setSelection={setGradeFilter}
                         variant="filled"
                         style={{ height:"60px", width: "250px", color: "#fff", backgroundColor:"#05AEED"}}/>
      
      </Box>
    <PageContainer>

        <Box style={{overflow:"auto", width:"100%", marginTop: "100px"}}>
          <Box className={sharedClasses?.vspacing4}>
              {courseDataLoading ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                      <CircularProgress />
                  </Box>
              ):(
                  <CoursesList courses={filteredCourses()} />
              )}
          </Box>
        </Box>
    </PageContainer>
      </>
  )
}

export default CoursesIndex





