import { faCheckCircle, faExclamationCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import grades from "types/Grades";
import { ICleverClass } from "types/ICleverClass";
import { useTracking } from "context/TrackingProvider";
import GradeChip from "components/ui/GradeChip";
import { teacherOnboardingContext } from "../TeacherOnboardingIndex";
import { CleverLoginButton } from "pages/login/LoginInput";
import {routes} from "../../../routes";

export const AddClassCleverImport: React.VFC = () => {
  const { data: cleverClasses, error: cleverClassesError } = useSWR<ICleverClass[]>(endpoints.cleverKlasses);
  const cleverClassesLoading = !cleverClasses && !cleverClassesError;
  const { teacherData, error: teacherDataError } = useTeacherInit();
  const teacherDataLoading = !teacherData && !teacherDataError;
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [submitStatus, setSubmitStatus] = useState<('submitting' | 'success' | 'error')[]>([]);
  const { track } = useTracking();
  const sharedClasses = useSharedStyles();
  const theme = useTheme();
  const { setDidImportCleverClasses } = useContext(teacherOnboardingContext);


  useEffect(() => {
    console.log(cleverClasses)
  }, [cleverClasses])

  const handleToggleClass = (classId: string, selected: boolean) => {
    if (selected) {
      setSelectedClasses(selectedClasses.concat(classId))
    } else {
      setSelectedClasses(selectedClasses.filter(_classId => _classId !== classId));
    }
  }

  const handleSelectAll = () => {
    const alreadyImported = teacherData?.klasses.filter(({ clever_id }) => !!clever_id) || [];

    setSelectedClasses(cleverClasses!.filter(cleverClass => !alreadyImported.find(klass => klass.clever_id === cleverClass.data.id)).map(({ data: { id } }) => id));
  }

  const handleDeselectAll = () => {
    setSelectedClasses([]);
  }

  const handleSubmit = (_submitStatus: ('error' | 'submitting' | 'success')[] = []) => {
    setIsSubmitting(true);
    const cleverClassId = selectedClasses[_submitStatus.length];
    const submitStatus = _submitStatus.concat('submitting')
    setSubmitStatus(submitStatus);

    if (cleverClassId === undefined) {
      setSubmitSuccess(true);
      setDidImportCleverClasses(true);
      return;
    }

    const cleverClass = cleverClasses!.find(({ data: { id } }) => id === cleverClassId)!

    justFetch(endpoints.cleverKlasses, 'POST', {
      clever_klasses: JSON.stringify([{
        clever_id: cleverClassId,
        grade: cleverGradeToKodableGrade(cleverClass.data.grade)
      }])
    })
      .then(res => {
        if (!res.ok) {
          res.json().then(body => {
            setSubmitError(body.message || body.error || 'An unknown error occurred. Please try again later.');
          });

          return;
        }

        track('Created Class', {
          'Number of Students': cleverClass.data.students.length,
          'Grade': cleverGradeToKodableGrade(cleverClass.data.grade),
          'Upload Source': 'Clever'
        });

        handleSubmit(submitStatus.slice(0, submitStatus.length - 1).concat('success'));
      })
      .catch(() => {
        setSubmitStatus(submitStatus.slice(0, submitStatus.length - 1).concat('error'));
        setSubmitError('An unknown error occurred. Please try again later.');
      })
  }

  if (cleverClassesLoading || teacherDataLoading) {
    return <>
      <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
        <CircularProgress />
      </Box>
    </>
  }

  if (cleverClassesError?.error_type === 'CleverNotLoggedInError' || cleverClassesError?.error_type === 'CleverAuthorizationError') {
    return <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" height="100%">
      <Alert severity="warning">Please sign in with Clever to continue.</Alert>
      <CleverLoginButton />
      <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-end" flexGrow={1}>
        <Button
          variant="outlined"
          onClick={() => history.push(routes.teacherOnboarding.addClassManual.index)}
          disabled={isSubmitting}
        >Add Classes Without Clever</Button>
      </Box>
    </Box>
  }

  if (cleverClassesError?.error_type === 'CleverResourceNotFound') {
    return <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" height="100%">
      <Alert severity="error">We couldn't load your classes from Clever because your district hasn't shared your data with Kodable. Ask your Clever administrator to check the data sharing rules for Kodable.</Alert>
      <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-end" flexGrow={1}>
        <Button
          variant="outlined"
          onClick={() => history.push(routes.teacherOnboarding.addClassManual.index)}
          disabled={isSubmitting}
        >Add Classes Without Clever</Button>
      </Box>
    </Box>
  }

  if (cleverClassesError) {
    return <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" height="100%">
      <Alert severity="error">{cleverClassesError.message}</Alert>
      <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-end" flexGrow={1}>
        <Button
          variant="outlined"
          onClick={() => history.push(routes.teacherOnboarding.addClassManual.index)}
          disabled={isSubmitting}
        >Add Classes Without Clever</Button>
      </Box>
    </Box>
  }

  if (submitSuccess) {
    return <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center" className={sharedClasses.vspacing2}>
      <FontAwesomeIcon color={theme.palette.green.main} size="4x" icon={faCheckCircle} />
      <Typography variant="h2">Clever Classes Added</Typography>
    </Box>
  }


  return <Box display="flex" flexDirection="column" flexGrow={1} className={sharedClasses.vspacing2}>
    <Dialog open={!!submitError} fullWidth>
      <DialogTitle>
        Error Adding Classes
      </DialogTitle>
      <DialogContent>
        We couldn't add one of your classes from Clever. {submitError}
      </DialogContent>
      <DialogActions>
        <Box></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.classes.index)}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
    <Box>
      {selectedClasses.length < cleverClasses!.length &&
        <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSelectAll}>Select all</Button>
      }
      {selectedClasses.length === cleverClasses!.length &&
        <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleDeselectAll}>Deselect all</Button>
      }
    </Box>
    <Box component={Paper} {...{ variant: 'outlined' }} flexGrow={1} overflow="scroll">
      {cleverClasses && cleverClasses.length > 0 && <List>
        {cleverClasses?.filter(({ data: { id } }) => isSubmitting ? selectedClasses.includes(id) : true).map(({ data: { id, name, grade, students } }, idx) => {
          const selected = selectedClasses.includes(id);
          const alreadyImported = !!teacherData?.klasses.find(({ clever_id }) => clever_id === id);
          const gradeKey = cleverGradeToKodableGrade(grade);

          return <ListItem
            key={id}
            button {...{ disableRipple: true }}
            selected={selected} onClick={() => handleToggleClass(id, !selected)}
            disabled={alreadyImported || isSubmitting}
          >
            <ListItemIcon>
              {!isSubmitting && <Checkbox
                checked={selected}
              />}
              {submitStatus[idx] === 'submitting' && <CircularProgress size={32} />}
              {submitStatus[idx] === 'success' && <FontAwesomeIcon size="2x" icon={faCheckCircle} />}
              {submitStatus[idx] === 'error' && <FontAwesomeIcon size="2x" icon={faExclamationCircle} />}
            </ListItemIcon>
            <ListItemText
              primary={<>{alreadyImported ? <><Chip size="small" label="Already imported" />&nbsp;</> : ''}{name}</>}
              secondary={<Box display="flex" alignItems="center">{grade && <><GradeChip size="small" grade={gradeKey as keyof typeof grades} />&nbsp;&nbsp;</>}<FontAwesomeIcon icon={faUser} />&nbsp;{students.length}</Box>}
            />
          </ListItem>
        })}
      </List>}
      {cleverClasses?.length === 0 && <Alert severity="info">We couldn't find any classes in your Clever account.</Alert>}
    </Box>
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Button
        variant="outlined"
        onClick={() => history.push(routes.teacherOnboarding.addClassManual.index)}
        disabled={isSubmitting}
      >Add Classes Without Clever</Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
        disabled={selectedClasses.length === 0 || isSubmitting}
      >{isSubmitting ? <>Added {submitStatus.length - 1}/{selectedClasses.length}</> : 'Add'}</Button>
    </Box>
  </Box>
}

const cleverGradeToKodableGrade = (cleverGrade: string) => {
  return (cleverGrade !== undefined && cleverGrade === 'Kindergarten')
    ? 'k'
    : ((cleverGrade !== undefined && (cleverGrade in grades))
      ? cleverGrade
      : 'all');
}