import endpoints from "endpoints"
import { justFetch } from "mutations/mutate"
import { IKlass } from "types/IKlass"
import { ELeadState, IStudentSchoolProfile } from "types/IStudentSchoolProfile";
import Amplitude from "utils/Amplitude";
import 'utils/Intercom';

export enum TrackParentInviteMethod {
    link = 'link',
    download = 'download',
    email = 'email',
    skipped = 'skipped',
    clicked = 'clicked'
}

const TrackParentInviteMethodStrings = {
  [TrackParentInviteMethod.link]: "Copied Invite Families Link",
  [TrackParentInviteMethod.download]: "Printed Parent Instructions",
  [TrackParentInviteMethod.email]: "Teacher Invited Families via Email",
  [TrackParentInviteMethod.skipped]: "Teacher Skipped Onboarding Invite",
  [TrackParentInviteMethod.clicked]: "Teacher Clicked On Invite Families"
}

export enum TrackParentInviteMethodLocation {
  sidebar = 'Sidebar',
  classPageWidget = 'Class Page Invite Widget',
  onboarding = 'Onboarding',
  inviteFamiliesButton = 'Invite Families Button',
  bulkAddClasses = 'Bulk Add Classes',
  getStudentsLoggedIn = 'Get Students Logged In Widget',
  classHomePage = 'Class Home Page'
}

export enum TrackParentInviteTrackIgnore {
  amplitude = 'amplitude',
  intercom = 'intercom',
  database = 'database'
}

export interface TrackParentInviteResponse {
  status: number;
  invalid_emails: string[];
}

const actions = {
    /**
     * Used to track which klasses have received invites and through which methods, in the DB,
     * Intercom, and Amplitude.
     * @param klass (required) klass to track inviteMethod on
     * @param inviteMethod (required) The invite method to track; one of TrackParentInviteMethod. If "skipped",
     * it will only track in Amplitude -- not the DB or Intercom
     * @param location (Optional) Used to define which version of the modal(s) they used to invite
     * @param ignoreTrackList (Optional) An array of TrackParentInviteTrackIgnore values that control
     * which services NOT to track in. For example, passing `TrackParentInviteTrackIgnore.amplitude` in
     * this list will cause it to NOT track in Amplitude
     * @param parentEmails
     */
    track: (
        klass: IKlass | null | undefined,
        inviteMethod: TrackParentInviteMethod,
        location?: TrackParentInviteMethodLocation | null | undefined,
        ignoreTrackList?: TrackParentInviteTrackIgnore[],
        parentEmails?: String[],
    ) : Promise<TrackParentInviteResponse> => { 
        return new Promise((resolve, reject) => {
          if (!klass && inviteMethod !== 'clicked') {
            reject({status: 404, invalid_emails: []})
            return
          }

          if (!ignoreTrackList || ignoreTrackList?.indexOf(TrackParentInviteTrackIgnore.amplitude) === -1) {
            // Track in Amplitude
            Amplitude.track(
              TrackParentInviteMethodStrings[inviteMethod],
              {
                klass_id: klass?.id || 0,
                location: location || 'unknown'
              }
            );
          }
          
          // Don't track in Intercom/DB if they just clicked the "skip" button in onboarding; this
          // can only happen once per class, so if they invite later this will not be triggered
          if (inviteMethod === 'skipped' || inviteMethod === 'clicked') {
            resolve({status: 201, invalid_emails: []})
            return
          }

          if (!ignoreTrackList || ignoreTrackList?.indexOf(TrackParentInviteTrackIgnore.intercom) === -1) {
            // Track in Intercom
            if (window && window.Intercom && typeof window?.Intercom == 'function') {
              (window as any).Intercom(
                'trackEvent',
                TrackParentInviteMethodStrings[inviteMethod],
                {
                  klass_name: klass?.klass_name,
                  klass_id: klass?.id
                }
              );
            }
          }
          
          if (!ignoreTrackList || ignoreTrackList?.indexOf(TrackParentInviteTrackIgnore.database) === -1) {   
            // Track in klass table of database
            justFetch(endpoints.klassTrackParentInvite(klass?.id || 0), 'POST', {
              invite_method: inviteMethod,
              parent_emails: parentEmails || []
            })
            .then(async (res) => {
              const body = await res.json() as TrackParentInviteResponse
              resolve(body)
            })
            .catch((error) => {
              resolve({status: 500, invalid_emails: []})
            })
          }
        })
    },
    /**
     *  Used to find klasses that have students with linked accounts, and update them in our database
     *  to be marked as already invited. We set "link" as the invite method, as we did not previously
     *  track in our database which method teachers used to invite, so we assume its link.
     *  This way, teachers won't see notifications to invite klasses they already did.
     * 
     *  @param klass the IKlass to check to see if it has parent_invites tracked already
     *  @param students an array of IStudentSchoolProfiles to check to see if the students are
     *  already linked, or at least invited. Should be a list of students from the IKlass specified
     */
    trackInit: (klass: IKlass, students: IStudentSchoolProfile[]) => {
      // if parent_invites is not null or undefined, we're already tracking that this klass has been
      // invited; skip next checks
      if (!!klass?.parent_invites) {
        return
      }

      const invitedOrLinkedStudents = students.filter((student) => {
        return student?.lead?.state && [ELeadState.invited, ELeadState.linked].indexOf(student?.lead?.state) > 0
      })

      // If there are no invited or linked students, skip, there's no need to init tracking
      if (invitedOrLinkedStudents.length <= 0) {
        return
      }

      // Track the klass as having been invited via Link, as we don't know the true invite method
      // and the handouts technically use the same Link
      actions.track(
        klass,
        TrackParentInviteMethod.link,
        null,
        [TrackParentInviteTrackIgnore.amplitude, TrackParentInviteTrackIgnore.intercom]
      )
    }
}

export default actions
