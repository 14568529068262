import { Box } from "@material-ui/core"
import { IRouteParams } from "AppRoutes"
import useTeacherInit from "loaders/useTeacherInit";
import { useParams } from "react-router-dom"
import React from "react";

export const HocCertificatePrintout: React.VFC = () => {
  const { classId } = useParams<IRouteParams>();
  const { teacherData } = useTeacherInit();
  const klass = teacherData?.klasses.find(({ id }) => id?.toString() === classId);
  const students = teacherData?.students.filter((student) => student.klasses.includes(Number(classId)));

  return <>
    {students?.map(student => {
      return <Box style={{ pageBreakAfter: 'always' }} width="100%" marginX="auto" position="relative">
        <img src="/images/printouts/HOC_certificate.png" />
        <Box fontSize={45} position="absolute" height="100%" textAlign="center" top="0" left="34%" style={{ writingMode: 'vertical-lr' }} >{student.name}</Box>
        <Box fontSize={24} position="absolute" height="100%" textAlign="center" top="25px" left="5.5%" style={{ writingMode: 'vertical-lr', textTransform: 'uppercase' }} >{klass?.klass_code.toLocaleUpperCase()}</Box>
      </Box>
    })}
  </>
}