import React, { useEffect, useState, useCallback } from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import { DialogStateProps } from "hooks/useDialogState";
import useSWR from "swr";
import { ICleverClass } from "../../../types/ICleverClass";
import { IKlass } from "../../../types/IKlass";
import endpoints from "../../../endpoints";
import { justFetch } from "../../../mutations/mutate";
import { cleverGradeToKodableGrade } from "../../../pages/clever/utils/cleverToKodableGrade";
import { CleverLoginButton } from "../../../pages/login/LoginInput";
import useTeacherInit from "../../../loaders/useTeacherInit";
import {useClever} from "../../../context/CleverProvider";

const SyncCleverDialog: React.VFC<DialogStateProps & { klasses: IKlass[] }> = ({
                                                                                 open = false,
                                                                                 onClose = () => {},
                                                                                 klasses,
                                                                               }) => {
  const { data: cleverClasses, error: cleverClassesError } = useSWR<ICleverClass[]>(
    endpoints.cleverKlasses
  );
  const {mutate: mutateInit} = useTeacherInit()
  const {useKlasses, useStudents} = useClever()
  const {mutate: mutateKlasses} = useKlasses();
  const {mutate: mutateStudents} = useStudents();
  const [syncClasses, setSyncClasses] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateRosters = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const updatePromises = syncClasses.map(async (cleverClassId) => {
        const cleverClass = cleverClasses!.find(({ data: { id } }) => id === cleverClassId)!;

        try {
          const response = await justFetch(endpoints.cleverKlasses, 'POST', {
            clever_klasses: JSON.stringify([
              {
                clever_id: cleverClassId,
                grade: cleverGradeToKodableGrade(cleverClass.data.grade),
              },
            ]),
          });

          if (!response.ok) {
            throw new Error();
          }

        } catch (error) {
          throw error;
        }
      });

      await Promise.all(updatePromises);
      mutateKlasses();
      mutateStudents();
      mutateInit();
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [syncClasses, cleverClasses]);

  useEffect(() => {
    if (!klasses || !cleverClasses) {
      return;
    }
    const alreadyImported = klasses.filter(({ clever_id }) => !!clever_id) || [];
    setSyncClasses(
      cleverClasses
        .filter((cleverClass) =>
          alreadyImported.find((klass) => klass.clever_id === cleverClass.data.id)
        )
        .map(({ data: { id } }) => id)
    );
  }, [cleverClasses, klasses]);

  return (
    <Dialog open={open} fullWidth>
      <LinearProgress style={{ visibility: isSubmitting ? 'visible' : 'hidden' }} />
      <DialogTitle>
        {!cleverClassesError ? "Update Class Rosters?" : "Sign into Clever to Update Class Rosters"}
      </DialogTitle>
      <DialogContent>
        {!cleverClassesError && (
          <Alert severity="warning">
            This will update your existing class rosters in Kodable to match what is currently being
            shared with us from Clever. Any students that are no longer being shared will be
            removed, and newly shared students will be added. This <b>will not</b> add or remove
            classes.
          </Alert>
        )}
        {cleverClassesError && <CleverLoginButton />}
      </DialogContent>
      {!cleverClassesError && (
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateRosters}
            disabled={isSubmitting}
          >
            Update Rosters
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SyncCleverDialog;