import useSharedStyles from "../../components/useSharedStyles";
import {Box, Paper, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faDownload, faMapMarkerAlt, faRecycle, faStar} from "@fortawesome/free-solid-svg-icons";
import ClassProgressBar from "./ClassProgressBar";
import {faStar as faStarOutlined} from "@fortawesome/free-regular-svg-icons";
import React, {useMemo} from "react";
import Button from "../../components/ui/buttons/Button";
import {format} from "date-fns";
import classNames from "classnames";
import {dateToUTC, IProgressReportView} from "./reportUtilities";
import {ELessonType} from "../courses/ICourseData";
import {
    OverviewHeaderProps,
    ProgressReportViewProps, useClassCoursesProgressHeaderStyles,
    useStudentsProgressStyles
} from "./StudentsProgress";
import {ClassProgressStudentRowCell} from "./ProgressTable";



const columnWidth = 220;
const levelColumnWidth = 100;
export const LegacyView: React.VFC<ProgressReportViewProps> = ({ classIds, selectedCourseId, onSelectCourse, reportView , students, selectedCourse, displayState, selectedGame}) => {

    // todo: is displayState != all hide unassigned lessons
    const studentsProgressStyles = useStudentsProgressStyles();

    const filteredCourses = useMemo(() => {
        return reportView?.orderedCourses.filter(course => {
            return course.orderedMissions && course.orderedMissions.length > 0
        })
        // todo: filter base on omissions
    }, [reportView])
    return <>
        <Box display="flex" component={Paper} {...{ elevation: 0 }} flexDirection="row" className={studentsProgressStyles.stickyCourses}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width={`${columnWidth}px`} style={{textAlign: 'center'}}>
                <Typography variant="h2" style={{marginBottom: '.5rem'}}>Student Progress</Typography>
                <Typography variant="body2" style={{marginBottom: '.75rem'}}>Download student progress trackers so student can track their own learning!</Typography>
                <a
                    href="http://resources.kodable.com/public/activities/StudentProgressTrackers.pdf"
                    target="_blank" rel="noreferrer"
                >
                    <Button
                        startIcon={<FontAwesomeIcon icon={faDownload} />}
                        color="blue"
                        variant="contained"
                    >
                        Progress Tracker
                    </Button>
                </a>
            </Box>
            {!selectedCourseId && filteredCourses?.map(courseReport => {
                return <CoursesHeaderLegacy
                    key={courseReport.courseId}
                    courseReportData={courseReport}
                    onSelectCourse={onSelectCourse ? (selectedCourseId => onSelectCourse?.(selectedCourseId)) : undefined}
                    displayState={displayState}
                />
            })}
            {selectedCourseId && <LessonsHeaderLegacy courseId={selectedCourseId} report={reportView!} />}
        </Box>

        <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column">
                {students.map(student => {
                    return <ClassProgressStudentRowCell key={student.id}>
                        <Typography variant="subtitle1">{student.name}</Typography>
                        Last seen {student.tracked_date ? format(new Date(student.tracked_date), 'M/d/yyyy') : 'never'}
                    </ClassProgressStudentRowCell>
                })}
            </Box>

            {!selectedCourseId && filteredCourses?.map(courseReport => {
                return <CourseColumnLegacy key={courseReport.courseId} courseReportData={courseReport} />
            })}

            {selectedCourse?.orderedLessons?.map(lesson => {
                return <LessonsColumnLegacy key={lesson.lessonId} lesson={lesson} />
            })}
        </Box>
        {classIds.length === 1 && <LegacyKey />}
    </>
}

const CoursesHeaderLegacy: React.VFC<OverviewHeaderProps> = ({ courseReportData, onSelectCourse }) => {
    const sharedClasses = useSharedStyles();
    const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

    return <Box
        mb={2}
        display="flex"
        height={155}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width={columnWidth}
        className={onSelectCourse ? classNames({ [sharedClasses.hoverCursorPointer]: !courseReportData.emptyCourse, [classCoursesProgressHeaderStyles.clickableTitle]: !courseReportData.emptyCourse }, classCoursesProgressHeaderStyles.title) : ''}
        onClick={() => {
            if (!courseReportData.emptyCourse) {
                onSelectCourse?.(courseReportData.courseId.toString())
            }
        }}
    >
        <img
            src={courseReportData.galaxyImage ? `images/courses/planets/${courseReportData.galaxyImage}.png` : courseReportData.iconUrl}
            alt={`${courseReportData.dashboardTitle} icon`}
            style={{
                height: 100,
            }}
        />
        <Typography variant="h2" color={onSelectCourse ? 'primary' : 'textPrimary'}>{courseReportData.dashboardTitle}</Typography>
    </Box>
}

const CourseColumnLegacy: React.VFC<{ courseReportData: IProgressReportView['orderedCourses'][0] }> = ({ courseReportData }) => {
    const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();
    const isBeachCleanup = courseReportData.courseId === 7;

    return <Box className={classCoursesProgressHeaderStyles.column}>
        {
            courseReportData.orderedProgress.map((progress, idx) => {
                const state = (() => {
                    if (isBeachCleanup) {
                        return 'noProgress';
                    }

                    if (progress.completionPercentage < 1) {
                        return 'inProgress';
                    }

                    if (progress.threeStarredEverything) {
                        return 'fullyCompleted';
                    }

                    return 'partiallyCompleted';
                })();

                const completionPercentage = (() => {
                    if (isBeachCleanup) {
                        return (progress?.hoc_garbage_pickup_count || 0) > 0 ? 1 : 0;
                    }

                    return progress.completionPercentage;
                })();

                return <ClassProgressStudentRowCell key={idx}>
                    {courseReportData.legacyStatus[idx] ? (
                        <>
                            {progress.showAsPercent && <Box textAlign="center" width="100%">
                                {!isNaN(progress.completionPercentage)
                                    ? `${Math.round(progress.completionPercentage * 100)}%`
                                    : (isBeachCleanup ? `${progress.hoc_garbage_pickup_count || 0} pieces collected` : '--')}
                            </Box>}
                            {!progress.showAsPercent && <ClassProgressBar progress={completionPercentage} state={state} showProgressIndicator={progress.currentLocation}>
                                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                                    {state === 'fullyCompleted' && <FontAwesomeIcon size="2x" icon={faStar} />}
                                    {state === 'partiallyCompleted' && <FontAwesomeIcon size="2x" icon={faStarOutlined} />}
                                    {(state === 'partiallyCompleted' || state === 'fullyCompleted') && <Box ml={1}>Course completed {format(dateToUTC(progress.completionDate!), 'M/d/yyyy')}</Box>}
                                    {isBeachCleanup && (progress?.hoc_garbage_pickup_count || 0) > 0 && <>
                                        <FontAwesomeIcon size="2x" icon={faRecycle} />
                                        <Box
                                            ml={1}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Box display="inline" fontWeight="bold" fontSize="0.85rem">{progress.hoc_garbage_pickup_count}</Box>&nbsp;trash pieces collected
                                        </Box>
                                    </>}
                                </Box>
                            </ClassProgressBar>}
                        </>
                    ) : (
                        <Box textAlign="center" width="100%">
                            No legacy data
                        </Box>
                    )}
                </ClassProgressStudentRowCell>
            })
        }
    </Box>
}
const LessonsHeaderLegacy: React.VFC<{ courseId: string, report: IProgressReportView }> = ({ courseId, report }) => {
    const courseReportData = useMemo(() => {
        return report.orderedCourses.find(courseReportView => courseReportView.courseId.toString() === courseId)!;
    }, [courseId, report]);

    const lessons = courseReportData.orderedLessons!;

    return <Box display="flex" flexDirection="row" alignItems="flex-end">
        <Box
            my={2}
            display="flex"
            height={155}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            maxWidth={columnWidth}
            minWidth={columnWidth}
        >
            <img
                src={courseReportData.galaxyImage ? `images/courses/planets/${courseReportData.galaxyImage}.png` : courseReportData.iconUrl}
                alt={`${courseReportData.dashboardTitle} icon`}
                style={{
                    height: 100,
                }}
            />
            <Typography variant="h2">{courseReportData.dashboardTitle}</Typography>
        </Box>
        <Box display="flex" flexDirection="row">
            {lessons?.map(lesson => {
                return <Box key={lesson.lessonId} display="flex" flexDirection="column" alignItems="center" textAlign="center" justifyContent="space-between" mb={1}>
                    <img src={lesson.type === ELessonType.mediaContent ? "/images/courses/media_content_icon.png" : "/images/courses/game_content_icon.png"} style={{ maxHeight: 32 }} />
                    <Box mt={1} display="flex" minWidth={levelColumnWidth}>
                        {lesson.type === ELessonType.mediaContent && <Typography variant="body2">{lesson.dashboardTitle}</Typography>}
                        {lesson.type === ELessonType.gameContent && lesson.orderedLevels.map(level => <Box key={level.levelId} width={levelColumnWidth}>
                            <Typography
                                style={{wordWrap: 'break-word', padding: '.5rem'}}
                                variant="body2"
                            >
                                {`${lessons.indexOf(lesson) + 1}.${lesson.orderedLevels.indexOf(level) + 1}`}
                            </Typography>
                        </Box>)}
                    </Box>
                </Box>
            })}
        </Box>
    </Box>;
}

const LessonsColumnLegacy: React.VFC<{ lesson: Required<IProgressReportView['orderedCourses'][0]>['orderedLessons'][0] }> = ({ lesson }) => {
    const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

    return <>
        {lesson.orderedLevels.map((level, levelIdx) => {
            return <Box key={level.levelId} className={classCoursesProgressHeaderStyles.column}>
                {level.orderedProgress.map((progress, idx) => {
                    return <ClassProgressStudentRowCell key={idx} width={levelColumnWidth} hideBorder={levelIdx > 0}>
                        <ClassProgressBar
                            state={progress.stars === 3 ? 'fullyCompleted' : (progress.stars > 0 ? 'partiallyCompleted' : 'inProgress')}
                            progress={progress.stars > 0 ? 1 : 0}
                        >
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" height="100%" px={2}>
                                {lesson.type === ELessonType.mediaContent && progress.stars > 0 && <FontAwesomeIcon icon={faCheckCircle} />}
                                {lesson.type === ELessonType.gameContent && progress.stars >= 0 && new Array(progress.stars).fill(true).map((node, i) => <FontAwesomeIcon key={i} icon={faStar} />)}
                            </Box>
                        </ClassProgressBar>
                    </ClassProgressStudentRowCell>
                })}
            </Box>
        })}
    </>
}

const LegacyKey = () => {
    const sharedClasses = useSharedStyles();

    return <Box component={Paper} {...{ variant: 'outlined' }} p={2} mt={4}>
        <Typography variant="subtitle1">Key</Typography>
        <Box mt={2}><FontAwesomeIcon icon={faMapMarkerAlt} /> Last Activity</Box>
        <Box mt={2} display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" alignItems="flex-end" className={sharedClasses.vspacing2} width={220} >
                <ClassProgressBar
                    state="fullyCompleted"
                    progress={1}
                >
                    <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2}>
                        <FontAwesomeIcon icon={faStar} />&nbsp;Completed all with 3 stars
                    </Box>
                </ClassProgressBar>
                <ClassProgressBar
                    state="partiallyCompleted"
                    progress={1}
                >
                    <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2}>
                        <FontAwesomeIcon icon={faStarOutlined} />&nbsp;Completed all with less than 3 stars
                    </Box>
                </ClassProgressBar>
            </Box>
            <Box ml={2} width={220} display="flex" flexDirection="column" alignItems="flex-end" className={sharedClasses.vspacing2}>
                <ClassProgressBar
                    state="inProgress"
                    progress={0.66}
                >
                    <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2}>
                        In progress
                    </Box>
                </ClassProgressBar>
                <ClassProgressBar
                    state="inProgress"
                    progress={0}
                >
                    <Box display="flex" flexDirection="row" alignItems="center" height="100%" px={2} color="black">
                        Not yet attempted
                    </Box>
                </ClassProgressBar>
            </Box>
        </Box>
    </Box>
}