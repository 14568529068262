import { Box } from "@material-ui/core"
import { IRouteParams } from "AppRoutes"
import useTeacherInit from "loaders/useTeacherInit";
import { useParams } from "react-router-dom"
import * as queryString from 'query-string';
import React from "react";

const focusedStudentId = queryString.parse(window.location.search).focusedStudentId as string;

export const ValentinesPrintout: React.VFC = () => {
  const { classId } = useParams<IRouteParams>();
  const { teacherData } = useTeacherInit();
  const students = teacherData?.students.filter((student) => student.klasses.includes(Number(classId)) && (focusedStudentId ? student.id.toString() === focusedStudentId : true));

  return <>
    {students?.map((student, index) => {
      return <Box key={index} style={{ pageBreakAfter: 'always' }} width="90%" marginX="auto" position="relative">
        <img src="/images/printouts/vday_handout_no_valentine.png" />
        <Box fontSize={50} position="absolute" top="51%" lineHeight={1} left="0%" width="100%" textAlign="center" fontWeight="bold" fontFamily="Kodable" color="#c22f96">{student.name}</Box>
        <Box fontSize={28} position="absolute" top="66%" left="70%" width="22%" textAlign="center" style={{ textTransform: 'uppercase' }} fontWeight="bold">{student.student_code}</Box>
      </Box>
    })}
  </>
}