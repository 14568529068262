import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import MenuItem from 'components/ui/MenuItem';
import { useFormik } from "formik";
import { IStudentsDialogProps } from "../../../pages/students/IStudentsDialogProps";
import * as Yup from 'yup';
import StudentActionConfirmationList from "../../../pages/students/StudentActionConfirmationList";
import useSharedStyles from "components/useSharedStyles";
import Select from "components/ui/Select";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useState } from "react";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import { justFetch } from "mutations/mutate";
import endpoints from "endpoints";
import useCurrentUser from "loaders/useCurrentUser";
import { mutate } from "swr";
import { useEffect } from "react";
import { useAlert } from "context/AlertProvider";
import {useRouteMatch} from "react-router-dom";
import {IRouteParams} from "../../../AppRoutes";

const TransferStudentsDialog: React.VFC<IStudentsDialogProps & { didMoveStudents: () => void }> = ({ open, students, onClose = (...args: any[]) => { }, didMoveStudents }) => {
  const { currentUser } = useCurrentUser();
  const { teacherData } = useTeacherInit();
  const { params } = useRouteMatch<IRouteParams>();
  const [submitError, setSubmitError] = useState(false);
  const alert = useAlert();

  const form = useFormik({
    initialValues: {
      klass_id: undefined,
    },
    onSubmit: async values => {
      try {
        const res = await justFetch(endpoints.moveStudentsToClass, 'PUT', {
          student_codes: students.map(({student_code}) => student_code),
          teacher_id: currentUser.id,
          old_klass_id: params.classId ? params.classId : students.map(({klasses}) => klasses[0])[0],
          new_klass_id: values.klass_id
        });
        if (!res.ok) {
          throw new Error();
        }

        mutate(endpoints.teacherInit);
        alert.success(`Student${students.length > 1 ? 's' : ''} Moved`);
        didMoveStudents();
        onClose();
      } catch {
        setSubmitError(true);
      }
    },
    validationSchema: Yup.object({
      klass_id: Yup.number().required('Select a class to move these students')
    })
  });

  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  // eslint-disable-next-line
  }, [open]);

  return <Dialog fullWidth open={open} onClose={onClose}>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>Move students to another class</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Select
          displayEmpty
          label="Move Students To Class:"
          helperText={form.errors.klass_id}
          error={!!form.errors.klass_id}
          onChange={e => form.setFieldValue('klass_id', e.target.value)}
          fullWidth
        >
          <MenuItem value={undefined} disabled>Select a class</MenuItem>
          {teacherData?.klasses.filter((klass) => klass.clever_id == null).sort((a, b) => a.klass_name.localeCompare(b.klass_name)).map(klass => {
            return <MenuItem
              key={klass.id}
              value={klass.id}
            >
              {klass.klass_name}
            </MenuItem>
          })}
        </Select>
        <Typography>
          This will move {students.length > 1 ? `these ${students.length} students:` : `this student:`}
        </Typography>
        <StudentActionConfirmationList students={students} showCurrentClass />

        {!form.isValid && form.submitCount > 0 && <Alert severity="error">Please correct the errors above to continue.</Alert>}

        {submitError && <Alert severity="error" action={
          <Button
            color="inherit"
            size="small"
            onClick={() => form.submitForm()}
          >Try again</Button>
        } >There was an error trying to submit this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          type="reset"
          disabled={form.isSubmitting}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          disabled={form.isSubmitting}
        >
          Move students
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default TransferStudentsDialog;