import {Box, Paper} from "@material-ui/core";
import Button from "../../components/ui/buttons/Button";
import TextField from "../../components/ui/TextField";
import {useFormik} from "formik";
import * as Yup from "yup";
import useSharedStyles from "../../components/useSharedStyles";
import {justFetch} from "../../mutations/mutate";
import endpoints from "../../endpoints";
import {useHistory} from "react-router-dom";
import {routes} from "../../routes";
import {useState} from "react";
import InfoBox from "../../components/ui/InfoBox";

interface IQuoteLookupRequest {
  quote: string
}

const QuoteLookup = () => {
  const sharedStyles = useSharedStyles();
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const form = useFormik<IQuoteLookupRequest>({
    initialValues: {
      quote: ''
    },
    onSubmit: async values => {
      const resp = await justFetch(endpoints.salesQuote.lookup(values.quote), 'GET')

      if (resp.status === 200) {
        const data = await resp.json()
        history.push(routes.sales.quotes.view(data["secret_code"]))
      } else {
        setError(true)
      }

    },
    validationSchema: Yup.object<IQuoteLookupRequest>({
      quote: Yup.string().required('Please enter your unique quote number')
    })
  });

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      style={{
        margin: 'auto',
        width: 350,
        padding: 24
      }}
      component={Paper}
    >
      <Box display={'flex'} justifyContent={'center'} style={{marginTop: 20, marginBottom: 36}}>
        <img src={'/images/logos/kodable-edu-color-vertical.svg'} width={200}/>
      </Box>
      <form onSubmit={form.handleSubmit}>
        <Box className={sharedStyles.vspacing2}>
          {error && <InfoBox severity={'error'}>No quote found with that ID</InfoBox>}
          <TextField
            label={'Quote ID'}
            id={'quote'}
            name={'quote'}
            placeholder={'1K-00000'}
            value={form.values['quote']}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            helperText={form.touched['quote'] ? form.errors['quote'] : undefined}
            error={form.submitCount > 0 && !!form.errors['quote']}
          />
          <Button
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            disabled={form.isSubmitting}
            fullWidth
          >
            Find Quote
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default QuoteLookup