import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import queryString from 'query-string';

const queryParams = queryString.parse(window.location.search);


const CleverSync: React.VFC = () => {
  const [requestState, setRequestState] = useState<'firing' | 'error' | 'success'>('firing');
  const sharedClasses = useSharedStyles();
  const theme = useTheme();

  useEffect(() => {
    if (queryParams.code) {
      justFetch(endpoints.syncClever, 'POST', { code: queryParams.code })
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          setRequestState('success');
        })
        .catch(() => setRequestState('error'));
    }
  }, []);

  return <PageContainer variant="centered">
    {requestState === 'firing' && <PageHeader title="Scheduling Clever Sync..."></PageHeader>}
    {requestState === 'success' && <PageHeader title="Clever Sync Completed"></PageHeader>}
    {requestState === 'error' && <PageHeader title="Clever Sync Error"></PageHeader>}
    <Box display="flex" flexDirection="column" alignItems="center" my={2}>
      {requestState === 'firing' && <CircularProgress />}
      {requestState === 'success' && <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" alignItems="center">
        <FontAwesomeIcon color={theme.palette.green.main} size="4x" icon={faCheckCircle} />
        <Typography>We have synced your Clever classes!</Typography>
      </Box>}
      {requestState === 'error' && <Box alignSelf="center"><Alert severity="error">We couldn't sync your Clever classes.</Alert></Box>}
    </Box>
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      {requestState !== 'firing' && <Button
        variant="contained"
        color="primary"
        {...{
          component: RouterLink,
          to: routes.classes.index
        }}
      >
        Continue
      </Button>}
    </Box>
  </PageContainer>
}

export default CleverSync;