import React, {useState} from "react";
import {Box, Checkbox, FormControl, makeStyles, Typography} from "@material-ui/core";
import Select from "./Select";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "./MenuItem";


const useSelectorStyles = makeStyles(theme => ({
    selector: {
        borderRadius: "30px",
    },
    convenience: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        fontWeight: "bold",
    },
}));

interface MultiSelectorProps {
    title: string;
    options: string[];
    currentSelection: string[];
    setSelection: (selection: string[]) => void;
    variant?: "outlined" | "standard" | "filled" | undefined;
    style?: React.CSSProperties;
}
export const MultiSelector: React.VFC<MultiSelectorProps> = ({title, options, currentSelection, setSelection, variant, style}) => {
    const [open, setOpen] = useState(false);
    const styles = useSelectorStyles();

    function handleChange(option: string){
        if (currentSelection.includes(option)){
            setSelection(currentSelection.filter((value) => value !== option));
        } else {
            setSelection([...currentSelection, option]);
        }
    }

    return (
        <FormControl variant={variant}>
            <Select
                multiple
                style={style}
                className={styles.selector}
                placeholder={title}
                value={currentSelection}
                displayEmpty={true}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                renderValue={(selectedRaw) => {
                    const selected = selectedRaw as string[];
                    const capitalized = selected.length > 0 ? selected[0].charAt(0).toUpperCase() + selected[0].slice(1) : "";
                    return selected.length == 0 ? title : selected.length > 1 ? `${capitalized} + ${selected.length - 1}` : `${capitalized}`}
                }
            >

                <Box display="flex" flexDirection="row" justifyContent="space-between" style={{marginLeft:"10px",marginRight:"10px", marginTop:"5px", gap:"5px" }}>
                    <Typography variant={"h2"}>{title}</Typography>
                    <CloseIcon onClick={() => {
                        setOpen(false);
                    }} />
                </Box>
                <Box display="flex" flexDirection="row" style={{marginLeft:"10px", marginTop:"5px", gap:"5px" }}>
                    <Typography className={styles.convenience} onClick={() => {
                        setSelection(options);
                    }}>Select all</Typography>
                    <Typography>•</Typography>
                    <Typography className={styles.convenience} onClick={() => {
                        setSelection([]);
                    }}>Deselect all</Typography>
                </Box>
                {options.map((option) => (
                    <MenuItem key={option} value={option} onClick={() => handleChange(option)}>
                        <Checkbox checked={currentSelection.indexOf(option) > -1} />
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MultiSelector;