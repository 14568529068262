import PageContainer from "../../../../components/ui/PageContainer";
import {useOrganizationSelection} from "../../../../context/OrganizationSelectionProvider";
import {useSalesInitialSelectionStyles} from "../../SalesSiteTypeSelections";
import useSharedStyles from "../.././../../components/useSharedStyles";
import {useHistory} from "react-router-dom";
import {Box, Button, Typography} from "@material-ui/core";
import {routes} from "../../../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faBuildingColumns, faPeopleGroup, faSchool} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { getConfigPlanURL } from "./SubmitOrderIndex";

const OrderSelectOrgTypePage: React.VFC = () => {
  const { setIsOutsideUS, setSiteType, setIsLocationSet } = useOrganizationSelection()
  const configureStyles = useSalesInitialSelectionStyles()
  const sharedClasses = useSharedStyles()
  const history = useHistory();

  return (
    
      <Box >
        <Box display="flex" flexDirection="column">
          <Typography variant="h1" style={{ textAlign: 'center', marginBottom: '2rem' }}>Is this order for a School or a larger District/Organization?</Typography>
          <Box className={sharedClasses?.hspacing4} display="flex" flexDirection="row" alignItems="center">
            <Box className={configureStyles?.siteTypeContainer}>
              <Box
                className={configureStyles?.siteTypeBox}
                onClick={() => {
                  setSiteType('school')
                  history.push(routes.sales.orders.submit.findSchool)
                }}
              >
                <Box className={configureStyles?.optionBoxContent}>
                  <FontAwesomeIcon className={configureStyles?.optionBoxIcon} icon={faSchool} />
                  <Typography className={configureStyles?.optionBoxText}>School</Typography>
                </Box>
              </Box>
              <Typography className={configureStyles?.optionBoxHelpText}>I am purchasing for a single location</Typography>
            </Box>
            <Box className={configureStyles?.siteTypeContainer}>
              <Box
                className={configureStyles?.siteTypeBox}
                onClick={() => {
                  setSiteType('district')
                  history.push(routes.sales.orders.submit.findDistrict)
                }}
              >
                <Box className={configureStyles?.optionBoxContent}>
                  <FontAwesomeIcon className={configureStyles?.optionBoxIcon} icon={faBuildingColumns} />
                  <Typography className={configureStyles?.optionBoxText}>District</Typography>
                </Box>
              </Box>
              <Typography className={configureStyles?.optionBoxHelpText}>I am purchasing for multiple schools</Typography>
            </Box>
            <Box className={configureStyles?.siteTypeContainer}>
              <Box
                className={configureStyles?.siteTypeBox}
                onClick={() => {
                  setSiteType('other')
                  setIsLocationSet(true)
                  history.push(getConfigPlanURL())
                }}
              >
                <Box className={configureStyles?.optionBoxContent}>
                  <FontAwesomeIcon className={configureStyles?.optionBoxIcon} icon={faPeopleGroup} />
                  <Typography className={configureStyles?.optionBoxText}>Other</Typography>
                </Box>
              </Box>
              <Typography className={configureStyles?.optionBoxHelpText}>I am purchasing for something other than a traditional school or district</Typography>
            </Box>
          </Box>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => {
            setIsOutsideUS(null)
            history.goBack()
          }}
        >
          Back
        </Button>
      </Box>

  )
}

export default OrderSelectOrgTypePage;