import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, makeStyles, Typography} from "@material-ui/core"
import classNames from "classnames";
import React, {useMemo} from "react";
import {IProgressReportCourse, IProgressReportOrderedBadge, IProgressReportOrderedMission} from "./reportUtilities";
import useCurrentUser from "../../loaders/useCurrentUser";

import {missionColorMap} from "../courses/components/defaultMissionConfigurations";
import {ProgressSegment} from "../students/shared/ProgressSegment";

interface CourseProgressBarProps {
  course: IProgressReportCourse;
  studentIndex: number;
  displayState: string;
}

const chevronSize = 5;

const useMissionSegmentStyle = makeStyles(theme => ({
  outerSegment: {
    borderRadius: '13px',
  },
  innerSegment: {
    borderRadius: '12px',
  },

  chevronSolo: {
    clipPath: `none`,
  },
  chevronLeft: {
    clipPath: `polygon(calc(100% - ${chevronSize}px) 0%, 100% 50%, calc(100% - ${chevronSize}px) 100%, 0% 100%, 0% 50%, 0% 0%)`,
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px',
  },
  chevronMid: {
    clipPath: `polygon(calc(100% - ${chevronSize}px)0%, 100% 50%, calc(100% - ${chevronSize}px) 100%, 0% 100%, ${chevronSize}px 50%, 0% 0%)`,
    borderRadius: '0px',
  },
  chevronRight: {
    clipPath: `polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, ${chevronSize}px 50%, 0% 0%)`,
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
  fill: {
    borderRadius: 0,
    borderRight: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none'
    }
  },
}));

const CourseProgressBar: React.FC<CourseProgressBarProps> = ({studentIndex, displayState, course}) => {
  const {currentUser} = useCurrentUser();
  const missions = course.orderedMissions;

  const filteredMissions = useMemo(() => {
    if (displayState === "all") {
      return missions;
    } else {
      return missions?.filter(mission => {
        // todo: filter based on omissions
        return !mission.premium || currentUser.plan === "School";
      });
    }
  }, [missions, displayState])

  function segmentType(index: number) {
    if (!filteredMissions || filteredMissions.length === 1) {
      return ProgressSegment.SOLO;
    } else if (index === 0) {
      return ProgressSegment.LEFT;
    } else if (index === filteredMissions.length - 1) {
      return ProgressSegment.RIGHT;
    } else {
      return ProgressSegment.MID;
    }
  }


  return (
    <Box
      height={32} width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      flexDirection="row"
    >

      {missions && missions.length > 0 ? (filteredMissions?.map((mission, index) => {
        let completedLessons = 0;
        let progress = 0;
        if (mission.orderedLessons) {
          mission.orderedLessons.forEach(lesson => {
            let completedLevels = 0;
            lesson.orderedLevels.forEach(level => {
              if (level.orderedProgress.length > studentIndex && level.orderedProgress[studentIndex].stars > 0) {
                completedLevels++;
              }
            })
            completedLessons = completedLessons + completedLevels / lesson.orderedLevels.length;
          })
          progress = completedLessons / mission.orderedLessons.length;
        }
        return <MissionSegment missionType={mission.missionType} progress={progress} segmentType={segmentType(index)}
                               key={index} />
      })) : (course.orderedBadges?.filter(badge => badge.size === 'major').map((badge, index) => {
        return <React.Fragment key={index}>
          {badge.tiers.map((tier, index) => {
            const earned = tier.earned[studentIndex];
            const progress = tier.progress[studentIndex];
            const units = badge.unit ? (progress > 1 && badge.pluralUnit ? badge.pluralUnit : badge.unit) : "";
            return <BadgeSegment missionType={index.toString()} earned={earned} progress={progress} units={units}
                                 segmentType={segmentType(index)} width={100} key={index}/>
          })}
        </React.Fragment>
      }))}
    </Box>
  )
}

const MissionSegment: React.FC<{
  missionType: string,
  progress: number,
  segmentType: ProgressSegment
}> = ({missionType, progress, segmentType}) => {
  const progressColor = missionColorMap[missionType];
  const borderColor = progress === 1 ? progressColor : '#979797'
  const missionSegmentStyle = useMissionSegmentStyle();

  const chevronStyle = (segmentType: ProgressSegment) => {
    switch (segmentType) {
      default:
      case ProgressSegment.SOLO:
        return missionSegmentStyle.chevronSolo;
      case ProgressSegment.LEFT:
        return missionSegmentStyle.chevronLeft;
      case ProgressSegment.MID:
        return missionSegmentStyle.chevronMid;
      case ProgressSegment.RIGHT:
        return missionSegmentStyle.chevronRight;
    }
  }
  return <>
    <Box
      style={{backgroundColor: borderColor}}
      width="100px"
      height="100%"
      display="flex"
      position="relative"
      className={classNames({[missionSegmentStyle.outerSegment]: true, [chevronStyle(segmentType)]: true})}
    >
      <Box
        width="100px"
        height="100%"
        display="flex"
        padding="1px"
      >
        <Box
          borderColor={borderColor}
          style={{
            backgroundColor: 'rgb(250, 250, 250)',
          }}
          color="white"
          width="100%"
          height="100%"
          justifySelf="center"
          className={classNames({[missionSegmentStyle.innerSegment]: true, [chevronStyle(segmentType)]: true})}
        >
        </Box>
      </Box>
      {progress > 0 && <Box
        height="100%"
        display="flex"
        justifyContent="center"
        width={`max(${progress * 100}%,15%)`}
        style={{backgroundColor: progressColor}}
        position="absolute"
        justifySelf="left"
        zIndex={1}
        className={classNames({
          [missionSegmentStyle.outerSegment]: true,
          [chevronStyle(segmentType)]: true,
          'barber_pole': progress < 1
        })}>
        {progress === 1 && (<FontAwesomeIcon icon={faCheck} style={{color: 'white', height: '32', width: 'auto'}}/>)}
      </Box>}
    </Box>
  </>
}


export const CourseBadgeProgressBar: React.FC<{ mission: IProgressReportOrderedMission, studentIndex: number, width: number }> = ({mission, studentIndex, width}) => {
    function segmentType(index: number) {
        if (index === 0) {
            return ProgressSegment.LEFT;
        } else if (index === mission.orderedLessons!.length - 1) {
            return ProgressSegment.RIGHT;
        } else {
            return ProgressSegment.MID;
        }
    }


    return (
        <Box
            height={32} width={width}
            display="flex"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            flexDirection="row"
        >

            {(mission.orderedLessons!.map((lesson, index) => {
                const levels = lesson.orderedLevels;
                let completedLevels = 0;
                levels.forEach(level => {
                    if (level.orderedProgress.length > studentIndex && level.orderedProgress[studentIndex].stars > 0) {
                        completedLevels++;
                    }
                })
                const progress = completedLevels / levels.length;
                const earned = completedLevels === levels.length;
                return <BadgeSegment key={index} earned={earned} missionType="0" progress={progress} units=""
                                     segmentType={segmentType(index)} width={95}/>
            }))
            }
        </Box>
    )
}

export const BadgeProgressBar: React.FC<{ badge: IProgressReportOrderedBadge, studentIndex: number }> = ({badge, studentIndex}) => {
  function segmentType(index: number) {
    if (!badge.tiers || badge.tiers.length === 1) {
      return ProgressSegment.SOLO;
    } else if (index === 0) {
      return ProgressSegment.LEFT;
    } else if (index === badge.tiers.length - 1) {
      return ProgressSegment.RIGHT;
    } else {
      return ProgressSegment.MID;
    }
  }


  return (
    <Box
      height={32} width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      flexDirection="row"
    >

      {(badge.tiers.map((tier, index) => {
        const progress = tier.progress[studentIndex];
        const earned = tier.earned[studentIndex];
        const units = badge.unit ? (progress > 1 && badge.pluralUnit ? badge.pluralUnit : badge.unit) : "";
        return <BadgeSegment key={index} earned={earned} missionType={index.toString()} progress={progress} units={units}
                             segmentType={segmentType(index)} width={95}/>
      }))
      }
    </Box>
  )
}


export const BadgeSegment: React.FC<{
  missionType: string,
  progress: number,
  earned: boolean,
  units: string,
  segmentType: ProgressSegment,
  width: number
}> = ({missionType, earned, progress, units, segmentType, width}) => {
  const progressColor = missionColorMap[missionType];
  const borderColor = earned ? progressColor : '#979797'
  const missionSegmentStyle = useMissionSegmentStyle();

  const chevronStyle = (segmentType: ProgressSegment) => {
    switch (segmentType) {
      default:
      case ProgressSegment.SOLO:
        return missionSegmentStyle.chevronSolo;
      case ProgressSegment.LEFT:
        return missionSegmentStyle.chevronLeft;
      case ProgressSegment.MID:
        return missionSegmentStyle.chevronMid;
      case ProgressSegment.RIGHT:
        return missionSegmentStyle.chevronRight;
    }
  }
  return <>
    <Box
      style={{backgroundColor: borderColor}}
      width={width}
      height="100%"
      display="flex"
      position="relative"
      className={classNames({[missionSegmentStyle.outerSegment]: true, [chevronStyle(segmentType)]: true})}
    >
      <Box
        width={width}
        height="100%"
        display="flex"
        padding="1px"
      >
        <Box
          borderColor={borderColor}
          style={{
            backgroundColor: 'rgb(250, 250, 250)',
          }}
          color="white"
          width="100%"
          height="100%"
          justifySelf="center"
          className={classNames({[missionSegmentStyle.innerSegment]: true, [chevronStyle(segmentType)]: true})}
        >
        </Box>
      </Box>
      {earned &&
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          width="100%"
          style={{backgroundColor: progressColor}}
          position="absolute"
          justifySelf="left"
          zIndex={1}
          pt={units !== "" ? "3px" : "0px"}
          className={classNames({[missionSegmentStyle.outerSegment]: true, [chevronStyle(segmentType)]: true})}>
          {units !== "" && !earned ? (
            <Typography style={{color: "white"}}>{progress} {units}</Typography>
          ) : (
            <>{earned && <FontAwesomeIcon icon={faCheck} style={{color: 'white', height: '26', width: 'auto'}}/>}</>
          )}
        </Box>
      }
    </Box>
  </>
}

export default CourseProgressBar;