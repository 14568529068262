import React from "react";
import {IKlass} from "../../types/IKlass";
import Button from "../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export const AddStudentsButton: React.FC<{
  klass: IKlass | undefined,
  onAddStudents: (klassId?: number) => void
}> = ({klass, onAddStudents}) => {
  return <Button
    startIcon={<FontAwesomeIcon icon={faPlus}/>}
    variant="contained"
    color="primary"
    disableElevation={false}
    onClick={() => onAddStudents(klass?.id)}
  >
    Add Students
  </Button>
}