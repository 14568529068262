import {Box, Divider, InputLabel, Typography, useTheme} from "@material-ui/core";
import Button from "../../../../../components/ui/buttons/Button";
import {Autocomplete, AutocompleteRenderOptionState, createFilterOptions} from "@material-ui/lab";
import {ISimpleSchool} from "../../../../../types/IDistrict";
import {justFetch} from "../../../../../mutations/mutate";
import endpoints from "../../../../../endpoints";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheck, faExclamation} from "@fortawesome/free-solid-svg-icons";
import TextField from "../../../../../components/ui/TextField";
import React, {useEffect, useState} from "react";
import {ISchoolSearchResultItem} from "../../../../settings/teacher/ISchoolSearchResultItem";
import useDialogState from "../../../../../hooks/useDialogState";
import AddSchoolDialog from "./AddSchoolDialog";
import {LinkedSchool} from "../OrderRequestReviewPage";
import SearchSchoolDialog from "./SearchSchoolDialog";

const schoolFilter = createFilterOptions<ISimpleSchool>();

export type SchoolInfoType = {
  id: number;
  nces_id: null | string;
  nces_school_id: null | string;
  state_school_id: null | string;
  school_name: string;
  school_phone: string;
  school_mailing_address: string;
  school_mailing_city: string;
  school_mailing_state: string;
  school_mailing_zip: number;
  school_mailing_zip4: null | string;
  school_location_address: string;
  school_location_city: string;
  school_location_state: string;
  school_location_zip: number;
  school_location_zip4: null | string;
  school_nces_code: null | string;
  school_nces_status: null | string;
  nces_locale_code: null | string;
  school_latitude: null | number;
  school_longitude: null | number;
  school_ansi_county_num: null | string;
  school_county_name: null | string;
  school_low_grade: number;
  school_high_grade: number;
  school_grade_level: null | string;
  is_charter: null | boolean;
  is_public: boolean;
  created_at: string;
  updated_at: string;
  latitude: null | number;
  longitude: null | number;
  ll_point: null | string;
  district_id: null | string;
};

const SiteToSchoolBox: React.VFC<{schoolID: number | undefined, zipCode: string | undefined, siteName: string, siteNum: number, onSelectedSchool: (index: number, schoolInfo: LinkedSchool) => void}> = ({schoolID, zipCode, siteName, siteNum, onSelectedSchool}) => {
  const [schools, setSchools] = useState<ISchoolSearchResultItem[]>([])
  const [loadingSchools, setLoadingSchools] = useState<boolean>(true);
  const [error, setError] = useState<string>('')
  const [selectedSchool, setSelectedSchool] = useState<ISimpleSchool>({
    school_name: `Please select the school to link`,
    id: 0,
    city: '',
    state: '',
    address: ''
  })
  const theme = useTheme()
  const searchSchoolDialogState = useDialogState();

  useEffect(() => {
    const fetchSchools = async () => {
      setLoadingSchools(true)
      //const endpoint = 
      const endpoint = zipCode? endpoints.schoolSearchByZip(zipCode as string) : endpoints.godmode.schools(1, '');

      justFetch(endpoint, 'GET')
        .then(async (res) => {
          if (res.ok) {
            const data = await res.json()
            setSchools(data)
            setLoadingSchools(false)
            return
          }
          setError('Could not find any schools with the provided location information')
          setLoadingSchools(false)
        })
        .catch((error) => {
          setLoadingSchools(false)
          setError('Could not load schools')
        })

    }
    fetchSchools()
  }, [zipCode])

  // automatically select the school from order request if only one site
  useEffect(() => {
    
    if (!(selectedSchool?.id !== 0)) {
      var school = schools.find(({ id }) => id === schoolID);
      if (school){
        setSelectedSchool({
          school_name: school?.school_name,
          id: school?.id,
          city: school?.school_location_city,
          state: school?.school_location_state,
          address: school?.school_location_address
        });
        onSelectedSchool(siteNum-1, {id: school.id, name: school.school_name})
      }
    }
  }, [schools, schoolID])

  const updateSchoolInfo = (schoolInfo: SchoolInfoType, nonTraditionalSchoolName?: string) => {
    // todo: maybe allow setting nonTraditionalSchool name in order approval, if for some reason it hasn't been set before?
    setSelectedSchool({
      school_name: schoolInfo?.school_name,
      id: schoolInfo?.id,
      city: schoolInfo?.school_location_city,
      state: schoolInfo?.school_location_state,
      address: schoolInfo?.school_location_address
    })
    onSelectedSchool(siteNum-1, {id: schoolInfo?.id, name: schoolInfo?.school_name})
  }

  return (
    <Box display={'flex'} flexDirection={'column'}
         style={{
      width: 350,
      height: 340,
      padding: 10,
      borderWidth: 3,
      borderColor: selectedSchool?.id !== 0 ? theme.palette.green.main : theme.palette.red.main,
      borderRadius: 8
    }}>
      <SearchSchoolDialog 
        open={searchSchoolDialogState.open} 
        onClose={searchSchoolDialogState.onClose} 
        searchOptions={{zipcode: zipCode, state: selectedSchool.state, city: selectedSchool.city}} 
        onSuccess={updateSchoolInfo}/>
      
      <Box display={'flex'} flexDirection={'row'}>
        <FontAwesomeIcon icon={selectedSchool?.id !== 0 ? faCheck : faExclamation} size={'2x'} color={selectedSchool?.id ? theme.palette.green.main : theme.palette.red.main} />
        <Typography variant={'h5'} style={{width: '100%', textAlign: 'center', marginLeft: -15}}>Site #{siteNum}</Typography>
      </Box>
      <Divider/>
      <Box>
        <Typography variant={'subtitle1'}>User provided school:</Typography>
        <Typography variant={'subtitle2'}>{siteName}</Typography>
      </Box>
      <Box marginTop={2}>
        <InputLabel htmlFor="school_id" style={{color: '#000', fontWeight: 600, marginBottom: 5}}>School to Link</InputLabel>
        
        <Box
          border={1}
          borderColor="grey.500"
          borderRadius={4}
          p={1}
          display="flex"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Typography variant="body1">
            {selectedSchool?.school_name || 'No school selected - Non-traditional'}
          </Typography>
        </Box>
        {(selectedSchool.id !== 0) && <Box marginTop={2}>
            <Typography variant={'subtitle1'}>Address:</Typography>
            {(selectedSchool?.address || selectedSchool?.city || selectedSchool?.state)? <>
              <Typography>{selectedSchool?.address}</Typography>
              <Typography>{selectedSchool?.city}, {selectedSchool?.state}</Typography></>: <>
              <Typography>N/A</Typography>
              <Typography>{"\u00A0"}</Typography></>
              }
          </Box>
        }
        <Box p={2} display={'flex'} justifyContent={'center'}>
          {selectedSchool.id == 0 && <Button color={'primary'}
                variant={'contained'}
                onClick={ ()=> {searchSchoolDialogState.handleOpen()}}
            >Select School</Button>
          }

          {selectedSchool.id !== 0 && <Button color={'primary'}
                variant={'contained'}
                onClick={()=>{searchSchoolDialogState.handleOpen()}}
            >Change School</Button>
          }
        </Box>      
      </Box>
    </Box>
  )
}

export default SiteToSchoolBox