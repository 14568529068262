import {makeStyles, TooltipProps} from "@material-ui/core";
import {Tooltip as MUITooltip} from "@material-ui/core";

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.blue.main,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderStyle: "solid",
    borderColor: theme.palette.blue.main,
    borderWidth: 2,
    fontSize: 15,
    padding: 10,

  },
}));

const Tooltip = (props: TooltipProps) => {
  const classes = useStylesTooltip();

  return <MUITooltip classes={classes} {...props}>{props.children}</MUITooltip>
}

export default Tooltip