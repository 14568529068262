import {
  faCaretDown,
  faEdit,
  faExclamationTriangle,
  faLock,
  faPaperPlane,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useDialogState from "hooks/useDialogState";
import useCurrentUser from "loaders/useCurrentUser";
import { justFetch } from "mutations/mutate";
import EditPasswordDialog from "components/dialogs/settings/EditPasswordDialog";
import EditProfileDialog from "components/dialogs/settings/EditProfileDialog";
import React, {useEffect, useMemo} from "react";
import { useRef, useState } from "react";
import useSWR from "swr";
import { ITeacher } from "types/ITeacher";
import { ITeacherData } from "types/ITeacherData";
import * as Yup from 'yup';


export interface IFailedTeacher {
  username: string;
  fail_reason: string;
}

export interface ILinkTeacherResponse {
  linked_teachers: ITeacher[],
  failed_teachers: IFailedTeacher[],
  students_over_limit: boolean,
  total_students_count_for_license: number,
  license_student_limit: number,
}

const TeachersTable: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { data: teacherData, mutate } = useSWR<ITeacherData>(endpoints.teacherInit);
  const sharedClasses = useSharedStyles();
  const inviteTeachersDialog = useDialogState();
  const [teacherBeingEdited, setTeacherBeingEdited] = useState<ITeacher>();
  const [teacherChangingPassword, setTeacherChangingPassword] = useState<ITeacher>();
  const [schoolName, setSchoolName] = useState<string>('');

  const alert = useAlert();

  const [inviteTeachersError, setInviteTeachersError] = useState(false);
  const [inviteTeachersErrorMessage, setInviteTeachersErrorMessage] = useState<string[]>();

  const inviteTeachersForm = useFormik({
    validateOnChange: false,
    initialValues: {
      license_id: 0,
      teacher_emails: ''
    },
    validate: async values => {
      let errorMessage;
      const emails = values.teacher_emails.split('\n').map(email => email.trim()).filter(Boolean);

      if (emails.length === 0) {
        errorMessage = 'Enter the email addresses of teachers you would like to invite'
      }

      for (let i = 0; i < emails.length; i++) {
        try {
          await Yup.string().email().validate(emails[i])
        } catch (e) {
          errorMessage = `${emails[i]} is not a valid email address`;
          break;
        }
      }

      return errorMessage && {
        teacher_emails: errorMessage
      }
    },
    onSubmit: values => {
      return justFetch(endpoints.linkTeachers, 'POST', {
        license_id: values.license_id,
        teacher_emails: values.teacher_emails.split('\n').map(email => email.trim()).filter(Boolean)
      })
        .then(async res => {
          if (!res.ok) {
            throw new Error();
          }

          mutate();

          const response : ILinkTeacherResponse = await res.json();
          const linkedTeachers = response.linked_teachers;
          const failedTeachers = response.failed_teachers;

          let message : string[] = [];

          if (linkedTeachers.length > 0) {
            message.push('Teachers linked successfully:');
            linkedTeachers.map(teacher => {
              message.push(teacher.username);
            });
          }

          if (linkedTeachers.length > 0 && failedTeachers.length > 0) {
            message.push(''); // space between sections
          }

          if (failedTeachers.length > 0) {
            message.push('Teachers not linked:');
            failedTeachers.map(teacher => {
              message.push(`${teacher.username} ${teacher.fail_reason}`);
            });
          }

          if (response.students_over_limit)
          {
            message.push(''); // space between sections
            message.push(`Student count of ${response.total_students_count_for_license} is over the limit of ${response.license_student_limit}! `);
            message.push(`Paid features will be disabled until the student count is within limit. `);
            message.push(`Contact your administrator or support@kodable.com.`);
          }

          if (failedTeachers.length === 0 && !response.students_over_limit)
          {
            inviteTeachersDialog.onClose();
            setInviteTeachersErrorMessage(undefined);
          }
          else
          {
            setInviteTeachersError(true);
            setInviteTeachersErrorMessage(message);
          }

          alert.success(message.join("\n"));
        })
        .catch((e) => {
          setInviteTeachersError(true);
          alert.error('Teachers invite failed!')
          setInviteTeachersErrorMessage(e?.message || 'An unexpected error occurred.');
        });
    }
  });

  useEffect(() => {
    if (inviteTeachersDialog.open) {
      inviteTeachersForm.resetForm();
      setInviteTeachersError(false);
      setInviteTeachersErrorMessage(undefined);
      inviteTeachersForm.setFieldValue('license_id', assignedLicenses().length == 1? assignedLicenses()[0].id : 0)
      setSchoolName(assignedLicenses().length == 1? assignedLicenses()[0].site_name : '');
    }
  }, [inviteTeachersDialog.open]);

  const teacherRowData = useMemo(() => {
    return teacherData?.teachers
      .map(teacher => {
        const adminToThisTeacher = teacherData?.teachers
            .find((searchTeacher) => searchTeacher.id === teacher.administrator_id);

        return {
          ...teacher,
          admin: adminToThisTeacher
        }
      })
      .filter(teacher => currentUser.id !== teacher.id) || [];
  }, [teacherData]);

  const [unlinking, setUnlinking] = useState(false);
  const [unlinkError, setUnlinkError] = useState<string>();
  const [teacherUnlinking, setTeacherUnlinking] = useState<ITeacher>();

  useEffect(() => {
    if (!teacherUnlinking) {
      setUnlinking(false);
      setUnlinkError(undefined);
    }
  }, [teacherUnlinking]);

  useEffect(()=>
  {
    if (assignedLicenses().length == 1)
    {
      inviteTeachersForm.setFieldValue('license_id', assignedLicenses()[0].id)
      setSchoolName(assignedLicenses()[0].site_name || '');
    }
  }, [teacherData])

  const unlinkTeacher = () => {
    if (!teacherUnlinking) {
      return;
    }
    setUnlinking(true);
    setUnlinkError(undefined);

    justFetch(endpoints.unlinkTeacher(teacherUnlinking.id), 'POST')
      .then(res => {
        setUnlinking(false);
        if (res.ok) {
          mutate();
          alert.success('Teacher unlinked');
          setTeacherUnlinking(undefined);
        } else {
          res.json().then(body => setUnlinkError(body?.message || body?.error || 'An unknown error occurred'));
        }
      })
      .catch(() => { setUnlinking(false); setUnlinkError('An unknown error occurred') })
  }

  if (!teacherData) {
    return <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  const licenses = teacherData?.subscription.licenses;
  const assignedLicenses = () => {
    const result = licenses?.filter(license => license.teacher_id);
    return result? result : [];
  };

  const showSiteSelection = assignedLicenses().length > 1;

  return <>
    <Dialog {...inviteTeachersDialog} fullWidth>
      <LinearProgress style={{ visibility: inviteTeachersForm.isSubmitting ? 'visible' : 'hidden' }}></LinearProgress>
      <DialogTitle>Link Teacher Accounts</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>

        {showSiteSelection && <>
        <Typography>Choose the school to which you want to link teachers (school must have an administrator assigned):</Typography>

        <Select
          label="Site"
          id="license_id"
          onChange={(e) => {
            inviteTeachersForm.setFieldValue('license_id', e.target.value);
            const license = licenses?.find(license => license.id == e.target.value);
            setSchoolName(license?.site_name || '');
          }}
          value={inviteTeachersForm.values.license_id}
          fullWidth
          displayEmpty
          style={{
            background: 'white'
          }}
        >
          <MenuItem disabled value="0">
            <em>{assignedLicenses().length > 0? "Select School" : "No available schools. You must assign school administrators first"}</em>
          </MenuItem>
          {assignedLicenses()
            .map(license => <MenuItem
              key={license.id}
              value={license.id}
            >
              {license.site_name}
            </MenuItem>)
          }
        </Select>
        <br />
        <br />
        </>
        }
        <Typography>Enter the email addresses of teachers you would like to link{schoolName?.length>0? ' to ' : ''} <b>{schoolName}</b>:</Typography>

        <TextField
          multiline
          minRows={8}
          name="teacher_emails"
          id="teacher_emails"
          value={inviteTeachersForm.values.teacher_emails}
          onChange={inviteTeachersForm.handleChange}
          placeholder="Enter one email per line"
          error={!!inviteTeachersForm.errors.teacher_emails}
          helperText={inviteTeachersForm.errors.teacher_emails}
          disabled={inviteTeachersForm.isSubmitting}
        />
        {inviteTeachersError && <Alert severity="error">{inviteTeachersErrorMessage?.map((line, index)=> (<span key={index}>{line}<br /></span>))}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={inviteTeachersDialog.onClose}
          disabled={inviteTeachersForm.isSubmitting}
        >Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => inviteTeachersForm.handleSubmit()}
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          disabled={inviteTeachersForm.isSubmitting || !inviteTeachersForm.values.license_id || !inviteTeachersForm.values.teacher_emails.length}
        >
          Link
        </Button>
      </DialogActions>
    </Dialog>
    <EditProfileDialog
      open={!!teacherBeingEdited}
      onClose={() => setTeacherBeingEdited(undefined)}
      teacher={teacherBeingEdited}
    />
    <EditPasswordDialog
      open={!!teacherChangingPassword}
      onClose={() => setTeacherChangingPassword(undefined)}
      teacher={teacherChangingPassword}
    />
    <Dialog open={!!teacherUnlinking}>
    <LinearProgress style={{ visibility: unlinking ? 'visible' : 'hidden' }}></LinearProgress>
      <DialogTitle>Remove {teacherUnlinking?.name || teacherUnlinking?.username} from your subscription?</DialogTitle>
      <DialogContent>
        They will no longer have access to your subscription. You will not be able to view their classes and students.

        {unlinkError && <Alert severity="error">{unlinkError}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setTeacherUnlinking(undefined)}
          disabled={unlinking}
        >Cancel</Button>
        <Button
          color="orange"
          variant="contained"
          onClick={unlinkTeacher}
          startIcon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          disabled={unlinking}
        >
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
    <Box pb={2} display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
      <Button
        color="primary"
        variant="contained"
        onClick={inviteTeachersDialog.handleOpen}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Link Teachers
      </Button>
    </Box>
    <TableContainer component={Paper} {...{ variant: 'outlined' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              School
            </TableCell>
            <TableCell>
              Admin Name
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teacherRowData
              .map(teacher => <TeacherRow
            key={teacher.id}
            teacher={teacher}
            onEdit={setTeacherBeingEdited}
            onChangePassword={setTeacherChangingPassword}
            onUnlink={setTeacherUnlinking}
          />)}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

interface TeacherRowProps {
  teacher: ITeacher & {
    admin?: ITeacher;
  };
  onEdit: (teacher: ITeacher) => void;
  onChangePassword: (teacher: ITeacher) => void;
  onUnlink: (teacher: ITeacher) => void;
}

const TeacherRow: React.VFC<TeacherRowProps> = ({ teacher, onEdit, onChangePassword, onUnlink }) => {
  const sharedClasses = useSharedStyles();
  const actionsButtonRef = useRef(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false);

  return (<>
    <Menu
      anchorEl={actionsButtonRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={actionsMenuOpen}
      onClose={() => setActionsMenuOpen(false)}
    >
      <MenuItem onClick={() => { setActionsMenuOpen(false); onEdit(teacher); }}><FontAwesomeIcon icon={faEdit} />&nbsp;Edit</MenuItem>
      <MenuItem onClick={() => { setActionsMenuOpen(false); onChangePassword(teacher); }}><FontAwesomeIcon icon={faLock} />&nbsp;Change Password</MenuItem>
      {!teacher?.user_role?.includes("admin") &&
      <MenuItem onClick={() => { 
        setActionsMenuOpen(false); 
        onUnlink(teacher); 
      }}><FontAwesomeIcon icon={faTrash} />&nbsp;Unlink</MenuItem>
      }
    </Menu>
    <TableRow
      className={actionsMenuOpen ? sharedClasses.tableRowHoverButtonOpenMenu : sharedClasses.tableRowHoverButton}
      key={teacher.id}
      onClick={() => setActionsMenuOpen(true)}
    >
      <TableCell>{teacher.name}</TableCell>
      <TableCell>{teacher.school?.school_name || teacher.school_name || 'None'}</TableCell>
      <TableCell>{teacher.admin?.name}</TableCell>
      <TableCell style={{ width: 0 }}>
        <Button
          onClick={e => { e.stopPropagation(); setActionsMenuOpen(true); }}
          ref={actionsButtonRef}
        >
          Actions&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown} />
        </Button>
      </TableCell>
    </TableRow>
  </>)
}

export default TeachersTable;