import {Route, useHistory, useLocation} from "react-router-dom";
import {routes} from "routes";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  Link,
  Paper,
  Typography
} from "@material-ui/core";
import {TeacherOnboardingAddSchoolResults} from "./add-school/TeacherOnboardingAddSchoolResults";
import {TeacherOnboardingAddSchoolSearch} from "./add-school/TeacherOnboardingAddSchoolSearch";
import React, {useEffect, useMemo, useState} from "react";
import Button from "components/ui/buttons/Button";
import {AddClassManualName} from "../classes/add-class-manual/AddClassManualName";
import {AddClassManualStudents} from "../classes/add-class-manual/AddClassManualStudents";
import {FormikProps, useFormik} from "formik";
import IAddNewClassRequest from "types/IAddNewClassRequest";
import useCurrentUser, {mutate as mutateCurrentUser} from "loaders/useCurrentUser";
import * as Yup from 'yup';
import {justFetch} from "mutations/mutate";
import {mutate} from "swr";
import endpoints from "endpoints";
import {IKlass} from "types/IKlass";
import {useTracking} from "context/TrackingProvider";
import {motion} from "framer-motion";
import useTeacherInit from "loaders/useTeacherInit";
import {AddClassCleverImport} from "./add-class-clever/AddClassCleverImport";
import {FuzzBuzz} from "./FuzzBuzz";
import {Alert} from "@material-ui/lab";
import {TeacherOnboardingSelectGameAssignments} from "./TeacherOnboardingSelectGameAssignments";
import grades from "../../types/Grades";
import {GameTitle} from "../../enums/GameTitle";

export const teacherOnboardingContext = React.createContext<{ addNewClassForm: FormikProps<IAddNewClassRequest>, setDidImportCleverClasses: React.Dispatch<React.SetStateAction<boolean>> }>(null!)

export const TeacherOnboardingIndex: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const { track } = useTracking();
  const [error, setError] = useState<string>();
  const { teacherData } = useTeacherInit();
  const [didImportCleverClasses, setDidImportCleverClasses] = useState(false)
  const [disableContinueButton, setDisableContinueButton] = useState(false)

  const fuzzBuzzSay = useMemo(() => {
    switch (pathname) {
      case routes.teacherOnboarding.addSchool.index:
      case routes.teacherOnboarding.addSchool.results:
        return "I'll connect you with other teachers that use Kodable!"
      case routes.teacherOnboarding.addClassManual.index:
        return "Name your class something fun your kids will recognize!";
      case routes.teacherOnboarding.addClassManual.students:
        return "Each student will get their own separate Kodable profile";
      case routes.teacherOnboarding.addClassClever.index:
        return "How clever! Choose the classes to use with Kodable."
    }
  }, [pathname]);

  const pageTitle = useMemo(() => {
    switch (pathname) {
      case routes.teacherOnboarding.addSchool.index:
      case routes.teacherOnboarding.addSchool.results:
        return "Find Your School"
      case routes.teacherOnboarding.addClassManual.index:
        return (teacherData?.klasses.length || 0) > 0 ? "Name Your Class" : "Name Your First Class";
      case routes.teacherOnboarding.addClassManual.students:
        return "Add Your Students"
      case routes.teacherOnboarding.assignGames.index:
        return "Our recommended apps for your class"
      case routes.teacherOnboarding.addClassClever.index:
        return "Select Clever Classes"
    }
  }, [teacherData, pathname]);

  const continueButton = useMemo(() => {
    switch (pathname) {
      case routes.teacherOnboarding.addClassManual.index:
        return routes.teacherOnboarding.addClassManual.students;
      case routes.teacherOnboarding.addClassManual.students:
        return routes.teacherOnboarding.assignGames.index
      case routes.teacherOnboarding.addClassClever.index:
        return didImportCleverClasses ? routes.classes.index : undefined;
    }
  }, [pathname, teacherData, didImportCleverClasses]);

  const addNewClassForm: FormikProps<IAddNewClassRequest> = useFormik<IAddNewClassRequest>({
    validateOnChange: true,
    isInitialValid: false,
    initialValues: {
      grade: 'default',
      klass_code: undefined,
      klass_name: '',
      password_enabled: false,
      students: [],
      teacher_id: currentUser.id,
      assigned_games: [GameTitle.BASICS, GameTitle.CREATOR]
    },
    onSubmit: async values => {
      const res = await justFetch(endpoints.classes, 'POST', values);

      if (res.ok) {
        const createdClass = await res.json() as IKlass;
        mutate(endpoints.classes);
        mutate(endpoints.teacherInit);

        track('Created Class', {
          'Upload Source': 'Wizard',
          'Grade': values.grade,
          'Number of Students': values.students.length
        });
        history.push(routes.class.index(createdClass.id));
      } else {
        res.json().then(body => setError(body.message || body.error || 'An unknown error occurred.'))
      }
    },
    validationSchema: Yup.object({
      klass_name: Yup.string().required('Enter a name for your class.'),
      grade: Yup.string().oneOf(Object.keys(grades), 'Select the grade.').required()
    })
  });

  const teacherOnboardingContextValue = useMemo(() => {
    return {
      addNewClassForm,
      setDidImportCleverClasses
    }
  }, [addNewClassForm, setDidImportCleverClasses]);

  useEffect(() => {
    if (!addNewClassForm.values.klass_name && pathname === routes.teacherOnboarding.addClassManual.students) {
      history.push(routes.teacherOnboarding.addClassManual.index);
    }
  }, [pathname, addNewClassForm.values.klass_name]);


  const navigateToPathname = (pathname: string) => {
    history.push(pathname);
  }

  const showFrame = false;
  const showFuzzBuzz = (teacherData?.klasses.length || 0) === 0;
  
  return <teacherOnboardingContext.Provider value={teacherOnboardingContextValue}>
    <Dialog open={!!error}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>{error}</DialogContent>
      <DialogActions>
        <Box></Box>
        <Button variant="contained" onClick={() => setError(undefined)}>Close</Button>
      </DialogActions>
    </Dialog>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
      <Box maxHeight="600px" width="min(100vw, 1000px)" borderRadius={6} border={showFrame ? '4px dashed red' : undefined} display="flex" justifyContent="center">
        <Hidden mdUp><Box width="125px">&nbsp;</Box></Hidden>
        <Hidden smDown>
          <Box width="250px">
            {showFuzzBuzz && <FuzzBuzz say={fuzzBuzzSay} />}
          </Box>
        </Hidden>
        <Box display="flex" flexDirection="column" width={pageTitle === "Our recommended apps for your class" ? "900px" : "500px"}>
          <Box position="relative" display="flex" flexDirection="column" component={Paper} {...{ variant: 'outlined' }} p={4} flexGrow={1} width="100%">
            <Typography variant="h1" paragraph align="center" style={{fontFamily: "Bjola"}}>{pageTitle}</Typography>

            <Route
              component={TeacherOnboardingAddSchoolSearch}
              path={routes.teacherOnboarding.addSchool.index}
              exact
            />
            <Route
              component={TeacherOnboardingAddSchoolResults}
              path={routes.teacherOnboarding.addSchool.results}
            />
            <Route
              component={AddClassManualName}
              path={routes.teacherOnboarding.addClassManual.index}
              exact
            />
            <Route
              component={AddClassManualStudents}
              path={routes.teacherOnboarding.addClassManual.students}
            />
            <Route
              component={TeacherOnboardingSelectGameAssignments}
              path={routes.teacherOnboarding.assignGames.index}
            />
            <Route
              component={AddClassCleverImport}
              path={routes.teacherOnboarding.addClassClever.index}
            />
          </Box>
          {pathname !== routes.teacherOnboarding.addClassManual.students &&
            <Box py={2} height={100}></Box>
          }
        </Box>
        <Box width={{ xs: 125, md: 250 }} display="flex" alignItems="flex-end">
          {(continueButton && (pathname !== routes.teacherOnboarding.addClassManual.students)) && <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: [0, 1.2, 1] }}
            transition={{ duration: 0.33, times: [0, 0.66, 1] }}
          >
            <Button
              variant="contained"
              color="blue"
              size="large"
              disableElevation={false}
              disabled={
                disableContinueButton ||
                addNewClassForm.isSubmitting ||
                pathname === routes.teacherOnboarding.addClassManual.index && !addNewClassForm.isValid ||
                pathname === routes.teacherOnboarding.addClassManual.students && addNewClassForm.values.students.length === 0
              }
              style={{
                fontWeight: 'bold',
                margin: 16,
                marginBottom: 32,
              }}
              onClick={async () => {
                if (pathname === routes.teacherOnboarding.addClassManual.students) {
                  addNewClassForm.submitForm();
                } else {
                  if (teacherData?.klasses.length === 0 && pathname === routes.teacherOnboarding.addClassClever.index) {
                    setDisableContinueButton(true);
                    await mutate(endpoints.teacherInit, undefined, true);
                  }
                  await justFetch(endpoints.parent.onboarding.finalize(currentUser.id), 'PUT', {is_dashboard_onboarded: true})
                  mutateCurrentUser();
                  navigateToPathname(continueButton);
                }
              }}
            >Continue</Button>
          </motion.div>}
        </Box>
      </Box>
      <Box mt={2} display="flex" flexDirection="column" alignItems="center" style={{ visibility: pathname === routes.teacherOnboarding.addClassManual.students ? 'visible' : 'hidden' }}>
        <Box width="65%">
          {teacherData?.district?.no_pii &&
            <Alert severity="error">
              <>Due to your district's privacy restrictions, you may not use student names for your student profiles. Please use an anonymous identifier. <strong>This does not affect your student's experience with Kodable.</strong></>
            </Alert>
          }
          {!teacherData?.district?.no_pii &&
            <>
            <Typography variant="h2" align="center">
              We care about student safety!
            </Typography>
            <Typography variant="body2" align="center">
              Pursuant to COPPA regulations and our <Link href="https://www.kodable.com/privacy" target="_blank">Terms of Service</Link>, <span style={{ fontWeight: 'bold' }}>do not</span> use any Personally Identifiable Information when creating student profiles.
            </Typography>
            </>
          }
          </Box>
        </Box>
    </Box>
  </teacherOnboardingContext.Provider>
}