import { Box, Link, Typography } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import { Alert } from "@material-ui/lab";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { CLASS_STUDENT_LIMIT } from "components/dialogs/class/AddStudentsDialog";
import { useAddClassFormContext } from "context/AddClassFormProvider";
import { AddNewClassManualStep } from "./AddClassManualForm";
import {GameTitle} from "../../../enums/GameTitle";

export const AddClassManualFormStudents: React.VFC<{setStep: Dispatch<SetStateAction<AddNewClassManualStep>>}> = ({setStep}) => {
  const { addNewClassForm } = useAddClassFormContext()
  const sharedClasses = useSharedStyles();
  const [studentEntry, setStudentEntry] = useState<string>('');

  useEffect(() => {
    addNewClassForm.setFieldValue('students', studentEntry.split('\n').map(entry => entry.trim()).filter(Boolean));
  }, [studentEntry]);

  const showStudentLimitWarning = useMemo(() => {
    return studentEntry.split('\n').map(entry => entry.trim()).filter(Boolean).length > CLASS_STUDENT_LIMIT;
  }, [studentEntry]);

  return <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2}>
    <Typography variant="body2" style={{ fontStyle: 'italic' }}>Add one student per line or copy and paste.</Typography>
    <TextField
      multiline
      minRows={14}
      placeholder="Enter one student per line"
      value={studentEntry}
      onChange={e => setStudentEntry(e.target.value)}
      style={{
        background: '#fff'
      }}
      disabled={addNewClassForm.isSubmitting}
    />
    {(studentEntry.length < 1 && !addNewClassForm.isSubmitting) &&
      <Box display="flex" justifyContent="flex-end">
        <Link
          style={{
            cursor: 'pointer'
          }}
          onClick={() => {
            addNewClassForm.setFieldValue('assigned_games', [GameTitle.BASICS, GameTitle.CREATOR])
            addNewClassForm.submitForm()
          }}
        >
          Add students later
        </Link>
      </Box>
    }
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="contained"
        color="blue"
        size="large"
        disableElevation={false}
        disabled={
          addNewClassForm.isSubmitting ||
          addNewClassForm.values.students.length === 0 ||
          showStudentLimitWarning
        }
        onClick={() => {
          addNewClassForm.setFieldValue('assigned_games', [GameTitle.BASICS, GameTitle.CREATOR])
          addNewClassForm.submitForm()
        }}
      >
        Save
      </Button>
    </Box>
    {showStudentLimitWarning && <Alert severity="error">You cannot have more than 50 students in a class.</Alert>}
  </Box>
}