import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox, CircularProgress, Dialog, DialogActions,
  DialogContent,
  DialogTitle, LinearProgress,
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/ui/buttons/Button";
import { DialogStateProps } from "../../../hooks/useDialogState";
import useSWR from "swr";
import endpoints from "../../../endpoints";
import { justFetch } from "../../../mutations/mutate";
import Amplitude from "../../../utils/Amplitude";
import { ICleverClass } from "../../../types/ICleverClass";
import { IKlass } from "../../../types/IKlass";
import { cleverGradeToKodableGrade } from "../utils/cleverToKodableGrade";
import { CleverLoginButton } from "../../login/LoginInput";
import {useClever} from "../../../context/CleverProvider";
import useTeacherInit from "../../../loaders/useTeacherInit";

type SubmitStatusType = 'error' | 'submitting' | 'success';
type SubmitStatus = Record<string, SubmitStatusType>;

interface ManageKlassesDialogProps extends DialogStateProps {
  klasses: IKlass[];
}

const ManageKlassesDialog: React.FC<ManageKlassesDialogProps> = ({ open, onClose, klasses }) => {
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const {mutate: mutateInit} = useTeacherInit()
  const {useKlasses, useStudents} = useClever()
  const {mutate: mutateKlasses} = useKlasses();
  const {mutate: mutateStudents} = useStudents();
  const { data: cleverClasses, error: cleverClassesError } = useSWR<ICleverClass[]>(endpoints.cleverKlasses);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({});

  const handleToggleClass = (classId: string, selected: boolean) => {
    if (selected) {
      setSelectedClasses(prev => [...prev, classId]);
    } else {
      setSelectedClasses(prev => prev.filter(_classId => _classId !== classId));
    }
  };

  useEffect(() => {
    if (!klasses || !cleverClasses) {
      return;
    }
    const alreadyImported = klasses.filter(({ clever_id }) => !!clever_id) || [];
    setSelectedClasses(cleverClasses.filter(cleverClass =>
      alreadyImported.some(klass => klass.clever_id === cleverClass.data.id)
    ).map(({ data: { id } }) => id));
  }, [cleverClasses, klasses]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const initialStatus: SubmitStatus = {};
    cleverClasses?.forEach(({ data: { id } }) => {
      initialStatus[id] = 'submitting';
    });
    setSubmitStatus(initialStatus);

    try {
      const importPromises = selectedClasses.map(classId => handleImportClass(classId));
      const deletePromises = cleverClasses
        ?.filter(({ data: { id } }) => !selectedClasses.includes(id))
        .map(({ data: { id } }) => handleDeleteClass(id)) || [];

      const results = await Promise.allSettled([...importPromises, ...deletePromises]);

      const newSubmitStatus: SubmitStatus = { ...initialStatus };
      results.forEach((result, index) => {
        const classId = index < selectedClasses.length
          ? selectedClasses[index]
          : cleverClasses?.filter(({ data: { id } }) => !selectedClasses.includes(id))[index - selectedClasses.length].data.id;
        if (classId) {
          newSubmitStatus[classId] = result.status === 'fulfilled' ? 'success' : 'error';
        }
      });

      setSubmitStatus(newSubmitStatus);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
      mutateKlasses();
      mutateStudents();
      mutateInit();
      onClose();
    }
  };

  const handleImportClass = async (cleverClassId: string): Promise<void> => {
    try {
      const cleverClass = cleverClasses?.find(({ data: { id } }) => id === cleverClassId);
      if (!cleverClass) {
        throw new Error('Clever class not found');
      }

      const res = await justFetch(endpoints.cleverKlasses, 'POST', {
        clever_klasses: JSON.stringify([{
          clever_id: cleverClassId,
          grade: cleverGradeToKodableGrade(cleverClass.data.grade)
        }])
      });

      if (!res.ok) {
        throw new Error('Failed to import class');
      }

      Amplitude.track('Created Class', {
        'Number of Students': cleverClass.data.students.length,
        'Grade': cleverGradeToKodableGrade(cleverClass.data.grade),
        'Upload Source': 'Clever'
      });

      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'success' }));
    } catch (error) {
      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'error' }));
      throw error;
    }
  };

  const handleDeleteClass = async (cleverClassId: string): Promise<void> => {
    try {
      const res = await justFetch(endpoints.deleteCleverKlass(cleverClassId), 'DELETE');

      if (!res.ok) {
        throw new Error('Failed to delete class');
      }

      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'success' }));
    } catch (error) {
      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'error' }));
      throw error;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <LinearProgress style={{ visibility: isSubmitting ? 'visible' : 'hidden' }} />
      <DialogTitle>
        {!cleverClassesError? "Select classes to share with Kodable" : "Sign into Clever to Import Classes"}
      </DialogTitle>
      <DialogContent>
        {!cleverClassesError &&
          <Box component={Paper} {...{ variant: 'outlined' }} maxHeight="50vh" overflow="scroll">
            <Table stickyHeader={true} size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>Class</TableCell>
                </TableRow>
              </TableHead>
              {cleverClasses && cleverClasses.length > 0 && <TableBody>
                {cleverClasses?.filter(({ data: { id }}) => isSubmitting ? selectedClasses.includes(id) : true).map(({ data: { id, name } }) => {
                  const selected = selectedClasses.includes(id);
                  const status = submitStatus[id] || 'submitting';

                  return (
                    <TableRow key={id}>
                      <TableCell onClick={() => handleToggleClass(id, !selected)}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'left'} alignItems={'center'}>
                          {!isSubmitting && (
                            <Checkbox
                              checked={selected}
                              onChange={() => handleToggleClass(id, !selected)}
                            />
                          )}
                          {isSubmitting && (
                            <Box mr={2} width={32} height={40} alignContent={'center'}>
                              {status === 'submitting' && selected && <CircularProgress size={32} />}
                              {status === 'success' && selected && <FontAwesomeIcon size="2x" icon={faCheckCircle} color={'green'} />}
                              {status === 'error' && selected && <FontAwesomeIcon size="2x" icon={faExclamationCircle} color={'red'} />}
                            </Box>
                          )}
                          {name}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              }
            </Table>
          </Box>
        }
        {cleverClassesError &&
          <CleverLoginButton/>
        }
      </DialogContent>
      {!cleverClassesError &&
        <DialogActions>
          <Box display={'flex'} flexDirection={'row-reverse'} width={'100%'}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >Save</Button>
          </Box>
        </DialogActions>
      }
    </Dialog>
  )
}

export default ManageKlassesDialog