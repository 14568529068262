import {IProgressReportCourse, IProgressReportView, transformReportToCsv} from "./reportUtilities";
import {IStudentSchoolProfile} from "../../types/IStudentSchoolProfile";
import {GameTitle} from "../../enums/GameTitle";
import React, {Dispatch, SetStateAction} from "react";
import useSharedStyles from "../../components/useSharedStyles";
import {Box, Typography} from "@material-ui/core";
import Button from "../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {format} from "date-fns";
import {GameSelectDropdown} from "../../components/games/GameSelectDropdown";
import FileSaver from "file-saver";

interface IProgressHeaderProps{
    selectedCourseId?: string
    selectedStudentId?: string;
    selectedStudentIndex: number;
    onSelectCourse?: (selectedCourseId?: string) => void
    onSelectStudent?: (selectedStudentIndex?: string) => void
    onViewCourseDetails?: (courseId?: string) => void
    reportView: IProgressReportView | null,
    students: IStudentSchoolProfile[],
    selectedCourse?: IProgressReportCourse,
    displayState: string,
    setDisplayState: (displayState: string) => void
    selectedGame: GameTitle;
    setSelectedGame: Dispatch<SetStateAction<GameTitle>>;
    legacyView: boolean;
    setLegacyView: (legacyView: boolean) => void
}
export const ProgressHeader: React.VFC<IProgressHeaderProps> = ({ 
    selectedCourseId, selectedStudentId, selectedStudentIndex, reportView, onSelectCourse, displayState, setDisplayState, onViewCourseDetails, students, selectedGame, setSelectedGame, legacyView, setLegacyView 
}) => {
    const sharedClasses = useSharedStyles();
    
    return (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%" className={sharedClasses.hspacing4} mb={3}>
            <Box display="flex" flexDirection="row" alignItems="center" className={sharedClasses.hspacing4}>
                {(selectedCourseId || selectedStudentId) && (
                    <Button
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                        onClick={() => onSelectCourse?.()}
                    >
                        Back
                    </Button>
                )}
                <DisplayStateTabs displayState={displayState} setDisplayState={setDisplayState} />
                {selectedCourseId ? (
                    <Button
                        variant="contained"
                        color="blue"
                        onClick={() => onViewCourseDetails?.(selectedCourseId)}
                    >
                        View Course Details
                    </Button>
                ) : selectedStudentId ? (
                    <Box display="flex" flexDirection="column" ml={2}>
                        <Typography variant="subtitle1">{students[selectedStudentIndex].name}</Typography>
                        Last seen {students[selectedStudentIndex].tracked_date 
                            ? format(new Date(students[selectedStudentIndex].tracked_date!), 'M/d/yyyy') 
                            : 'never'}
                    </Box>
                ) : (
                    <>
                        <GameSelectDropdown selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
                        <DownloadReportButton students={students} reportView={reportView} />
                    </>
                )}
            </Box>
            
            {/* Add a flex-grow element to take up remaining space */}
            <Box flexGrow={1} />

            {/* Legacy View Toggle Button aligned to the right */}
            {!selectedStudentId && (
                <LegacyViewToggleButton legacyView={legacyView} setLegacyView={setLegacyView} />
            )}
        </Box>
    );
};


const DisplayStateTabs: React.VFC<{ displayState: string, setDisplayState: (displayState: string) => void }> = ({ displayState, setDisplayState }) => {
    return <Box display="flex" flexDirection="row">
        <Button
            variant={displayState == "assigned" ? "contained" : "outlined"}
            color="blue"
            style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            }}
            onClick={() => {
                setDisplayState("assigned");
            }}
        >Assigned Courses</Button>

        <Button
            variant= {displayState == "all" ? "contained" : "outlined"}
            color="blue"
            style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }}
            onClick={() => {
                setDisplayState("all");
            }}
        >All Courses</Button>
    </Box>
}

const DownloadReportButton: React.VFC<{ students: IStudentSchoolProfile[], reportView: IProgressReportView | null }> = ({ students, reportView }) => {
    return <Button
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faFileDownload} />}
        onClick={() => {
            FileSaver.saveAs(transformReportToCsv('student', students.map(({ name }) => name), reportView!), 'kodable progress report.csv');
        }}
    >Download Report</Button>
}

const LegacyViewToggleButton: React.VFC<{ legacyView: boolean, setLegacyView: (legacyView: boolean) => void }> = ({ legacyView, setLegacyView }) => {
    return <Button
        variant="outlined"
        color="purple"
        onClick={() => {setLegacyView(!legacyView)}}
    >{legacyView ? "View New Progress" : "View Old Progress"}</Button>
}

export default ProgressHeader;