export const missionColorMap: Record<string, string> ={
    '0': '#01b343',
    '1': '#108cab',
    '2': '#8b4acf',
    '3': '#da4d55'
}

export const missionNameMap: Record<string, string> ={
    '0': 'Beginner',
    '1': 'Intermediate',
    '2': 'Advanced',
    '3': 'Mastery'
}