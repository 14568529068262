import { Box, CircularProgress, Grid, Typography, Link } from "@material-ui/core";
import { IRouteParams } from "AppRoutes";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useMemo } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import useSWR from "swr";
import { IKlass } from "types/IKlass";
import { ELeadState, IStudentSchoolProfile } from "types/IStudentSchoolProfile";
import { Check, ChevronLeft } from "@material-ui/icons";
import { ValentinesStudentMazeData } from "./TeacherValentineClassList";
import { ValentinesSharingDialog } from "./ValentinesSharingDialog";
import {routes} from "../../routes";

const purp = '#C32F96'

export const ViewValentinesByClass: React.VFC = () => {
  const { classId } = useParams<IRouteParams>();
  const { teacherData } = useTeacherInit();
  const klass = useMemo(() => {
    return teacherData?.klasses.find(({ id }) => id.toString() === classId)
  }, [teacherData, classId]);
  const { data } = useSWR<{ [key: number]: ValentinesStudentMazeData[] }>(endpoints.valentinesMazesByKlass(classId, 'ValentinesDay2023'));
  const students = useMemo(() => {
    return teacherData?.students.filter((student) => student.klasses.includes(Number(classId)));
  }, [teacherData])


  if (!klass || !students || !data) {
    return <Box bgcolor="#e67fc7" width="100%" minHeight="calc(100vh - 76px)" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  }

  return <Box bgcolor="#e67fc7" width="100%" minHeight="calc(100vh - 76px)" display="flex" flexDirection="column" alignItems="center" p={4}>
    <ViewValentinesByClassHeader klass={klass} />
    <ViewValentinesByClassContent klass={klass} students={students} mazeData={data} />
  </Box>;
}

export const ViewValentinesByClassHeader: React.VFC<{ klass: IKlass }> = ({ klass }) => {
  const valentinesLinksDialogState = useDialogState();
  return <Box display="flex" flexDirection="column" alignItems="center" pb={4} width="100%">
    <Box width="100%" display="flex" alignItems="center">
      <RouterLink to={routes.valentines.index} style={{ color: 'white' }}><ChevronLeft /> Back to Valentine's activity</RouterLink>
    </Box>
    <Box color="white" py={2}>
      <ValentinesSharingDialog
        {...valentinesLinksDialogState}
        klass={klass}
      />
      <Box textAlign="center" fontFamily="Kodable" style={{ textShadow: '1px 3px rgba(0,0,0,0.5)', color: 'white', fontSize: '1.75rem' }}>{klass.klass_name}</Box>
    </Box>
    <Button
      variant="contained"
      onClick={valentinesLinksDialogState.handleOpen}
      color="newPink"
      style={{ fontWeight: 'bold' }}
    >Connect Families</Button>
  </Box>
}

export const ViewValentinesByClassContent: React.VFC<{ klass: IKlass, students: IStudentSchoolProfile[], mazeData: { [key: number]: ValentinesStudentMazeData[] } }> = ({ klass, students, mazeData }) => {
  return <Box maxWidth={1200} width="100%" pb={4}>
    <Grid container spacing={4} justifyContent="center">
      {students.map(student => <>
        <ViewValentinesByClassItem klass={klass} mazeData={mazeData[student.id]?.[0] || undefined} student={student} />
      </>)}
    </Grid>
  </Box>;
}

export const ViewValentinesByClassItem: React.VFC<{ klass: IKlass, mazeData: ValentinesStudentMazeData, student: IStudentSchoolProfile }> = ({ klass, mazeData, student }) => {
  const viewDialogState = useDialogState();
  const sharedClasses = useSharedStyles();

  return <Grid item xs={8} sm={6} md={4}>
    <ValentinesSharingDialog {...viewDialogState} klass={klass} studentId={student.id} mazeImgSrc={mazeData?.image_url} />
    <Box display="flex" justifyContent="center" width="100%">
      <Box bgcolor="white" py={3} px={2} borderRadius={6} maxWidth={350} width="100%" height={400}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" height="100%">
          <Typography variant="subtitle1">{student.name}</Typography>
          {!mazeData && <>
            <Box pt={2} height={225} display="flex" alignItems="center">
              <img src={'/images/valentines/student-coding.png'} style={{ width: 150, borderRadius: 6 }} />
            </Box>
            <Box pt={2}>
              <Typography align="center" style={{ fontStyle: 'italic' }}>hasn't created a valentine yet</Typography>
            </Box>
          </>}
          {mazeData && <>
            <Box pt={2}>
              <Box overflow="hidden">
                <img src={mazeData.image_url} style={{ borderRadius: 6, transform: 'scale(1.05)' }} />
              </Box>
            </Box>
            <Box pt={2}>
              <Typography align="center">{mazeData.message}</Typography>
            </Box>
          </>}
          <Box py={1}>
            {student.lead?.state !== ELeadState.linked && <Link
              className={sharedClasses.hoverCursorPointer}
              style={{ color: purp }}
              onClick={viewDialogState.handleOpen}
            >{mazeData ? 'Share' : 'Connect'}</Link>}
            {student.lead?.state === ELeadState.linked && <Box display="flex" alignItems="center">
              <Check />&nbsp;Shared!
            </Box>}
          </Box>
        </Box>
      </Box>
    </Box>
  </Grid>;
}