import React from "react";
import {GameTitle} from "../../../enums/GameTitle";
import {Box, Typography} from "@material-ui/core";

export const GameTitleChip: React.VFC<{ game: GameTitle }> = ({ game }) => {

    const getCardConfig = (game: GameTitle) => {
        if (game === GameTitle.BASICS) {
            return {
                icon: '/images/onboarding/basics-app-icon.png',
                color: "#ffb40f",
                name: "BASICS",
                grades: 'GRADES K-2',
            }
        } else {
            return {
                icon: '/images/onboarding/creator-app-icon.png',
                color: "#C32F96",
                name: "CREATOR",
                grades: 'GRADES 3-5',
            }
        }
    }
    const config = getCardConfig(game);
    return (
        <Box display="flex" flexDirection="row" alignItems="center" style = {{
            backgroundColor: config.color,
            height: "35px",
            borderRadius: "5px",
            position: 'absolute', right: 16, top: 16
            }}>
            <img src={config.icon} width={125} alt={config.name}
                 style={{
                     userSelect: 'none',
                     height: "100%",
                     width: "auto",
                     borderTopLeftRadius: "5px",
                     borderBottomLeftRadius: "5px",
                 }}
            />
            <Typography
                style={{
                    fontFamily: 'Bjola',
                    fontSize: '1.2rem',
                    paddingTop: '.75rem',
                    color: "#fff",
                    paddingLeft: ".5rem",
                    paddingRight: ".5rem"
                }}
            >
            {config.name}</Typography>
        </Box>)
}