import React from "react";
import {DialogStateProps} from "../../hooks/useDialogState";
import {IStudentSchoolProfile} from "../../types/IStudentSchoolProfile";
import Button from "../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export const DeleteStudentsButton: React.FC<{
  dialogState: DialogStateProps,
  selectedStudents: IStudentSchoolProfile[]
}> = ({selectedStudents, dialogState}) => {
  return <Button
    startIcon={<FontAwesomeIcon icon={faTrash}/>}
    variant="contained"
    color="red"
    disabled={selectedStudents.length === 0}
    onClick={dialogState.handleOpen}
  >
    Delete Selected Students
  </Button>
}