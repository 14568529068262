import Button from "components/ui/buttons/Button";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link, List, ListItem, ListItemText, makeStyles, MenuItem, Paper, Typography } from "@material-ui/core";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { justFetch } from "mutations/mutate";
import { useFormik } from "formik";
import useCurrentUser, { mutate as updateCurrentUser } from "loaders/useCurrentUser";
import * as Yup from 'yup';
import { Alert } from "@material-ui/lab";
import { useTracking } from "context/TrackingProvider";
import { ISchoolSearchResultItem } from "pages/settings/teacher/ISchoolSearchResultItem";
import { useAlert } from "context/AlertProvider";
import { DialogStateProps } from "hooks/useDialogState";
import states from "states-us/dist";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import {routes} from "../../../routes";
import useTeacherInit from "../../../loaders/useTeacherInit";


export const TeacherOnboardingAddSchoolResults: React.VFC = () => {
  return <UpdateSchoolResultsPage />
}


const useUpdateSchoolResultsPageStyles = makeStyles(theme => ({
  listContainer: {
    overflow: 'scroll'
  }
}));

const UpdateSchoolResultsPage: React.FC = () => {
  const { state: locationState } = useLocation<{ zipcode: string } | { city: string, state: string }>();
  const { data: schools, error, mutate } = useSWR<ISchoolSearchResultItem[]>(locationState && 'zipcode' in locationState ? endpoints.schoolSearchByZip(locationState.zipcode) : endpoints.schoolSearchByCity(locationState?.city, locationState?.state));
  const loading = !schools && !error;
  const classes = useUpdateSchoolResultsPageStyles();
  const sharedClasses = useSharedStyles();
  const [searchString, setSearchString] = useState('');
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { teacherData } = useTeacherInit();
  const [submissionError, setSubmissionError] = useState(false);
  const { track } = useTracking();

  const form = useFormik({
    initialValues: {
      is_non_usa: false,
      school_id: undefined
    },
    validationSchema: Yup.object({
      school_id: Yup.number().required('Please select a school to continue')
    }),
    onSubmit: values => {
      return justFetch(endpoints.user(`${currentUser.id}`), 'PUT', values)
        .then(res => {
          if (!res.ok) {
            setSubmissionError(true);
          } else {
            // have to wait for updateCurrentUser to finish before navigating, otherwise Navigator will use stale data and will repeat add-shool page
            updateCurrentUser().then(() => {
              track('Add School Completed', {
                result: 'school added'
              });

              if (teacherData?.has_clever_account && !currentUser?.is_dashboard_onboarded) {
                history.push(routes.teacherOnboarding.addClassClever.index);
                return;
              }
              history.push(routes.teacherOnboarding.addClassManual.index, {
                selectedSchool: schools?.find(({ id }) => values.school_id === id),
                ...locationState
              });
            });
          }
        })
        .catch(() => setSubmissionError(true));
    }
  });

  const schoolsFound = schools && schools.length > 0;

  const filteredSchools = useMemo(() => {
    const filteredSchools = schools?.filter(({ school_name }) => school_name.toLowerCase().includes(searchString.toLowerCase()));

    if (!filteredSchools?.find(({ id }) => id === form.values.school_id)) {
      form.setFieldValue('school_id', undefined);
    }

    return filteredSchools;
    // eslint-disable-next-line
  }, [schools, searchString]);
  
  const [showRequestSchoolDialog, setShowRequestSchoolDialog] = useState(false);

  useEffect(() => {
    if (locationState) {
      track('Viewed Onboarding Page', {
        'Page Name': 'Add School Results'
      });
    } else {
      history.push(routes.teacherOnboarding.addSchool.index)
    }
  }, [locationState]);

  if (loading) {
    return <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>
  }

  if (error) {
    return <>
      <Alert severity="error"
        action={
          <Button
            onClick={() => mutate()}
            color="inherit"
            size="small"
          >Try again</Button>
        }
      >There was an error performing this search.</Alert>
      <Button
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => {
          history.push(routes.teacherOnboarding.addSchool.index, locationState)
        }}
      >
        Go Back
      </Button>
    </>
  }

  const searchParam = (() => {
    if (!locationState) {
      return;
    }

    if ('zipcode' in locationState) {
      return locationState.zipcode;
    }

    return `${locationState.city}, ${locationState.state}`;
  })();

  return <>
    <RequestAddSchoolDialog
      open={showRequestSchoolDialog}
      onClose={() => setShowRequestSchoolDialog(false)}
      school_searched_zip={(locationState as any).zipcode}
      school_mailing_city={(locationState as any).city}
      school_mailing_state={(locationState as any).state}
    />
    <Box className={sharedClasses.vspacing2} display="flex" flexDirection="column" flexGrow={1} height="400px">
      {schools && schools.length > 0 && <Typography>
        {schools.length} schools found in {searchParam}{schoolsFound ? ':' : '.'} <Link href="#" onClick={e => {
          e.preventDefault();
          setShowRequestSchoolDialog(true);
        }}>
          Can't find your school?
        </Link>
      </Typography>}
      {schools && schools.length === 0 && <Typography>
        We couldn't find any schools in {searchParam}. <Link href="#" onClick={e => {
          e.preventDefault();
          setShowRequestSchoolDialog(true);
        }}>
          Click here to add your school.
        </Link>
      </Typography>}
      {schoolsFound &&
        <TextField
          autoFocus
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder="Start typing to filter results"
          margin="dense"
        ></TextField>
      }
      {schoolsFound &&
        <Paper variant="outlined" classes={{
          root: classes.listContainer
        }} style={{ flexGrow: 1, height: 0 }}>
          <List>
            {filteredSchools?.map(({ id, school_name, school_location_city, school_location_state, school_location_zip }) => {
              return <ListItem key={id} button {...{ disableRipple: true }} selected={id === form.values.school_id} onClick={() => !form.isSubmitting && form.setFieldValue('school_id', id)}>
                <ListItemText primary={school_name}></ListItemText>
              </ListItem>
            })}
          </List>
        </Paper>
      }

      {submissionError && <Alert severity="error">There was an error submitting this form.</Alert>}
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={() => {
              history.push(routes.teacherOnboarding.addSchool.index, locationState)
            }}
          >
            Go Back
          </Button>
        </Box>
        {schoolsFound && <Button
          color="primary"
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          disableElevation
          onClick={form.submitForm}
          disabled={!form.isValid || form.isSubmitting}
        >Set as my school</Button>}
      </Box>
    </Box>
  </>
}


interface IAddSchoolRequest {
  teacher_id: number;
  school_name: string;
  school_mailing_city: string;
  school_mailing_state: string;
  school_searched_zip?: string;
}

const RequestAddSchoolDialog: React.VFC<DialogStateProps & { school_mailing_city?: string, school_mailing_state?: string, school_searched_zip?: string }> = ({ open = false, school_mailing_city, school_mailing_state, school_searched_zip, onClose = () => { } }) => {
  const { session, currentUser } = useCurrentUser();
  const [submitError, setSubmitError] = useState(false);
  const history = useHistory();
  const alert = useAlert();
  const { track } = useTracking();

  const form = useFormik<IAddSchoolRequest>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      teacher_id: currentUser.id,
      school_name: '',
      school_mailing_city: school_mailing_city || '',
      school_mailing_state: school_mailing_state || '',
      school_searched_zip: school_searched_zip || ''
    },
    onSubmit: values => {
      justFetch(endpoints.requestAddSchool, 'POST', values)
        .then(res => {
          if (!res.ok) {
            throw new Error();
          }

          alert.success('School Request Submitted');
          track('Add School Completed', {
            result: 'school requested'
          });
          return mutate(endpoints.session, {
            ...session,
            user: {
              ...session.user,
              is_non_usa: true
            }
          });
        })
        .then(() => history.push(routes.classes.index))
        .catch(() => setSubmitError(true))
    },
    validationSchema: Yup.object({
      school_name: Yup.string().required('Enter the name of your school'),
      school_mailing_city: Yup.string().required('Enter the city that your school is in'),
      school_mailing_state: Yup.string().required('Enter the state that your school is in'),
    })
  });
  const sharedClasses = useSharedStyles();
  useEffect(() => {
    if (open) {
      form.resetForm();
      setSubmitError(false);
    }
  }, [open]);

  return <Dialog open={open} fullWidth>
    <LinearProgress style={{ visibility: form.isSubmitting ? 'visible' : 'hidden' }} />
    <form onSubmit={form.handleSubmit}>
      <DialogTitle>
        Add A New School
      </DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography>Please enter your school name, city, and state. We will add your school.</Typography>
        <TextField
          label="School"
          id="school_name"
          name="school_name"
          value={form.values.school_name}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
          helperText={form.errors.school_name}
          error={form.submitCount > 0 && !!form.errors.school_name}
        />
        <TextField
          label="City"
          id="school_mailing_city"
          name="school_mailing_city"
          value={form.values.school_mailing_city}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
          helperText={form.errors.school_mailing_city}
          error={form.submitCount > 0 && !!form.errors.school_mailing_city}
        />
        <Select
          label="State"
          id="school_mailing_state"
          name="school_mailing_state"
          value={form.values.school_mailing_state}
          onChange={(e) =>
            form.setFieldValue("school_mailing_state", e.target.value)
          }
          disabled={form.isSubmitting}
          displayEmpty
          fullWidth
          helperText={form.errors.school_mailing_state}
          error={form.submitCount > 0 && !!form.errors.school_mailing_state}
        >
          <MenuItem disabled value="">
            Select your state
          </MenuItem>
          {states
            .filter(({ territory }) => !territory)
            .map(({ name, abbreviation }) => (
              <MenuItem key={abbreviation} value={abbreviation}>{name}</MenuItem>
            ))}
        </Select>
        {form.submitCount > 0 && !form.isValid && <Alert severity="error">Please correct the errors above to continue.</Alert>}
        {submitError && <Alert severity="error" action={<Button size="small" color="inherit" onClick={() => form.handleSubmit()}>Try Again</Button>}>There was an error submitting this form.</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={form.isSubmitting}
          onClick={onClose}
          type="reset"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="green"
          disabled={form.isSubmitting}
          type="submit"
        >
          Add School
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}
