import { IRouteParams } from "AppRoutes";
import { useHistory, useRouteMatch } from "react-router-dom";
import StudentsProgress from "pages/reports/StudentsProgress";
import { Box } from "@material-ui/core";
import {routes} from "../../../routes";
import React from "react";

const ClassProgress: React.VFC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  return <Box py={4} px={6}>
    <StudentsProgress
      classIds={[parseInt(params.classId)]}
      selectedCourseId={params.courseId}
      selectedStudentId={params.studentId}
      onSelectCourse={selectedCourseId => {
        if (selectedCourseId) {
          history.push(routes.class.progress(params.classId, selectedCourseId))
        } else {
          history.push(routes.class.progress(params.classId, ''))
        }
      }}
      onSelectStudent={selectedStudentId => {
        if (selectedStudentId) {
          history.push(routes.class.progressIndividual(params.classId, selectedStudentId))
        } else {
          history.push(routes.class.progress(params.classId, ''))
        }
      }}
      onViewCourseDetails={courseId => history.push(routes.courses.viewCourse.materials(courseId))}
    />
  </Box>
}

export default ClassProgress;