import {useLayoutEffect, useState} from "react";
import {Box, Paper} from "@material-ui/core";
import {GridColDef} from "@material-ui/data-grid";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";
import {ICleverTeacher, useClever} from "../../context/CleverProvider";

const CleverTeachers: React.VFC = () => {
  const [teachers, setTeachers] = useState<ICleverTeacher[]>([])
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState<string>('')
  const [totalRowCount, setTotalRowCount] = useState(0)
  const {useTeachers} = useClever();
  const {data: teacherData, isValidating} = useTeachers({
    page: page+1,
    per_page: pageSize,
    query
  })

  useLayoutEffect(() => {
    setTotalRowCount(teacherData?.total_count || 0)

    if (teacherData?.teachers) {
      setTeachers(teacherData.teachers.map((teacher: ICleverTeacher) => {
        return {...teacher, id: `${teacher.teacher_id}`}
      }))
    }
  }, [teacherData])

  const columns: GridColDef[] = [
    { field: 'teacher_name', headerName: 'Teacher Name', width: 250 },
    { field: 'clever_id', headerName: 'Clever ID', width: 250 },
    { field: 'school_name', headerName: 'School', width: 300 },
    { field: 'klass_count', headerName: '# Classes', width: 120, type: 'number' },
    { field: 'student_count', headerName: '# Students', width: 120, type: 'number' },
    { field: 'last_sync', headerName: 'Last Sync', width: 125, type: 'date' },
  ]

  return (
    <Box ml={2} mr={2}>
      <CleverButtonToolbox
        searchPlaceholder={"Search Teachers"}
        searchDisabled={teachers.length === 0 && query.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        toolTip={'These are the teachers currently shared with Kodable through Clever. If a specific teacher is missing from this list, please contact your Clever admin to ensure they are being shared with us.'}
      />
      <Box component={Paper}>
        <CleverTable
          onPageSizeChange={setPageSize}
          onPageChange={setPage}
          pageSize={pageSize}
          page={page}
          columns={columns}
          rows={teachers}
          loading={isValidating}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverTeachers