import { ILicense } from "./ILicense";
import { IOrganizationProfile } from "./IOrganizationProfile"
import { IPayment } from "./IPayment";
import { ITeacher } from "./ITeacher"

export enum ISubscriptionTerm {
  None,
  Monthly,
  Yearly,
  Lifetime,
  Gift6,
  Gift12,
  GiftLifetime
}

export enum ISubscriptionSource {
  None,
  Apple,
  Stripe,
}

export interface ISubscriptionPurchaseProcess {
  id: number;
  teacher_id: number;
  quote_request_id: number | null;
  email: string;
  opportunity_id: number | null;
  state: 'paid' | 'payment_pending' | 'delinquent';
  secret_key: string;
  created_at: string;
  updated_at: string;
  payment_due: string;
  rep_id: number | null;
  bill_date: string;
  is_invoiced: boolean;
  quote_request_v2_id: number | null;
}


export type ISubscription = {
  id: number,
  teacher_id: number,
  teacher?: ITeacher,
  amount: number,
  contract_amount: number,
  license_count: number,
  receipt: null | string,
  active: boolean,
  start_date: string,
  end_date: string,
  created_at: string,
  updated_at: string,
  stripe_sub_id: string | null,
  term: ISubscriptionTerm.Lifetime | ISubscriptionTerm.None | ISubscriptionTerm.GiftLifetime | ISubscriptionTerm.Monthly | ISubscriptionTerm.Yearly | ISubscriptionTerm.Gift6 | ISubscriptionTerm.Gift12,
  original_transaction_id: string | null,
  source: ISubscriptionSource,
  prorated_discounted_price: number,
  organization_profile_id: number | null,
  organization_profile: IOrganizationProfile | null,
  purchase_process: ISubscriptionPurchaseProcess | null,
  payment: IPayment | null,
  plan: string | null,
  has_curriculum: boolean,
  notes: string | null,
  licenses: ILicense[],
  quickbooks_invoice_link?: string,
  previous_subscription_id: number | null,
  student_limit: number,
  quickbooks_invoice_doc_number?: string,
  secret_key?: string,
  contract_duration?: string,
  furthest_paid_sub_id?: number,
}

export type ISchoolSubscriptionPricing = {
  premium: number;
  premium_plus: number;
  volume_discount: number;
  volume_discount_threshold: number;
}

export type IParentSubscriptionPricing = {
  monthly: number,
  yearly: number,
  lifetime: number
}
