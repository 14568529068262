import {Box, InputAdornment} from "@material-ui/core";
import TextField from "../../../components/ui/TextField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/ui/buttons/Button";
import {useEffect, useState} from "react";
import InfoBox from "../../../components/ui/InfoBox";
import useTeacherInit from "../../../loaders/useTeacherInit";
import useDebounce from "../../../hooks/useDebounce";

const CleverButtonToolbox: React.FC<{
  hideClass?: boolean,
  showHiddenClass?: boolean,
  searchPlaceholder?: string,
  searchDisabled?: boolean,
  hideClassDisabled?: boolean,
  showHiddenClassDisabled?: boolean,
  onSearchChange: (query: string) => void,
  onHideClassClick?: () => void,
  onShowHiddenClassClick?: () => void,
  toolTip?: string,
}> =
  ({
     hideClass = false,
     showHiddenClass = false,
     searchPlaceholder = 'Search',
     searchDisabled = false,
     hideClassDisabled = false,
     showHiddenClassDisabled = false,
     onSearchChange = () => {},
     onHideClassClick = () => {},
     onShowHiddenClassClick = () => {},
     toolTip
  }) => {
  const [query, setQuery] = useState("")
  const debouncedQuery = useDebounce(query, 1000)
  const {teacherData} = useTeacherInit()

  const handleQueryChange = (value: string) => {
    setQuery(value);
  };

  useEffect(() => {
    onSearchChange(debouncedQuery);
  }, [debouncedQuery, onSearchChange]);

  return (
    <Box display={'flex'} mt={2} mb={2} width={'100%'} flexDirection={'row'} alignItems={'center'}>
      <Box width={400}>
        <TextField
          value={query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleQueryChange(e.target.value)}
          placeholder={searchPlaceholder}
          fullWidth
          disabled={searchDisabled}
        />
      </Box>
      {hideClass && teacherData?.has_clever_secure_sync && showHiddenClass &&
        <Box display={'flex'} width={450} justifyContent={'start'}>
          <Box ml={2}>
            <Button
              variant={"contained"}
              color={'red'}
              disabled={hideClassDisabled}
              onClick={onHideClassClick}
            >
              Hide Class
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              variant={"contained"}
              color={'orange'}
              disabled={showHiddenClassDisabled}
              onClick={onShowHiddenClassClick}
            >
              Show Hidden Classes
            </Button>
          </Box>
        </Box>
      }
      <Box display={'flex'} justifyContent={'center'}>
        {toolTip &&
          <InfoBox style={{marginLeft: 10, width: '100%'}}>
            {toolTip}
          </InfoBox>
        }
      </Box>
    </Box>
  )
}

export default CleverButtonToolbox