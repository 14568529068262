import {DataGrid, GridColDef, GridRowData} from "@material-ui/data-grid";
import {addColumnFormatters} from "../utils/formatter";

const CleverTable: React.FC<{
  columns: GridColDef[],
  rows: GridRowData[],
  loading: boolean,
  checkboxSelection?: boolean,
  onRowSelect?: (value: any[]) => void,
  onPageSizeChange: (size: number) => void,
  pageSize: number,
  onPageChange: (page: number) => void,
  page: number,
  totalRowCount: number
}> =
  ({columns, rows, loading = false, checkboxSelection = false, onRowSelect = (value) => {}, onPageSizeChange, pageSize, onPageChange, page, totalRowCount}) => {

  return (
    <DataGrid
      rows={rows}
      columns={addColumnFormatters(columns)}
      pageSize={pageSize}
      page={page}
      autoHeight
      loading={loading}
      onSelectionModelChange={(data) => onRowSelect(data)}
      checkboxSelection={checkboxSelection}
      disableColumnMenu
      disableColumnSelector
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageSizeChange={onPageSizeChange}
      onPageChange={(newPage) => onPageChange(newPage)}
      pagination
      rowCount={totalRowCount}
      paginationMode={"server"}
      disableSelectionOnClick
    />
  )
}

export default CleverTable