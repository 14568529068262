import { Box, Link, Paper, Typography } from "@material-ui/core";
import {AccountBalanceRounded, BusinessCenterRounded, DomainRounded, Email, WarningRounded} from "@material-ui/icons";
import Button from "components/ui/buttons/Button";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import { useAlert } from "context/AlertProvider";
import { format } from "date-fns";
import endpoints from "endpoints";
import useDialogState from "hooks/useDialogState";
import { justFetch } from "mutations/mutate";
import { setSessionTokenCookie } from "pages/login/loginUtils";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import Cookies from "universal-cookie";
import { EditTeacherDialog } from "./dialogs/EditTeacherDialog";
import { IGodmodeUserProfile } from "pages/godmode/Users/Teachers/ViewUser/IGodmodeUserProfile";
import {ConfirmationDialog} from "components/dialogs/base/ConfirmationDialog";
import React from "react";

export const AccountDetailsBlock: React.FC<{ teacher: IGodmodeUserProfile }> = ({ teacher }) => {
  const sharedClasses = useSharedStyles();
  const alert = useAlert();
  const history = useHistory();
  const editTeacherDialogState = useDialogState();
  const makeAmbassadorDialogState = useDialogState();

  const handleImpersonate = async () => {
    const res = await justFetch(endpoints.godmode.impersonate(teacher.id), 'POST');
    const parsed: { session_token: string } = await res.json();
    const currentSessionToken = (new Cookies()).get('kodable_kode')
    setSessionTokenCookie(parsed.session_token);
    setSessionTokenCookie(currentSessionToken, true);
    window.location.href = '/';
  }

  const handleMakeAmbassador = async () => {
    const res = await justFetch(endpoints.godmode.ambassadors, 'POST', {teacher_id: teacher.id});
    if (res.ok) {
      makeAmbassadorDialogState.onClose();
      alert.success('User successfully promoted to Ambassador!');
    } else {
      makeAmbassadorDialogState.onClose();
      const parsed = await res.json();
      if (res.status === 409) {
        alert.error("This user is already a Ambassador.");
      } else {
        alert.error(parsed.message);
      }
    }
  }

  const handleUpgradePlan = () => {
    history.push(routes.sales.orders.submit.planWithTeacher(teacher.name, teacher.username));
  }

  return <Paper variant="outlined" style={{ height: '100%'}}>
    <EditTeacherDialog {...editTeacherDialogState} teacher={teacher} />
    <ConfirmationDialog
        open={makeAmbassadorDialogState.open}
        onClose={makeAmbassadorDialogState.onClose}
        title={'Are you sure?'}
        onConfirm={handleMakeAmbassador}
        subtitle={'Are you sure that you would like to make this user an Ambassador?'}
    />
    <Box p={2} height="100%">
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" alignItems="start" flexGrow={1}>
          <Box flexGrow={1} mr={2}>
            {teacher.is_administrator && teacher.organization_profile  && <Typography variant="h5">
              {teacher.id === teacher.organization_profile.administrator_id && "Account Super Admin"}
              {teacher.id !== teacher.organization_profile.administrator_id && "Account Site Admin"}
            </Typography>}
            {!teacher.is_administrator && <Typography variant="h5">
              Account Details
            </Typography>}
            <Typography variant="body1">{teacher.name}{!!teacher.administrator_code ? <>&nbsp;| Admin Code <Box fontWeight="bold" component="span">{teacher.administrator_code.toLocaleUpperCase()}</Box></> : null}</Typography>
            <Box display="flex" alignItems="center" className={sharedClasses.hspacing1} mt={1}>
              <Email /> <Box>{teacher.username}</Box>
            </Box>
            {!!teacher.password_reset && <Box display="flex" alignItems="center" className={sharedClasses.hspacing1} mb={-1}>
              <WarningRounded /><Box display="flex" alignItems="start" mt={0.25} width="100%"><Box mt={0.25}>Password Reset Link: </Box>
                <TextField fullWidth variant="standard" size="small" value={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/reset?id=${teacher.password_reset.reset_hash}`} inputProps={{
                  style: { fontSize: 14 }, onClick: (e) => {
                    e.currentTarget?.select();
                    document.execCommand('copy');
                    alert.success('Reset link copied to clipboard')
                  }
                }} />
              </Box>
            </Box>}
            <Box display="flex" alignItems="center" mt={1} className={sharedClasses.hspacing1}>
              <BusinessCenterRounded /><Box>{teacher.occupation}</Box>
            </Box>
            <Box display="flex" alignItems="center" mt={1} className={sharedClasses.hspacing1}>
              <AccountBalanceRounded /><Box>{teacher.school?.school_name || 'None'}</Box>
            </Box>
            <Link className={sharedClasses.hoverCursorPointer} href={teacher.organization_profile ? `/godmode/organization-profiles/${teacher.organization_profile.id}` : '#'} >
              <Box display="flex" alignItems="center" mt={1} className={sharedClasses.hspacing1}>
                <DomainRounded color="inherit"/>
                <Box>{teacher.organization_profile?.display_name || 'None'}</Box>
              </Box>
            </Link>          
          </Box>
          <Box display="flex" flexDirection="column">
            {teacher.administrator_id && <Box mb={1}><Button variant="contained" color="primary" onClick={() => history.push(routes.godmode.user.index(teacher.administrator_id))}>Administrator Account</Button></Box>}
            <Typography variant="subtitle1">Signed Up</Typography>
            <Typography variant="body2">{format(new Date(teacher.created_at), 'PPP')}</Typography>
            <Typography variant="subtitle1">Last Active</Typography>
            <Typography variant="body2">{format(new Date(teacher.updated_at), 'PPP')}</Typography>
          </Box>
        </Box>
        <Box borderTop="1px solid rgba(0, 0, 0, 0.12)" mt={2} pt={2} display="flex" justifyContent="space-around" className={sharedClasses.hspacing2}>
          <Link onClick={editTeacherDialogState.handleOpen} className={sharedClasses.hoverCursorPointer}>edit</Link>
          <Link onClick={() => handleImpersonate()} className={sharedClasses.hoverCursorPointer}>impersonate</Link>
          {!teacher.referrals.is_ambassador && <Link onClick={() => makeAmbassadorDialogState.handleOpen()} className={sharedClasses.hoverCursorPointer}>make ambassador</Link>}
          {(teacher.plan === 'Free') && <Link onClick={() => handleUpgradePlan()} className={sharedClasses.hoverCursorPointer}>upgrade plan</Link>}
        </Box>
      </Box>
    </Box>
  </Paper>;
}