import {useLayoutEffect, useState} from "react";
import {Box, Paper} from "@material-ui/core";
import {GridColDef} from "@material-ui/data-grid";
import CleverButtonToolbox from "./components/CleverButtonToolbox";
import CleverTable from "./components/CleverTable";
import {ICleverStudent, useClever} from "../../context/CleverProvider";


const CleverStudents: React.VFC = () => {
  const [query, setQuery] = useState<string>('');
  const [students, setStudents] = useState<ICleverStudent[]>([])
  const [pageSize, setPageSize] = useState(50);
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const {useStudents} = useClever()
  const { data: studentData, isValidating } = useStudents({
    page: page+1,
    per_page: pageSize,
    include_hidden_klasses: false,
    query
  });

  useLayoutEffect(() => {
    setTotalRowCount(studentData?.total_count || 0)
    if (studentData?.students) {
      setStudents(studentData.students.map((student: ICleverStudent) => {
        return {...student, id: `${student.student_id}-${student.clever_id}`}
      }))
    }
  }, [studentData]);


  const columns: GridColDef[] = [
    { field: 'student_name', headerName: 'Student Name', width: 175 },
    { field: 'clever_id', headerName: 'Clever ID', width: 250 },
    { field: 'school_name', headerName: 'School Name', width: 175 },
    { field: 'teachers', headerName: 'Teacher Name', width: 250 },
    { field: 'klasses', headerName: 'Class Name', width: 250 },
    { field: 'last_sync', headerName: 'Last Sync', width: 125, type: 'date' },
  ]

  return (
    <Box ml={2} mr={2}>
      <CleverButtonToolbox
        searchPlaceholder={"Search Students"}
        searchDisabled={studentData?.students.length === 0 && query.length === 0}
        onSearchChange={(searchValue) => setQuery(searchValue)}
        toolTip={'These are the students currently shared with Kodable through Clever. If a specific student is missing, please contact your Clever admin to ensure they are synced correctly.'}
      />
      <Box component={Paper}>
        <CleverTable
          onPageSizeChange={setPageSize}
          onPageChange={setPage}
          pageSize={pageSize}
          page={page}
          columns={columns}
          rows={students}
          loading={isValidating}
          totalRowCount={totalRowCount}
        />
      </Box>
    </Box>
  )
}

export default CleverStudents