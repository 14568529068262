import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import useSharedStyles from "components/useSharedStyles";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IOrderRequest } from "../IOrderRequest";
import { getConfigPlanURL, omitLicenseHolderSteps, submitOrderSteps } from "./SubmitOrderIndex";
import { routes } from "routes";

interface BooleanFunction {
  (): boolean | Promise<boolean>;
}

export const SubmitOrderPageActions: React.VFC<{ 
  allowNext?: boolean, 
  allowPrev?: boolean, 
  showPrev?: boolean, 
  onPrevious?: VoidFunction,
  onNext?: BooleanFunction,
  nextStr?: string }> = ({ 
    allowNext, 
    allowPrev, 
    showPrev=true, 
    nextStr,
    onPrevious,
    onNext
  }) => {
  const sharedClasses = useSharedStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const form = useFormikContext<IOrderRequest>();

  const steps = (form.values.license_holder_id && false? omitLicenseHolderSteps : submitOrderSteps)
  const currentStepperIndex = useMemo(() => steps.findIndex(step => step.pathname === pathname), [pathname, steps]);
  const { nextPageRoute, previousPageRoute } = useMemo(() => ({
    nextPageRoute: steps[currentStepperIndex + 1]?.pathname,
    previousPageRoute: steps[currentStepperIndex - 1]?.pathname,
  }), [pathname, steps, currentStepperIndex]);
  
  return <Box display="flex" justifyContent="flex-end" className={sharedClasses.hspacing2} borderTop="1px solid #eeeeee" mt={4} pt={3}>
    {showPrev && <Button
      variant="outlined"
      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      onClick={() => {
          onPrevious?.();
          if (previousPageRoute == routes.sales.orders.submit.plan)
            history.replace(getConfigPlanURL());
          else
            history.replace(previousPageRoute);
        }}
      disabled={!allowPrev}
      color="secondary"
      style={{
        visibility: currentStepperIndex > 0 ? 'visible' : 'hidden',
        position: 'absolute',
        top: '-3px',
        left: 0
      }}
    >
      Previous
    </Button>}

    <Button
      variant="contained"
      color="blue"
      onClick={async () => {
        if (await onNext?.()) return;
        history.replace(nextPageRoute)
      }}
      disabled={!allowNext}
      endIcon={<FontAwesomeIcon icon={faArrowRight} />}
      style={{
        visibility: currentStepperIndex < submitOrderSteps.length - 1 ? 'visible' : 'hidden'
      }}
    >
      {nextStr || 'Next'}
    </Button>
  </Box>;
}