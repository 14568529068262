import { faCog, faEdit, faExchangeAlt, faQrcode, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab, Box, CircularProgress, Menu, Typography } from "@material-ui/core";
import MenuItem from 'components/ui/MenuItem';
import { IRouteParams } from "AppRoutes";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import useTeacherInit from "loaders/useTeacherInit";
import React, { useMemo, useRef } from "react";
import { useState } from "react";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import { IKlass } from "types/IKlass";
import { ITeacherData } from "types/ITeacherData";
import AssignAnotherTeacherDialog from "../../components/dialogs/teacher/AssignNewTeacherDialog";
import ClassSettingsDialog from "../../components/dialogs/class/ClassSettingsDialog";
import DeleteClassDialog from "../../components/dialogs/class/DeleteClassDialog";
import ViewClassStudents from "./Students/ViewClassStudents";
import ViewClassDashboard from "./Home/ViewClassDashboard";
import ViewClassCourses from "./Assignments/ViewClassCourses";
import ClassStudentWork from "./StudentCreations/ClassStudentWork";
import ClassProgress from "./Progress/ClassProgress";
import Button from "components/ui/buttons/Button";
import QRCodeDialog from "../../components/dialogs/QRCodeDialog";
import useDialogState from "hooks/useDialogState";
import GradeChip from "../../components/ui/GradeChip";
import { TryItDialog } from "../../components/dialogs/TryItDialog";
import {routes} from "../../routes";
import AssignmentsIndex from "../courses/AssignmentsIndex";
import Tooltip from "../../components/ui/Tooltip";


const ViewClassContainer: React.FC<{ klass: IKlass }> = ({ children, klass: { id: classId, clever_id, klass_name, klass_code, grade } }) => {
  const settingsMenuAnchorRef = useRef(null);
  const { teacherData } = useTeacherInit();
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [showDeleteClassDialog, setShowDeleteClassDialog] = useState(false);
  const [showAssignTeacherDialog, setShowAssignTeacherDialog] = useState(false);
  const qrCodeDialogState = useDialogState();
  const sharedClasses = useSharedStyles();

  return <Box display="flex" flexDirection="column" flexGrow={1}>
    <TryItDialog
      klass_id={classId}
    />
    <ClassSettingsDialog
      open={settingsDialogOpen}
      onClose={() => setSettingsDialogOpen(false)}
      classId={classId}
      cleverId={clever_id}
    />
    <DeleteClassDialog
      open={showDeleteClassDialog}
      onClose={() => setShowDeleteClassDialog(false)}
      classId={classId}
    />
    <AssignAnotherTeacherDialog
      open={showAssignTeacherDialog}
      onClose={() => setShowAssignTeacherDialog(false)}
      classId={classId}
    />
    <QRCodeDialog {...qrCodeDialogState} classCode={klass_code.toLocaleUpperCase()} />
    <Menu
      anchorEl={settingsMenuAnchorRef.current}
      keepMounted
      open={settingsMenuOpen}
      onClose={() => setSettingsMenuOpen(false)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem onClick={() => { setSettingsMenuOpen(false); qrCodeDialogState.handleOpen(); }}><FontAwesomeIcon icon={faQrcode} />&nbsp;Show QR code</MenuItem>
      <MenuItem onClick={() => { setSettingsMenuOpen(false); setSettingsDialogOpen(true); }}><FontAwesomeIcon icon={faEdit} />&nbsp;Edit class settings</MenuItem>
      {clever_id !== null ?
        <Tooltip title={'Not available for Clever managed classes.'} placement={'right'}>
          <span>
            <MenuItem disabled={true} onClick={() => { setSettingsMenuOpen(false); setShowAssignTeacherDialog(true); }}><FontAwesomeIcon icon={faExchangeAlt} />&nbsp;Assign to another teacher</MenuItem>
          </span>
        </Tooltip> : <MenuItem disabled={teacherData?.teachers.length === 1} onClick={() => { setSettingsMenuOpen(false); setShowAssignTeacherDialog(true); }}><FontAwesomeIcon icon={faExchangeAlt} />&nbsp;Assign to another teacher</MenuItem>
      }
      {clever_id !== null ?
        <Tooltip title={'Not available for Clever managed classes.'} placement={'right'}>
          <span>
            <MenuItem disabled={true} onClick={() => { setSettingsMenuOpen(false); setShowDeleteClassDialog(true); }}><FontAwesomeIcon icon={faTrash} />&nbsp;Delete class</MenuItem>
          </span>
        </Tooltip> : <MenuItem onClick={() => { setSettingsMenuOpen(false); setShowDeleteClassDialog(true); }}><FontAwesomeIcon icon={faTrash} />&nbsp;Delete class</MenuItem>
      }
    </Menu>
    <PageHeader
      inAppBar
      title={klass_name}
      showCleverIcon={clever_id !== null}
      actions={
        <Box ml={4} className={sharedClasses.hspacing2} display="flex">
          <Box display="flex" flexDirection="row" alignItems="center" pl={2} pr={4}>
            <Typography variant="subtitle1" style={{ lineHeight: 1, fontSize: 14 }}>Class code:</Typography>&nbsp;
            <Box style={{ fontFamily: 'monospace', lineHeight: 1, fontSize: 16, color: 'gray' }}>{klass_code.toLocaleUpperCase()}</Box>
          </Box>
          <GradeChip grade={grade} kVariant={'full'} />
          <Button
            size="small"
            onClick={() => setSettingsMenuOpen(true)}
            ref={settingsMenuAnchorRef}
            startIcon={<FontAwesomeIcon icon={faCog} />}
            variant="outlined"
            color={'default'}
          >
            Settings
          </Button>
        </Box>
      }
    />
    {children}
  </Box>
}

const ViewClassIndex: React.FC = () => {
  const { data: teacherData } = useSWR<ITeacherData>(endpoints.teacherInit);
  const { pathname } = useLocation();
  const { path, url, params } = useRouteMatch<IRouteParams>();
  const klass = useMemo(() => teacherData?.klasses.find(({ id }) => id.toString() === params.classId), [teacherData, params.classId]);

  const history = useHistory();

  if (!klass) {
    return <PageContainer>
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  return <ViewClassContainer klass={klass}>
    <Tabs
      value={pathname}
      onChange={(ev, newPage) => history.push(newPage)}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Home" value={url} disableRipple />
      <Tab label="Assignments" value={`${url}/courses`} disableRipple />
      <Tab label="Student Creations" value={routes.class.studentWork(undefined, url)} disableRipple />
      <Tab label="Progress" value={pathname.includes('progress') ? pathname : routes.class.progress(undefined, '', url)} disableRipple />
      <Tab label="Students" value={routes.class.students(undefined, url)} disableRipple />
      <Tab label="Connect at Home" value={routes.parentConnect} disableRipple />
    </Tabs>
    <Route
      component={ViewClassDashboard}
      path={`${path}`}
      exact
    />
    <Route
      component={AssignmentsIndex}
      path={`${path}/courses`}
      exact
    />
    <Route
      component={ClassStudentWork}
      path={routes.class.studentWork(undefined, path)}
      exact
    />
    <Route
      component={ViewClassStudents}
      path={routes.class.students(undefined, path)}
      exact
    />
    <Route
      component={ClassProgress}
      path={routes.class.progress(undefined, undefined, path)}
      exact
    />
    <Route
      component={ClassProgress}
      path={routes.class.progressIndividual(undefined, undefined, path)}
      exact
    />
  </ViewClassContainer>
}

export default ViewClassIndex;