import {Box, CircularProgress, InputAdornment} from "@material-ui/core";
import Button from "components/ui/buttons/Button";
import {Alert} from "@material-ui/lab";
import {routes} from "routes";
import classNames from "classnames";
import PageContainer from "components/ui/PageContainer";
import PageHeader from "components/ui/PageHeader";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import React, {useEffect, useMemo, useState} from "react";
import useSWR from "swr";
import {ITeacherData} from "types/ITeacherData";
import {Redirect, useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import TextField from "components/ui/TextField";
import SyncCleverDialog from "../../../components/dialogs/clever/SyncCleverDialog";
import useCurrentUser from "loaders/useCurrentUser";
import {TryItDialog} from "../../../components/dialogs/TryItDialog";
import {RecommendedForYou} from "../Home/RecommendedForYou";
import useDialogState from "hooks/useDialogState";
import {TrackParentInviteMethodLocation} from "utils/ParentInvites";
import KlassSetupModal from "components/modal/KlassSetupModal";
import {KlassSetupModalState} from "types/KlassSetupBannerModalState";
import {MultiClassPrintoutDialog} from "pages/printouts/MultiClassPrintoutDialog";
import {GenerateMultipleClassLinksDialog} from "pages/hoc/GenerateMultipleClassLinksDialog";
import PaginationContainer from "../../../components/ui/PaginationContainer";
import {AddClassDialog} from "components/dialogs/class/AddClassDialog";
import useSubscription from "../../../loaders/useSubscription";
import SubscriptionExpiringSoonModal from "../../../components/modal/SubscriptionExpiringSoonModal";
import {accountTrackedDate, daysUntil} from "../../godmode/utils/time";
import Cookies from "universal-cookie";
import {KlassCard} from "../../../components/cards/KlassCard/KlassCard";


const ViewClassesList: React.VFC = () => {
  const { data: teacherData, error, mutate } = useSWR<ITeacherData>(endpoints.teacherInit);
  const {subscription} = useSubscription();
  const history = useHistory();
  const loading = !teacherData && !error;
  const sharedClasses = useSharedStyles();
  const [showCleverSyncConfirmationDialog, setShowCleverSyncConfirmationDialog] = useState(false);
  const [classFilter, setClassFilter] = useState('');
  const [hasInitializedClasses, setHasInitializedClasses] = useState<boolean>(false)
  const [klassSetupModalState, setKlassSetupModalState] = useState<KlassSetupModalState>('closed')
  const addClassManualDialogState = useDialogState()
  const subscriptionRenewalDialogState = useDialogState()

  const sortedKlasses = useMemo(() => {
    return teacherData?.klasses
      .sort((a, b) => a.klass_name.localeCompare(b.klass_name))
      .map(klass => ({
        ...klass,
        // studentNames: teacherData?.students.filter(student => student.klasses.includes(klass.id)).sort(({ name: A }, { name: B }) => A.localeCompare(B)),
        // teacherNames: teacherData?.teachers.filter(teacher => teacher.klasses.includes(klass.id))
      }))
      .filter(({ klass_name }) => klass_name.toLocaleLowerCase().includes(classFilter.toLowerCase()))
  }, [teacherData, classFilter]);

  // This checks if any klasses are missing parent_invites data, and updates them accordingly
  useEffect(() => {
    if (hasInitializedClasses) {
      return
    }
    // sortedKlasses?.forEach((sortedKlass) => {
    //   if (!sortedKlass || !sortedKlass?.studentNames) {
    //     return
    //   }
    //   ParentInvites.trackInit(sortedKlass, sortedKlass?.studentNames);
    // })
    setHasInitializedClasses(true)
  }, [sortedKlasses])

  useEffect(() => {
    if (daysUntil(subscription.end_date) < 14 && new Cookies().get('seen_renewal_reminder') !== new Date().toLocaleDateString()) {
      subscriptionRenewalDialogState.handleOpen()
    }
  }, []);

  const closeRenewalReminder = () => {
    new Cookies().set('seen_renewal_reminder', new Date().toLocaleDateString());
    subscriptionRenewalDialogState.onClose()
  }


  if (loading) {
    return <PageContainer classes={{ wrapper: classNames(sharedClasses.vspacing2) }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </PageContainer>
  }

  if (teacherData?.klasses.length === 0) {
    if (teacherData.has_clever_account) {
      return <Redirect to={routes.teacherOnboarding.addClassClever.index} />
    }

    return <Redirect to={routes.teacherOnboarding.addClassManual.index} />
  }

  return <PageContainer classes={{ wrapper: classNames(sharedClasses.vspacing2) }}>
    <TryItDialog />
    <SubscriptionExpiringSoonModal
      open={subscriptionRenewalDialogState.open}
      onClose={closeRenewalReminder}
      daysToExpire={daysUntil(subscription.end_date)}
      expirationDate={accountTrackedDate(subscription.end_date)}
    />
    {(klassSetupModalState === 'default') &&
        <KlassSetupModal
            open={klassSetupModalState === 'default'}
            setModalDisplayState={setKlassSetupModalState}
            onClose={() => {
              setKlassSetupModalState('closed')
            }}
        />
    }
    {(klassSetupModalState === 'print') &&
        <MultiClassPrintoutDialog
            title="Generate Parent Letters"
            helperText="Select the classes for which you want to generate parent letters."
            open={klassSetupModalState === 'print'}
            onClose={() => {
              setKlassSetupModalState('closed')
            }}
            handoutUrl={(klassId) => routes.handouts.parentLetters(klassId).slice(1)}
            filename={(klass) => `Kodable Parent Letters - ${klass.klass_name || "New Class"}`}
            trackingLocation={TrackParentInviteMethodLocation.inviteFamiliesButton}
            v2
        />
    }
    {(klassSetupModalState === 'share') &&
        <GenerateMultipleClassLinksDialog
            open={klassSetupModalState === 'share'}
            onClose={() => {
              setKlassSetupModalState('closed')
            }}
            location={TrackParentInviteMethodLocation.inviteFamiliesButton}
        />
    }
    <PageHeader title="My Classes" inAppBar></PageHeader>
    <RecommendedForYou />
    <AddClassDialog {...addClassManualDialogState} />
    <Box pb={1} display="flex" flexDirection="row" className={sharedClasses.hspacing2}>
      <Button
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        color="primary"
        variant="contained"
        disableElevation={false}
        onClick={() => addClassManualDialogState?.handleOpen()}
      >
        Add Class
      </Button>
      <Button
        variant="contained"
        color="orange"
        onClick={() => {
          setKlassSetupModalState('default')
        }}
      >Invite Families</Button>
      <TextField
        autoFocus
        placeholder="Class name, school or teacher"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
        value={classFilter}
        onChange={e => setClassFilter(e.target.value)}
        style={{
          width: 300
        }}
      />
    </Box>
    {sortedKlasses && sortedKlasses.length > 0 &&
        <PaginationContainer
            data={sortedKlasses}
            rowElement={(klass) => (
              <KlassCard
                key={klass.id}
                klassName={klass.klass_name}
                assignedGames={klass.klass_settings_data.AssignedGames}
                clever={klass.clever_id}
                grade={klass.grade}
                klassCode={klass.klass_code.toLocaleUpperCase()}
                studentNames={teacherData?.students.filter(student => student.klasses.includes(klass.id)).map((student) => student.name)}
                teacherNames={teacherData?.teachers.filter((teacher) => teacher.klasses.includes(klass.id)).map((teacher) => teacher.name)}
                onClick={() => history.push(routes.class.index(klass.id))}
              />
            )}
        />
    }
    {error && <Alert severity="error"
      action={
        <Button
          onClick={() => mutate()}
          color="inherit"
          size="small"
        >Try again</Button>
      }
    >There was an error retrieving your classes.</Alert>}

    {sortedKlasses?.length === 0 &&
      <Alert severity="info">We couldn't find any classes with this filter.</Alert>
    }
  </PageContainer>
}

export default ViewClassesList;
