import {GridCellParams, GridColDef, GridValueFormatterParams} from "@material-ui/data-grid";
import {accountTrackedDate} from "../../godmode/utils/time";
import {Link} from "react-router-dom";
import {routes} from "../../../routes";
import {List, ListItem, ListItemText, Popover} from "@material-ui/core";
import Button from "../../../components/ui/buttons/Button";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";


export const TeacherPopoverCell: React.FC<GridCellParams> = (params) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{width: '100%', display: 'flex'}} key={params.row.id}>
      <Button variant="text" color="primary" onClick={handleClick} fullWidth={true} style={{justifyContent: 'start', alignContent: 'start'}}>
        Multiple teachers
        <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} style={{marginLeft: 10}} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List dense>
          {params.row.teachers && params.row.teachers.length > 0 && params.row.teachers.map((teacher: {id: number, name: string}) => (
            <ListItem key={teacher.id}>
              <ListItemText
                primary={teacher.name}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export const KlassPopoverCell: React.FC<GridCellParams> = (params) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{width: '100%', display: 'flex'}} key={params.row.id}>
      <Button variant="text" color="primary" onClick={handleClick} fullWidth={true} style={{justifyContent: 'start', alignContent: 'start'}}>
        Multiple classes
        <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} style={{marginLeft: 10}} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List dense>
          {params.row.klasses && params.row.klasses.length > 0 && params.row.klasses.map((klass: {id: number, name: string}) => (
            <ListItem>
              <ListItemText
                primary={klass.name}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};


export const addColumnFormatters = (columns: GridColDef[]) => {
  return columns.map((column) => {
    column.disableColumnMenu = true;
    column.filterable = false;
    column.align = 'left'
    column.headerAlign = 'left'

    if (column.type && column.type === 'number') {
      column.minWidth = 130
      column.valueFormatter = (params: GridValueFormatterParams) => {
        if (!params.value) {
          return '0'
        } else {
          return params.value
        }
      }
    } else if (column.field === 'teachers') {
      column.renderCell = (params: GridValueFormatterParams) => {
        if (params.row.teachers.length > 1) {
          return <TeacherPopoverCell formattedValue={undefined} {...params} />
        } else {
          return params.row.teachers[0].name || '';
        }
      }
    } else if (column.field === 'klasses') {
      column.renderCell = (params: GridValueFormatterParams) => {
        if (params.row.klasses.length > 1) {
          return <KlassPopoverCell formattedValue={undefined} {...params} />
        } else {
          return params.row.klasses[0].name;
        }
      }
    // } else if (column.field === 'klass_name') {
    //   column.renderCell = (params: GridValueFormatterParams) => {
    //     return (
    //       <Link to={routes.class.index(params.row.klass_id)} style={{color: '#27a9e1'}}>{params.value}</Link>
    //     )
    //   }
    } else if (column.type && column.type === 'date') {
      column.minWidth = 120
      column.valueFormatter = (params: GridValueFormatterParams) => {
        if (!params.value) {
          return 'N/A';
        }
        return accountTrackedDate(params.value as string);
      }
    }
    return column
  })
}
