import grades from "types/Grades";
import { ICreativeBuildingsData } from "types/ICreativeBuildingsData";
import { ICourseData } from "./ICourseData";
import {GameTitle} from "../../enums/GameTitle";

interface ICourseSectionDescription {
  title: string;
  grades?: ReadonlyArray<(keyof typeof grades)>;
  icon: string | null;
  hideUnassign?: boolean;
}

export const orderedSections = ['start', 'introductory', 'intermediate', 'atHome'] as const;

const courseSections: { [key in typeof orderedSections extends ReadonlyArray<infer U> ? U : never]: ICourseSectionDescription } = {
  start: {
    title: "Start The Adventure Here!",
    grades: ['k', '1', '2', '3', '4', '5'],
    icon: 'start'
  },
  introductory: {
    title: 'Beginner Courses',
    grades: ['k', '1', '2', '3', '4', '5'],
    icon: 'easy',
  },
  intermediate: {
    title: 'Intermediate Courses',
    grades: ['2', '3', '4', '5'],
    icon: 'med',
  },
  atHome: {
    title: 'At-Home Courses',
    icon: null,
    hideUnassign: true
  }
} as const;

export const getOrderedCoursesBySection = (courseData: ICourseData[] = []) => courseData?.reduce((prev: { [key in typeof orderedSections extends ReadonlyArray<infer U> ? U : never]?: ICourseData[] }, cur) => {
  if (!cur.meta.section) {
    return prev;
  }

  return {
    ...prev,
    [cur.meta.section]: (prev[cur.meta.section] || []).concat(cur).sort((a, b) => (a.meta.inSectionOrder || 0) - (b.meta.inSectionOrder || 0))
  }
}, {});

export const convertCreativeBuildingsToSectionedCourses = (creativeBuildings: ICreativeBuildingsData[] = []) => {
  return creativeBuildings?.map(creativeBuilding => {
    return {
      configuration: {},
      id: 900+creativeBuilding?.id,
      meta: {
        courseCertificateUrl: undefined,
        dashboardSubtitle: creativeBuilding?.dashboard_subtitle,
        dashboardTitle: creativeBuilding?.dashboard_title,
        dashboardDescription: creativeBuilding?.dashboard_description,
        grades: creativeBuilding?.grades,
        inSectionOrder: 9,
        resources: [],
        section: creativeBuilding?.course_section,
        sectionOrder: creativeBuilding?.section_order,
        showDeepLink: false,
        standards: [],
        iconUrl: creativeBuilding?.icon_url,
        isCreativeArea: true,  
        gameTitle: GameTitle.BASICS  
      },
      name: creativeBuilding?.dashboard_title
    }
  })
}

export default courseSections;