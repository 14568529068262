import { faCaretDown, faCaretUp, faCheck, faDownload, faExclamationCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Link, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { routes } from "routes";
import Button from "components/ui/buttons/Button";
import PageContainer from "components/ui/PageContainer"
import PageHeader from "components/ui/PageHeader";
import Select from "components/ui/Select";
import TextField from "components/ui/TextField";
import useSharedStyles from "components/useSharedStyles";
import endpoints from "endpoints";
import { useFormik } from "formik";
import useCurrentUser from "loaders/useCurrentUser";
import useTeacherInit from "loaders/useTeacherInit";
import { justFetch } from "mutations/mutate";
import React, { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, Prompt, useHistory } from "react-router-dom";
import grades, { gradesArray } from "types/Grades";
import { IKlass } from "types/IKlass";
import { TrackParentInviteMethodLocation } from "utils/ParentInvites"
import * as Yup from 'yup';
import useGenerateClassCode from "../../hooks/useGenerateClassCode";
import PrintDialog from "../../components/dialogs/PrintDialog";
import { CLASS_STUDENT_LIMIT } from "components/dialogs/class/AddStudentsDialog";
import { useTracking } from "context/TrackingProvider";

interface IBulkClassCreationRequest {
  klass_name: string;
  klass_code: string;
  grade: keyof typeof grades;
  password_enabled: boolean;
  code_password_enabled: boolean;
  is_bulk: true;
  students: string[];
  teacher_id: number;
}

const BulkAddClasses: React.VFC = () => {
  const { currentUser } = useCurrentUser();

  const form = useFormik<{ classes: IBulkClassCreationRequest[] }>({
    initialValues: {
      classes: [{
        klass_name: '',
        klass_code: '',
        grade: 'all',
        password_enabled: false,
        code_password_enabled: false,
        is_bulk: true,
        students: [],
        teacher_id: currentUser.id
      }]
    },
    validationSchema: Yup.object({
      classes: Yup.array(
        Yup.object({
          klass_name: Yup.string().required('Enter a name for this class.'),
          klass_code: Yup.string()
            .required('Enter a class code')
            .matches(/(.){5}/, 'Your class code must be 5 characters or longer.')
        })
      )
    }),
    onSubmit: values => { }
  });

  const [showErrors, setShowErrors] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<('submitting' | 'success' | 'error')[]>([]);
  const [createdKlasses, setCreatedKlasses] = useState<IKlass[]>([]);

  useEffect(() => {
    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    }
  }, []);

  const { track } = useTracking();

  const deleteKlass = (_idx: number) => {
    form.setFieldValue('classes', form.values.classes.filter((val, idx) => idx !== _idx));
  }

  const addKlass = () => {
    const values = form.values;
    form.resetForm();
    form.setValues(values);

    form.setFieldValue('classes', form.values.classes.concat({
      klass_name: '',
      klass_code: '',
      grade: 'all',
      password_enabled: false,
      code_password_enabled: false,
      is_bulk: true,
      students: [],
      teacher_id: currentUser.id
    }));
  }

  const updateClassField = <Field extends keyof IBulkClassCreationRequest>(_idx: number, field: Field, value: IBulkClassCreationRequest[Field]) => {
    form.setFieldValue('classes', form.values.classes.map((klass, idx) => {
      if (idx !== _idx) {
        return klass;
      }

      return {
        ...klass,
        [field]: value
      }
    }))
  }

  const handlePressSubmit = () => {
    if (form.isValid) {
      handleSubmit();
    } else {
      setShowErrors(true);
    }
  }

  const handleSubmit = (_submitStatus: ('error' | 'submitting' | 'success')[] = []) => {
    setIsSubmitting(true);
    const currentClass = form.values.classes[_submitStatus.length];
    const submitStatus = _submitStatus.concat('submitting')
    setSubmitStatus(submitStatus);

    if (currentClass === undefined) {
      setSubmitSuccess(true);
      setIsSubmitting(false);
      return;
    }


    justFetch(endpoints.classes, 'POST', currentClass)
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }

        handleSubmit(submitStatus.slice(0, submitStatus.length - 1).concat('success'));

        return res.json() as Promise<IKlass>;
      })
      .then(klass => {
        track('Created Class', {
          'Number of Students': currentClass.students.length,
          'Grade': currentClass.grade,
          'Upload Source': 'Bulk Mode'
        });
        setCreatedKlasses(klasses => klasses.concat(klass))
      })
      .catch(() => {
        setSubmitStatus(submitStatus.slice(0, submitStatus.length - 1).concat('error'));
        setSubmitError(true);
      })
  }

  const updateClassError = <Field extends keyof IBulkClassCreationRequest>(_idx: number, field: Field, value: any) => {
    form.setFieldError(`classes.${_idx}.${field}`, value);
  }

  const sharedClasses = useSharedStyles();
  const history = useHistory();

  if (isSubmitting || submitSuccess || submitError) {
    return <PageContainer classes={{ wrapper: sharedClasses.vspacing2 }}>
      <Prompt
        when={isSubmitting}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Dialog open={submitError} fullWidth>
        <DialogTitle>
          Error Adding Classes
        </DialogTitle>
        <DialogContent>
          We couldn't add one of your classes.
        </DialogContent>
        <DialogActions>
          <Box></Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.classes.index)}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" flexDirection="column" alignItems="center" margin="0 auto" maxWidth="600px" className={sharedClasses.vspacing2}>
        {isSubmitting && <PageHeader title="Creating Classes..." />}
        {!isSubmitting && <PageHeader title="Classes Created!" />}
        {form.values.classes.map((klass, idx) => {
          return <Box display="flex" justifyContent="space-between" width="100%" bgcolor="white" border="1px solid lightgray" p={2} borderRadius="5px">
            <Typography variant="h2">{klass.klass_name}</Typography>
            <Box>
              {submitStatus[idx] === 'submitting' && <CircularProgress size={32} />}
              {submitStatus[idx] === 'success' && createdKlasses[idx] && <DownloadParentLettersButton klass={createdKlasses[idx]} />}
              {submitStatus[idx] === 'error' && <FontAwesomeIcon size="2x" icon={faExclamationCircle} />}
            </Box>
          </Box>
        })}
        {!isSubmitting && <Button
          size="large"
          variant="contained"
          color="green"
          {...{
            component: RouterLink,
            to: routes.classes.index
          }}
        >
          Go To My Classes
        </Button>}
      </Box>
    </PageContainer>
  }

  return <PageContainer classes={{ wrapper: sharedClasses.vspacing2 }}>
    <Prompt
      when={!isSubmitting}
      message='You have unsaved changes, are you sure you want to leave?'
    />
    <PageHeader title="Bulk Add Classes" />
    {form.values.classes.map((klass, idx) => {
      return <NewClassCard
        klass={klass}
        errors={form.errors.classes?.[idx]}
        key={idx}
        onRemove={() => deleteKlass(idx)}
        showRemove={form.values.classes.length > 1}
        onUpdateClassField={(field, value) => updateClassField(idx, field, value)}
        onUpdateClassError={(field, value) => updateClassError(idx, field, value)}
        highlightErrors={showErrors && !form.isValid}
      />
    })}
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Button
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        variant="contained"
        color="blue"
        onClick={addKlass}
      >Add Another Class</Button>
      <Button
        startIcon={<FontAwesomeIcon icon={faCheck} />}
        variant="contained"
        color="green"
        onClick={handlePressSubmit}
      >Save</Button>
    </Box>
  </PageContainer>;
}

const DownloadParentLettersButton: React.VFC<{ klass: IKlass }> = ({ klass }) => {
  const [showDialog, setShowDialog] = useState(false);

  return <>
    <PrintDialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
      handoutUrl={routes.handouts.parentLetters(klass.id).slice(1)}
      filename={`Kodable Parent Letters - ${klass.klass_name}`}
      v2
      isParentLetters={true}
      klass={klass}
      location={TrackParentInviteMethodLocation.bulkAddClasses}
    />
    <Button
      variant="contained"
      color="primary"
      startIcon={<FontAwesomeIcon icon={faDownload} />}
      onClick={() => {
        setShowDialog(true)
      }}
    >Parent Instructions</Button>
  </>
}

interface NewClassCardProps {
  klass: IBulkClassCreationRequest;
  errors: any;
  showRemove: boolean;
  onRemove: () => void;
  onUpdateClassField: <Field extends keyof IBulkClassCreationRequest>(field: Field, value: IBulkClassCreationRequest[Field]) => void;
  onUpdateClassError: <Field extends keyof IBulkClassCreationRequest>(field: Field, value: any) => void;
  highlightErrors: boolean;
}

const NewClassCard: React.VFC<NewClassCardProps> = ({ klass, onRemove, showRemove, onUpdateClassField, onUpdateClassError, errors, highlightErrors }) => {
  const sharedClasses = useSharedStyles();
  const { classCode: generatedClassCode, checkIfClassCodeUnique } = useGenerateClassCode();

  useEffect(() => {
    onUpdateClassField('klass_code', generatedClassCode);
  }, [generatedClassCode]);

  const theme = useTheme();
  const [showEditStudentsDialog, setShowEditStudentsDialog] = useState(false);
  const [studentsDialogInputValue, setStudentsDialogInputValue] = useState('');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const { teacherData } = useTeacherInit();

  useEffect(() => {
    if (showEditStudentsDialog) {
      setStudentsDialogInputValue(klass.students.join('\n'));
    }
  }, [showEditStudentsDialog])

  const handleClassCodeBlur = () => {
    if (klass.klass_code.length < 5) {
      return;
    }

    checkIfClassCodeUnique(klass.klass_code)
      .then(isUnique => {
        if (!isUnique) {
          onUpdateClassError('klass_code', 'This code is already in use by another class.')
        } else {
          onUpdateClassError('klass_code', undefined);
        }
      })
  }

  const showStudentLimitWarning = useMemo(() => {
    return studentsDialogInputValue.split('\n').map(entry => entry.trim()).filter(Boolean).length > CLASS_STUDENT_LIMIT;
  }, [studentsDialogInputValue]);

  return <>
    <Dialog open={showConfirmDeleteDialog} fullWidth>
      <DialogTitle>Delete Class?</DialogTitle>
      <DialogContent>
        You are deleting <strong>{klass.klass_name || 'an untitled class'}</strong> with <strong>{klass.students.length}</strong> students.
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setShowConfirmDeleteDialog(false)}
        >Cancel</Button>
        <Button
          variant="contained"
          color="red"
          startIcon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => { setShowConfirmDeleteDialog(false); onRemove(); }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={showEditStudentsDialog}>
      <DialogTitle>{klass.klass_name} Students</DialogTitle>
      <DialogContent className={sharedClasses.vspacing2}>
        <Typography variant="body2">Add one student per line. You can copy/paste from a spreadsheet into the text area!</Typography>
        <TextField
          multiline
          rows={8}
          placeholder="Enter one student per line"
          value={studentsDialogInputValue}
          onChange={e => setStudentsDialogInputValue(e.target.value)}
          style={{ background: 'white' }}
        />
        {showStudentLimitWarning && <Alert severity="error">You cannot have more than 50 students in a class. Please delete some before adding more.</Alert>}
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            onClick={() => setShowEditStudentsDialog(false)}
          >Cancel</Button>
          <Button
            variant="contained"
            color={klass.students.length > 0 ? 'blue' : 'green'}
            disabled={showStudentLimitWarning}
            onClick={() => {
              onUpdateClassField('students', studentsDialogInputValue.split('\n').map(s => s.trim()).filter(Boolean));
              setShowEditStudentsDialog(false);
            }}
          >
            {klass.students.length > 0 ? 'Update' : 'Add'}
          </Button>
        </Box>
        <Divider />
        <Box textAlign="center" pt={2} pb={4}>
          {teacherData?.district?.no_pii &&
            <Alert severity="error">
              <>Due to your district's privacy restrictions, you may not use student names for your student profiles. Please use an anonymous identifier. <strong>This does not affect your student's experience with Kodable.</strong></>
            </Alert>
          }
          {!teacherData?.district?.no_pii &&
            <>
            <Typography paragraph variant="h2">
              We care about student safety!
            </Typography>
            <Typography variant="body2">
              Pursuant to COPPA regulations and our <Link href="https://www.kodable.com/privacy" target="_blank">Terms of Service</Link>, <span style={{ fontWeight: 'bold' }}>do not</span> use any Personally Identifiable Information when creating student profiles.
            </Typography>
            </>
          }
        </Box>
      </DialogContent>
    </Dialog>
    <Box component={Paper} {...{ variant: 'outlined' }} p={2}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" className={sharedClasses.vspacing2} flexBasis="50%">
          <TextField
            label="Class Name"
            value={klass.klass_name}
            onChange={e => onUpdateClassField('klass_name', e.target.value)}
            helperText={errors?.klass_name}
            error={!!errors?.klass_name && highlightErrors}
          />
          <Box display="flex">
            <TextField
              label="Class Code"
              value={klass.klass_code.toLocaleUpperCase()}
              onChange={e => onUpdateClassField('klass_code', e.target.value)}
              onBlur={handleClassCodeBlur}
              disabled={!generatedClassCode}
              helperText={!!generatedClassCode && errors?.klass_code}
              error={!!generatedClassCode && !!errors?.klass_code}
            />
            {klass.klass_code !== generatedClassCode.toLocaleUpperCase() && <Box mt="28px" ml={2}>
              <Button
                onClick={() => onUpdateClassField('klass_code', generatedClassCode.toLocaleUpperCase())}
                variant="outlined"
                style={{ height: 40 }}
              >Reset</Button>
            </Box>}
          </Box>
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            color={klass.students.length > 0 ? 'blue' : 'green'}
            size="large"
            onClick={() => setShowEditStudentsDialog(true)}
          >{klass.students.length > 0 ? 'Update' : 'Add'} Students</Button>
          {klass.students.length > 0 && <Box mt={1}>
            <Typography color="textSecondary" variant="body2">{klass.students.length} students entered</Typography>
          </Box>}
          <Box mt={1}>
            <Button
              endIcon={<FontAwesomeIcon icon={showAdvancedOptions ? faCaretUp : faCaretDown} />}
              onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            >
              {showAdvancedOptions ? 'Hide' : 'Show'} Advanced Options
            </Button>
          </Box>
        </Box>
        {showRemove && <Box>
          <IconButton
            onClick={() => {
              if (klass.students.length > 0) {
                setShowConfirmDeleteDialog(true)
              } else {
                onRemove();
              }
            }}
            size="small"
          >
            <FontAwesomeIcon color={theme.palette.red.main} icon={faTrash} />
          </IconButton>
        </Box>}
      </Box>
      {showAdvancedOptions && <Box display="flex" flexDirection="row" alignItems="flex-end" mt={2} className={sharedClasses.hspacing4}>
        <Select
          label="Class Grade"
          id="grade"
          onChange={e => onUpdateClassField('grade', e.target.value as any)}
          value={klass.grade}
          style={{
            background: 'white',
          }}
        >
          {gradesArray
            .map(grade => <MenuItem
              key={grade.key}
              value={grade.key}
            >
              {grade.name}
            </MenuItem>)
          }
        </Select>
        {(teacherData?.teachers?.length || 0) > 1 && <>
          <Select
            label="Class Teacher"
            id="grade"
            onChange={e => onUpdateClassField('teacher_id', parseInt(e.target.value as any))}
            value={klass.teacher_id}
            style={{
              background: 'white',
            }}
          >
            {teacherData?.teachers
              .map(teacher => <MenuItem
                key={teacher.id}
                value={teacher.id}
              >
                {teacher.name}
              </MenuItem>)
            }
          </Select>
        </>}
        <FormControlLabel
          control={<Checkbox checked={klass.password_enabled} onChange={e => onUpdateClassField('password_enabled', e.target.checked)} name="password_enabled" id="password_enabled" />}
          label="Enable Picture Passwords"
          style={{ whiteSpace: 'nowrap' }}
        />
      </Box>}
    </Box>
  </>
}

export default BulkAddClasses;