import { ICreativeBuildingsData } from "types/ICreativeBuildingsData";

export const creativeBuildingsData: ICreativeBuildingsData[] = [
    {
        id: 1,
        dashboard_title: "Maze Maker",
        dashboard_subtitle: "Application of Sequence and Conditions",
        dashboard_description: "Maze Maker allows students to apply their coding skills by creating, solving, and sharing custom maze levels.",
        grades: ['k', '1', '2', '3'],
        icon_url: "/images/courses/creative_area_icons/MazeMaker.png",
        course_section: "intermediate",
        section_order: 2,
    },
    {
        id: 2,
        dashboard_title: "Fuzz Builder",
        dashboard_subtitle: "Application of Sequence and Conditions",
        dashboard_description: "With Fuzz Builder, students get creative, designing their own Fuzz characters while applying the coding concepts they have learned.",
        grades: ['k', '1', '2', '3'],
        icon_url: "/images/courses/creative_area_icons/FuzzBuilder.png",
        course_section: "introductory",
        section_order: 4,
    },
    {
        id: 4,
        dashboard_title: "Pets",
        dashboard_subtitle: "Application of Conditions and Functions",
        dashboard_description: "In Kodable Pets, students program Carebots to automate pet care tasks, practicing coding concepts like conditions, loops, and functions.",
        grades: ['k', '1', '2', '3'],
        icon_url: "/images/courses/creative_area_icons/Pets.png",
        course_section: "introductory",
        section_order: 2,
        
    }
];

